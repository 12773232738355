<template>
  <Modal ref="modal" @toggle="toggle" :label="params.title" width="400px">
    <template v-slot:body>
      <div>{{ params.message }}</div>
    </template>
    <template v-slot:footer>
      <v-btn variant="elevated" color="primary" @click="() => makeAction(params.approve)">
        {{ params.options?.btnApprove }}
      </v-btn>
      <v-btn variant="tonal" @click="() => makeAction(params.decline)">{{ params.options?.btnDecline }}</v-btn>
    </template>
  </Modal>
</template>

<script lang="ts">
import Modal from "/src/primitives/Modal.vue";
import { Component, Ref, Vue } from "vue-facing-decorator";

interface ApproveSettings {
  title?: string;
  message: string;
  options: {
    btnApprove?: string;
    btnDecline?: string;
  };
  approve?: (...args: any[]) => any;
  decline?: (...args: any[]) => any;
}

@Component({
  components: { Modal },
})
export default class ConfirmModal extends Vue {
  @Ref() readonly modal!: Modal;
  private defaultOptions = {
    btnApprove: "Подтвердить",
    btnDecline: "Отменить",
  };
  private defaults: ApproveSettings = {
    title: "Подтверждение действия",
    message: "Вы действительно хотите выполнить действие?",
    options: this.defaultOptions,
  };
  params: ApproveSettings = {
    message: this.defaults.message,
    options: this.defaultOptions,
  };

  public open(params: ApproveSettings) {
    this.params.message = params.message;
    this.params.title = params.title || this.defaults.title;
    this.params.options = {};
    if (params.options) {
      this.params.options.btnApprove = params.options.btnApprove || this.defaultOptions.btnApprove;
      this.params.options.btnDecline = params.options.btnDecline || this.defaultOptions.btnDecline;
    }
    this.params.approve = params.approve || this.defaults.approve;
    this.params.decline = params.decline || this.defaults.decline;
    this.modal.open();
  }

  makeAction(cb: null | (() => any) = null) {
    cb && cb();
    this.modal.close();
  }

  toggle() {
    this.params.decline && this.params.decline();
  }
}
</script>

<style lang="scss" scoped></style>
