import { any, orNull } from "../Decoders/CommonDecoders";
import { field, string, succeed } from "jsonous";

interface ErrorInterface {
  message: string;
  errors: Record<string, string>;
}

const errorDecoder = succeed({})
  .assign("message", orNull(field("message", string), ""))
  .assign("errors", orNull(field("errors", any), {}));

export class BaseError {
  error: ErrorInterface;

  constructor(error: any) {
    this.error = errorDecoder
      .decodeAny(error)
      .getOrElseValue({ message: "Не удалось определить формат ошибки", errors: {} });
  }

  static isBaseError(value: any): value is BaseError {
    return value instanceof BaseError;
  }

  static make(message: string, errors: Record<string, string> = {}) {
    return new BaseError({
      message,
      errors,
    });
  }

  hasErrors() {
    return Object.keys(this.error.errors).length !== 0;
  }

  getErrors() {
    return this.error.errors;
  }

  getMessage() {
    return this.error.message;
  }

  getErrorOrMessage() {
    const errors = this.getErrors();
    const keys = Object.keys(errors);
    if (keys.length === 0) return this.getMessage();
    return errors[keys[0]];
  }
}
