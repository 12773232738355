import { FileTypeHandler, FileTypeSettings } from "/src/entitites/FileType.ts";
import Decoder, { array, field, number, string, succeed } from "jsonous";
import { orNull, toObject } from "./CommonDecoders";

export const fileTypeHandlerDecoder = (): Decoder<FileTypeHandler> =>
  succeed(FileTypeHandler)
    .assign("code", field("code", string))
    .assign("sort", field("sort", number))
    .assign("title", field("title", string))
    .andThen(toObject(FileTypeHandler));

export const fileTypeSettingsDecoder = (): Decoder<FileTypeSettings> =>
  succeed(FileTypeSettings)
    .assign("id", field("id", number))
    .assign("title", orNull(field("title", string), ""))
    .assign("extensions", orNull(field("extensions", array(string)), []))
    .assign("handlers", orNull(field("handlers", array(string)), []))
    .andThen(toObject(FileTypeSettings));
