<template>
  <v-navigation-drawer>
    <v-list density="compact">
      <v-list-item prepend-icon="mdi-home" title="Veneer Scanner" :to="endpoints.HOME" />
    </v-list>

    <v-divider></v-divider>

    <v-list density="compact">
      <div v-for="(menuItem, itemKey) in menuStore.items" :key="itemKey">
        <div v-if="menuItem.subItems && menuItem.subItems.length">
          <v-list-subheader :title="menuItem.name" />
          <v-list v-if="menuItem.subItems && menuItem.subItems.length" density="compact" class="sub-menu">
            <v-list-item
              v-for="(subItem, subItemKey) in menuItem.subItems"
              :key="subItemKey"
              :prepend-icon="subItem.icon"
              :title="subItem.name"
              :to="subItem.url"
            >
            </v-list-item>
          </v-list>
        </div>
        <v-list-item v-else :prepend-icon="menuItem.icon" :title="menuItem.name" :to="menuItem.url" />
      </div>
    </v-list>
  </v-navigation-drawer>
</template>

<script lang="ts">
import { FRONT_ENDPOINTS } from "/src/endpoints";
import { MenuStore } from "/src/store/MenuStore";
import { Component, Vue } from "vue-facing-decorator";
import Typography from "/src/primitives/Typography.vue";
import { Container } from "typedi";

@Component({
  components: { Typography },
})
export default class Navigation extends Vue {
  menuStore = Container.get(MenuStore);
  endpoints = FRONT_ENDPOINTS;

  async mounted() {
    await this.menuStore.loadItems();
  }
}
</script>

<style lang="scss" scoped>
.v-list.sub-menu {
  margin-left: 10px;
}
:deep(.v-list-item-title) {
  word-break: break-word;
  white-space: break-spaces;
}
</style>
