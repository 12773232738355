<template>
  <div class="w-100">
    <div class="canvas-modal w-100">
      <v-row class="flex-nowrap w-100 h-100 ma-0">
        <v-col class="canvas-outer-container pa-0" cols="auto" :class="{ 'two-cols': !!$slots['after-result'] }">
          <div class="canvas-container">
            <CanvasActions :manualButtons="!currentContour" :zoomStore="zoomStore">
              <template v-slot:manual-actions v-if="!currentContour">
                <ManualCanvasActions
                  :defectTypesStore="defectTypesStore"
                  :canvasStore="canvasStore"
                  :canvasData="() => getCanvasData(true)"
                />
              </template>
            </CanvasActions>
            <canvas
              :id="canvasIds.modal"
              :class="{
                'manual-adding': canvasStore.isManualAddingMode,
                'manual-deleting': canvasStore.isManualDeletingMode,
              }"
            />
          </div>
        </v-col>
        <v-col class="second-column pt-0">
          <div v-if="hasResultErrors" class="mb-3">
            <v-alert
              variant="outlined"
              type="error"
              title="Ошибки при выполнении алгоритма:"
              :prominent="true"
              border="top"
            >
              <div v-for="(errorResult, key) in errorResults" :key="key">
                <div class="text-caption">
                  {{ (errorResult.result.individualSettings || errorResult.result.settings)?.title }}
                </div>
                <ul>
                  <li v-for="(error, key) in errorResult.errors" :key="key">{{ error }}</li>
                </ul>
              </div>
            </v-alert>
          </div>
          <div class="w-100">
            <v-card v-if="currentContour">
              <v-card-title>
                <Typography :size="16">
                  Обработано {{ currentContourId }} из {{ contours.length }}
                  <span> ({{ Math.round((currentContourId * 100) / contours.length) }}%)</span>
                </Typography>
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col>
                    <Typography size="18">Алгоритм «{{ currentContour.result.settings?.title }}»</Typography>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-select
                      v-if="defectTypesStore.types.length"
                      label="Тип дефекта"
                      v-model="currentDefectType"
                      :items="defectTypesStore.types"
                      variant="solo"
                      item-value="id"
                      hide-details
                      density="compact"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-btn
                  class="ml-2"
                  variant="elevated"
                  prepend-icon="mdi-trash-can-outline"
                  color="error"
                  @click="() => remove()"
                >
                  Удалить контур
                </v-btn>
                <v-btn
                  class="ml-2"
                  variant="elevated"
                  prepend-icon="mdi-magnify-plus-outline"
                  color="success"
                  @click="() => zoomInToCurrentContour()"
                >
                  Показать
                </v-btn>
                <v-btn
                  class="ml-2"
                  variant="elevated"
                  append-icon="mdi-arrow-right"
                  color="primary"
                  @click="approveAndContinue"
                >
                  Подтвердить и продолжить
                </v-btn>
              </v-card-actions>
            </v-card>
            <v-card v-else-if="manualResult.data.manualContours.length">
              <v-card-title>
                <Typography :size="16"> Все обнаруженные алгоритмами дефекты обработаны </Typography>
              </v-card-title>
              <v-card-text> Проверьте все ли дефекты на изображении указаны </v-card-text>
              <v-card-actions>
                <v-btn variant="elevated" prepend-icon="mdi-floppy" color="primary" @click="save">
                  Сохранить результаты
                </v-btn>
              </v-card-actions>
            </v-card>
          </div>
        </v-col>
      </v-row>
    </div>
    <ConfirmModal ref="confirmModal" />
  </div>
</template>

<script lang="ts">
import { ContoursAlgorithmResult } from "/src/entitites/AlgorithmResult.ts";
import { CanvasData } from "/src/entitites/CanvasData.ts";
import { ManualAlgorithmResult } from "/src/entitites/ManualAlgorithmResult.ts";
import { PointsContour } from "/src/entitites/PointsContour.ts";
import { UploadedFile } from "/src/entitites/UploadedFile.ts";
import { AlgorithmStatusEnum } from "/src/enums/AlgorithmStatusEnum.ts";
import ConfirmModal from "/src/primitives/ConfirmModal.vue";
import TooltipBtn from "/src/primitives/TooltipBtn.vue";
import Typography from "/src/primitives/Typography.vue";
import { PointsContourProvider } from "/src/services/PointsContourProvider.ts";
import { RectDefiner } from "/src/services/RectDefiner.ts";
import { UrlsProvider } from "/src/services/UrlsProvider.ts";
import { CanvasZoomStore } from "/src/store/CanvasZoomStore.ts";
import { DefectTypesStore } from "/src/store/DefectTypesStore.ts";
import { LayoutStore } from "/src/store/LayoutStore.ts";
import { ManualCanvasStore } from "/src/store/ManualCanvasStore.ts";
import { Container } from "typedi";
import { Component, Prop, Ref, Vue, Watch } from "vue-facing-decorator";
import CanvasActions from "./actions/CanvasActions.vue";
import ManualCanvasActions from "./actions/ManualCanvasActions.vue";

@Component({
  components: { ManualCanvasActions, CanvasActions, ConfirmModal, Typography, TooltipBtn },
})
export default class ComparisonManualCanvas extends Vue {
  @Ref() readonly confirmModal!: ConfirmModal;
  @Prop() file!: UploadedFile;
  @Prop({ required: true }) manualResult!: ManualAlgorithmResult;
  @Prop({ default: false }) manualButtons!: boolean;
  public defectTypesStore = Container.get(DefectTypesStore);
  public zoomStore = Container.get(CanvasZoomStore);
  public canvasStore = Container.get(ManualCanvasStore);
  private urlsProvider = Container.get(UrlsProvider);
  private contoursProvider = Container.get(PointsContourProvider);
  private rectDefiner = Container.get(RectDefiner);
  private layoutStore = Container.get(LayoutStore);
  private uniqId: string = Math.random().toString(36).substring(7);
  contours: { result: ContoursAlgorithmResult; contour: PointsContour }[] = [];
  currentContourId = 0;
  currentDefectType: number | null = null;

  // TODO: подумать над устранением дублирования

  async mounted() {
    await this.defectTypesStore.loadItems();
    await this.setupStore();
    this.updateContoursList();
    this.zoomStore.setCallbackOnChange(() => this.onUpdateData());
    await this.onUpdateData();
    this.zoomInToCurrentContour();
    window.addEventListener("resize", this.onResize);
  }

  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  }

  updateContoursList() {
    this.currentContourId = 0;
    if (this.manualResult.statusCode === AlgorithmStatusEnum.FINISHED) {
      return;
    }
    this.manualResult.data.siblingResults.forEach(result => {
      const settings = result.individualSettings || result.settings;
      this.contoursProvider.processSimpleContours(result.data.contoursPoints, settings).forEach(contour => {
        this.contours.push({ result, contour });
      });
    });
    this.zoomInToCurrentContour();
  }

  onResize() {
    this.zoomInToCurrentContour();
    this.onUpdateData();
  }

  @Watch("results")
  @Watch("currentDefectType")
  onUpdateData() {
    this.$nextTick(async () => {
      const additionalContours: PointsContour[] = [];
      this.currentContour && additionalContours.push(this.currentContour.contour);
      if (this.currentDefectType && this.currentContour) {
        const type = await this.defectTypesStore.getType(this.currentDefectType);
        const rectContour = this.rectDefiner.defineContour(
          this.currentContour.contour.points,
          type ? type.params : this.currentContour.contour.style,
        );
        additionalContours.push(rectContour);
      }
      this.canvasStore.setAdditionalContours(additionalContours);
      await this.canvasStore.updateCanvas(this.getCanvasData(true));
    });
  }

  @Watch("currentContourId")
  @Watch("currentDefectType")
  zoomInToCurrentContour() {
    const canvasData = this.getCanvasData(true);
    const currentContour = this.currentContour?.contour;
    if (currentContour) {
      const ratio = this.canvasStore.canvasStore.ratio;
      const sizes = this.rectDefiner.defineParams(currentContour.points, ratio);
      const zoomX = canvasData.canvas.width / (sizes.width + 10);
      const zoomY = canvasData.canvas.height / (sizes.height + 10);
      const zoom = Math.max(zoomX, zoomY);
      this.zoomStore.setZoom(zoom);
      setTimeout(() => {
        const ratio = this.canvasStore.canvasStore.ratio;
        const sizes = this.rectDefiner.defineParams(currentContour.points, ratio);
        const [minX, minY] = this.rectDefiner.define(currentContour.points, ratio);
        const scrollY = minY - (canvasData.container.clientHeight - sizes.height) / 2;
        const scrollX = minX - (canvasData.container.clientWidth - sizes.width) / 2;
        canvasData.container.scroll(scrollX, scrollY);
      });
    }
  }

  async approveAndContinue() {
    if (!this.currentDefectType) {
      return this.layoutStore.error("Не выбран тип дефекта");
    }
    if (this.currentContour) {
      const rectContour = this.rectDefiner.defineContour(
        this.currentContour.contour.points,
        this.currentContour.contour.style,
      );
      this.manualResult.data.manualContours.push({ typeId: this.currentDefectType, points: rectContour.points });
      this.manualResult.data.contoursCount = this.manualResult.data.manualContours.length;
    }
    this.currentContourId++;
    if (!this.currentContour) {
      this.zoomStore.reset();
    }
    await this.onUpdateData();
  }

  async save() {
    this.$emit("save", this.manualResult);
  }

  async remove(approved = false) {
    if (approved) {
      this.contours.splice(this.currentContourId, 1);
      await this.onUpdateData();
      this.zoomInToCurrentContour();
    } else {
      this.confirmModal.open({
        title: "Подтверждение удаления контура",
        message: "Вы действительно хотите удалить контур?",
        options: { btnApprove: "Удалить" },
        approve: () => this.remove(true),
      });
    }
  }

  get currentContour() {
    return this.contours.slice(this.currentContourId, this.currentContourId + 1).pop();
  }

  get imageUrl() {
    return this.urlsProvider.files.download(this.file?.hash || "");
  }

  get hasResultErrors() {
    return !!this.errorResults.length;
  }

  get errorResults(): { result: ManualAlgorithmResult; errors: string[] }[] {
    const errors = this.manualResult.data.errors;
    return errors.length ? [{ result: this.manualResult, errors: this.manualResult.data.errors }] : [];
  }

  @Watch("file")
  get canvasIds() {
    return {
      main: `main_canvas_${this.file?.hash}_${this.uniqId}`,
      modal: `modal_canvas_${this.file?.hash}_${this.uniqId}`,
    };
  }

  private async setupStore() {
    await this.canvasStore.setup({ zoomStore: this.zoomStore, imageUrl: this.imageUrl }, this.manualResult);
  }

  getCanvasData(isModal = false): CanvasData {
    const canvas = document.getElementById(isModal ? this.canvasIds.modal : this.canvasIds.main);
    const container = canvas?.closest(".canvas-container") as Element;
    return {
      canvas: canvas as HTMLCanvasElement,
      containerWidth: container?.clientWidth || 350,
      containerHeight: container?.clientHeight || 350,
      isModal,
      container,
    };
  }
}
</script>

<style lang="scss" scoped>
.canvas-container {
  font-size: 0;
  line-height: 0;
  canvas {
    &.manual-adding {
      cursor: crosshair;
    }
    &.manual-deleting {
      cursor: no-drop;
    }
  }
}
.canvas-modal {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-height: 100%;
  .canvas-outer-container {
    position: relative;
    overflow: hidden;
    outline: 1px #d3d3d3 solid;
    max-width: calc(100% - 800px);
    border-radius: 4px;
    .canvas-container {
      overflow: auto;
      min-height: 50vh;
      max-height: 70vh;
      max-width: 100%;
    }
  }
  .second-column {
    max-width: 800px;
  }
}
</style>
