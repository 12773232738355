<template>
  <v-card>
    <v-progress-linear :active="store.loader.isLoading" :indeterminate="true" :absolute="true" bottom />
    <v-card-title>Файл {{ item?.file?.name }}</v-card-title>
    <v-card-text v-if="item">
      <v-row justify="start">
        <v-col cols="auto">
          <v-btn prepend-icon="mdi-arrow-left" color="primary" :to="urlsProvider.defectsComparison.list()">
            К общему списку
          </v-btn>
        </v-col>
      </v-row>
      <v-table class="table-rounded">
        <tbody>
          <tr>
            <td class="text-left">id</td>
            <td class="text-left">{{ item.id }}</td>
          </tr>
          <tr>
            <td class="text-left">Загружен</td>
            <td class="text-left">{{ item?.file?.createdAt.toLocaleString() }}</td>
          </tr>
        </tbody>
      </v-table>
      <div>
        <v-container>
          <v-row no-gutters class="d-flex justify-start">
            <ComparisonManualCanvasView :result="item" @save="save"></ComparisonManualCanvasView>
          </v-row>
        </v-container>
      </div>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import { ManualAlgorithmResult } from "/src/entitites/ManualAlgorithmResult.ts";
import ComparisonManualCanvasView from "/src/modules/DefectsComparison/ComparisonManualCanvasView.vue";
import { UrlsProvider } from "/src/services/UrlsProvider.ts";
import { LayoutStore } from "/src/store/LayoutStore.ts";
import { ManualAlgorithmResultsStore } from "/src/store/ManualAlgorithmResultsStore.ts";
import { Container } from "typedi";
import { Component, Prop, Vue } from "vue-facing-decorator";

@Component({
  components: { ComparisonManualCanvasView },
})
export default class DefectsComparisonDetail extends Vue {
  @Prop() private id!: number;
  store = Container.get(ManualAlgorithmResultsStore);
  urlsProvider = Container.get(UrlsProvider);
  private layoutStore = Container.get(LayoutStore);
  item: ManualAlgorithmResult | null = null;

  protected async created() {
    await this.reload();
  }

  async reload() {
    const item = await this.store.loadItem(this.id);
    item && (this.item = item);
  }

  async save(item: ManualAlgorithmResult) {
    const result = await this.store.save(item);
    if (result.hasErrors) {
      return result.errorsAsArray.forEach(error => this.layoutStore.error(error));
    }
    this.layoutStore.success("Изменения сохранены");
    await this.$router.push(this.urlsProvider.defectsComparison.list());
  }
}
</script>

<style lang="scss" scoped></style>
