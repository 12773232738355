import { Loader } from "/src/entitites/Loader";
import { UserPermission, UserRole } from "/src/entitites/User.ts";
import { UserRolesProvider } from "/src/services/UserRolesProvider.ts";
import { Container, Service } from "typedi";
import { action, observable } from "mobx";

@Service({ global: true })
export class UserRolesStore {
  @observable public loader = new Loader();
  @observable public errors: string[] = [];
  @observable public items: UserRole[] = [];
  @observable public permissions: UserPermission[] = [];
  @observable public totalItems = 0;
  private provider = Container.get(UserRolesProvider);

  public async loadItems(params: any) {
    this.clear();
    this.loader.start();
    const result = await this.provider.getItems(params);
    this.loader.stop();
    result.hasErrors && (this.errors = result.errorsAsArray);
    this.items = result.data?.items || [];
    this.totalItems = result.data?.totalItems || 0;
  }

  public async getRole(id: number) {
    return this.items.filter(item => item.id === id).pop();
  }

  public async loadPermissions() {
    if (!this.permissions.length) {
      this.loader.start();
      const result = await this.provider.getPermissions();
      this.loader.stop();
      this.permissions = result.data.items || [];
    }
  }

  public async getPermission(id: string) {
    await this.loadPermissions();
    return this.permissions.filter(item => item.id === id).pop();
  }

  @action.bound
  public async save(model: UserRole) {
    this.clear();
    this.loader.start();
    const response = await this.provider.save(model);
    response?.hasErrors && (this.errors = response.errorsAsArray);
    this.loader.stop();
    return response.success;
  }

  @action.bound
  public async delete(id: number) {
    this.clear();
    this.loader.start();
    const response = await this.provider.delete(id);
    response?.hasErrors && (this.errors = response.errorsAsArray);
    this.loader.stop();
    return response.success;
  }

  @action.bound
  public clear() {
    this.errors = [];
  }
}
