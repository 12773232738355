<template>
  <div>
    <v-menu v-if="actions.length">
      <template v-slot:activator="{ props }">
        <v-btn :density="density" icon="mdi-menu" v-bind="props" />
      </template>

      <v-list>
        <v-list-item v-for="(action, index) in actions" :key="index" :value="index" @click="() => handle(action)">
          <template v-slot:prepend v-if="action.icon">
            <v-icon :icon="action.icon"></v-icon>
          </template>
          <v-list-item-title>{{ action.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <ConfirmModal ref="confirmModal" />
  </div>
</template>

<script lang="ts">
import { DropdownAction } from "/src/entitites/Dropdown.ts";
import ConfirmModal from "/src/primitives/ConfirmModal.vue";
import { Component, Prop, Ref, Vue } from "vue-facing-decorator";

@Component({
  components: { ConfirmModal },
  emits: ["action"],
})
export default class DropdownMenu extends Vue {
  @Prop({ default: () => [] }) actions!: DropdownAction[];
  @Prop({ default: "comfortable" }) density!: null | "default" | "comfortable" | "compact" | any;
  @Ref() readonly confirmModal!: ConfirmModal;

  handle(action: DropdownAction) {
    const confirm = action.confirm;
    if (confirm) {
      this.confirmModal.open({
        title: confirm.title,
        message: confirm.message,
        options: {
          btnApprove: confirm.btnApprove,
          btnDecline: confirm.btnDecline,
        },
        approve: () => this.$emit("action", action),
      });
      return;
    }
    this.$emit("action", action);
  }
}
</script>

<style lang="scss" scoped></style>
