import { FilePackage } from "/src/entitites/FilePackage.ts";
import { fileDecoder } from "/src/services/Decoders/FilesDecoders.ts";
import Decoder, { array, date, field, number, succeed } from "jsonous";
import { any, orNull, toObject } from "./CommonDecoders";

export const packageDecoder = (decodeFiles = true): Decoder<FilePackage> =>
  succeed(FilePackage)
    .assign("id", field("id", number))
    .assign("file", field("file", fileDecoder(false)))
    .assign("nestedFiles", orNull(field("nestedFiles", decodeFiles ? array(fileDecoder(false)) : array(any))))
    .assign("createdAt", orNull(field("createdAt", date)))
    .assign("updatedAt", orNull(field("updatedAt", date)))
    .andThen(toObject(FilePackage));
