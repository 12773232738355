<template>
  <v-card>
    <v-progress-linear :active="store.loader.isLoading" :indeterminate="true" :absolute="true" bottom />
    <v-card-title>Настройка алгоритмов</v-card-title>
    <v-card-text>
      <v-btn class="mb-4" color="primary" @click="openForm">Добавить</v-btn>
      <v-table class="table-rounded" item-key="id" v-if="store.items.length">
        <thead>
          <tr>
            <th class="text-left">id</th>
            <th class="text-left">Название настроек</th>
            <th class="text-left">Название алгоритма</th>
            <th class="text-left">Тип</th>
            <th class="text-left">Действия</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, key) in store.items" :key="key">
            <td>{{ item.id }}</td>
            <td>{{ item.title }}</td>
            <td>{{ item.algorithmTitle }}</td>
            <td>
              <TooltipBtn
                btn-variant="text"
                btn-class="text-blue"
                icon="mdi-pencil"
                @click="openForm(item.id)"
                tooltip="Редактировать"
              />
              <span v-if="!item.fileId">Общий для всех файлов</span>
              <span v-else>
                <TooltipBtn
                  btn-variant="text"
                  btn-class="text-blue"
                  icon="mdi-magnify"
                  :to="urlsProvider.files.detail(item.fileId)"
                  tooltip="К деталям файла"
                />
                Применяется к отдельному файлу
              </span>
            </td>
            <td>
              <DropdownMenu :actions="actions" @action="(action: any) => handleAction(action, item)" />
            </td>
          </tr>
        </tbody>
      </v-table>
      <div v-else>
        <br />
        <Typography :size="16"> Типы отсутствуют </Typography>
      </div>
      <AlgorithmSettingsFormModal ref="formModal" @change="reload" />
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import { AlgorithmSettings } from "/src/entitites/AlgorithmSettings.ts";
import { DropdownAction } from "/src/entitites/Dropdown.ts";
import TooltipBtn from "/src/primitives/TooltipBtn.vue";
import { UrlsProvider } from "/src/services/UrlsProvider.ts";
import { AlgorithmSettingsStore } from "/src/store/AlgorithmSettingsStore.ts";
import AlgorithmSettingsFormModal from "./AlgorithmSettingsFormModal.vue";
import DropdownMenu from "/src/primitives/DropdownMenu.vue";
import Typography from "/src/primitives/Typography.vue";
import { LayoutStore } from "/src/store/LayoutStore.ts";
import { clone } from "ramda";
import { Container } from "typedi";
import { Component, Ref, Vue } from "vue-facing-decorator";

@Component({
  components: { TooltipBtn, Typography, DropdownMenu, AlgorithmSettingsFormModal },
})
export default class AlgorithmSettingsList extends Vue {
  @Ref() readonly formModal!: AlgorithmSettingsFormModal;
  private layoutStore: LayoutStore = Container.get(LayoutStore);
  store = Container.get(AlgorithmSettingsStore);
  urlsProvider = Container.get(UrlsProvider);
  actions: DropdownAction[] = [
    { title: "Изменить", code: "edit", icon: "mdi-pencil" },
    {
      title: "Удалить",
      code: "delete",
      icon: "mdi-trash-can-outline",
      confirm: {
        title: "Подтверждение удаления",
        message: "Вы действительно хотите удалить запись?",
        btnApprove: "Удалить",
      },
    },
  ];

  getType(settings: AlgorithmSettings) {
    if (!settings.fileId) {
      return "Общий для всех файлов";
    }
    return settings.fileId;
  }

  protected async created() {
    await this.reload();
  }

  async reload() {
    await this.store.loadItems();
    await this.store.loadAreaLimitSettings();
  }

  openForm(id: number | null) {
    let item;
    if (id) {
      item = this.store.items.filter(type => type.id === id).pop();
      item && (item = clone(item));
    }
    !item && (item = new AlgorithmSettings());
    this.formModal.open(item);
  }

  async delete(id: number | null) {
    id && (await this.store.delete(id));
    this.layoutStore.success("Запись удалена");
    await this.reload();
  }

  handleAction(action: DropdownAction, item: AlgorithmSettings) {
    switch (action.code) {
      case "edit":
        return this.openForm(item.id);
      case "delete":
        return this.delete(item.id);
    }
  }
}
</script>

<style lang="scss" scoped></style>
