export enum AlgorithmCodeEnum {
  detectDefectsByBrightness = "detectDefectsByBrightness",
  detectDefectsByHistogram = "detectDefectsByHistogram",
  detectDefectsByThroughHoles = "detectDefectsByThroughHoles",
  detectDefectsByKnots = "detectDefectsByKnots",
  detectBorders = "detectBorders",
  detectWorkingArea = "detectWorkingArea",
  cumulativeResults = "cumulativeResults",
  manualResults = "manualResults",
  defineDefectTypes = "defineDefectTypes",
}

export const CommonSettingsCodes: string[] = [
  AlgorithmCodeEnum.detectBorders,
  AlgorithmCodeEnum.detectWorkingArea,
  AlgorithmCodeEnum.detectDefectsByBrightness,
  AlgorithmCodeEnum.detectDefectsByHistogram,
  AlgorithmCodeEnum.detectDefectsByThroughHoles,
  AlgorithmCodeEnum.detectDefectsByKnots,
];

export const ContoursSettingsCodes: string[] = [
  AlgorithmCodeEnum.detectBorders,
  AlgorithmCodeEnum.detectWorkingArea,
  AlgorithmCodeEnum.detectDefectsByBrightness,
  AlgorithmCodeEnum.detectDefectsByHistogram,
  AlgorithmCodeEnum.detectDefectsByThroughHoles,
  AlgorithmCodeEnum.detectDefectsByKnots,
  AlgorithmCodeEnum.defineDefectTypes,
];
