import {
  AlgorithmSettings,
  CommonSettingsData,
  CumulativeResultsSettingsData,
  DefineDefectTypeCondition,
  DefineDefectTypesConfiguration,
  DefineDefectTypesSettingsData,
  DetectDefectsByBrightnessSettingsData,
  DetectDefectsByKnotsSettingsData,
  DetectDefectsByThroughHolesSettingsData,
  DetectWorkingAreaSettingsData,
  ManualResultsSettingsData,
} from "/src/entitites/AlgorithmSettings.ts";
import { AlgorithmCodeEnum } from "/src/enums/AlgorithmCodeEnum.ts";
import { decodeLineStyle } from "/src/services/Decoders/ContourDecoders.ts";
import Decoder, { array, field, number, string, succeed } from "jsonous";
import { any, orNull, toObject } from "./CommonDecoders";

function decodeCommonData(decoder: Decoder<any>): Decoder<CommonSettingsData> {
  return decodeLineStyle(decoder).andThen(toObject(CommonSettingsData));
}

function process<T>(decoder: T, handlers: ((decoder: T) => T)[]) {
  handlers.forEach(handler => (decoder = handler(decoder)));
  return decoder;
}

function decodeWidthHeightRatio(decoder: Decoder<any>) {
  return decoder
    .assign("widthHeightRatioLess", orNull(field("widthHeightRatioLess", number)))
    .assign("widthHeightRatioLessOrEqual", orNull(field("widthHeightRatioLessOrEqual", number)))
    .assign("widthHeightRatioMore", orNull(field("widthHeightRatioMore", number)))
    .assign("widthHeightRatioMoreOrEqual", orNull(field("widthHeightRatioMoreOrEqual", number)));
}

function decodeSquareParams(decoder: Decoder<any>) {
  return decoder
    .assign("squareLess", orNull(field("squareLess", number)))
    .assign("squareLessOrEqual", orNull(field("squareLessOrEqual", number)))
    .assign("squareMore", orNull(field("squareMore", number)))
    .assign("squareMoreOrEqual", orNull(field("squareMoreOrEqual", number)));
}

function decodeHsvParams(decoder: Decoder<any>) {
  return decoder
    .assign("minHsvHue", orNull(field("minHsvHue", number)))
    .assign("minHsvSaturation", orNull(field("minHsvSaturation", number)))
    .assign("minHsvBrightness", orNull(field("minHsvBrightness", number)))
    .assign("maxHsvHue", orNull(field("maxHsvHue", number)))
    .assign("maxHsvSaturation", orNull(field("maxHsvSaturation", number)))
    .assign("maxHsvBrightness", orNull(field("maxHsvBrightness", number)));
}

function decodeDefectsByBrightnessData(decoder: Decoder<any>): Decoder<DetectDefectsByBrightnessSettingsData> {
  return process(decoder, [decodeWidthHeightRatio, decodeSquareParams])
    .assign("selectionType", orNull(field("selectionType", string)))
    .assign("areaLimitSettingsId", orNull(field("areaLimitSettingsId", number)))
    .assign("inRangeMaskCoefficient", orNull(field("inRangeMaskCoefficient", number)))
    .andThen(toObject(DetectDefectsByBrightnessSettingsData));
}

function decodeDefectsByThroughHolesData(decoder: Decoder<any>): Decoder<DetectDefectsByThroughHolesSettingsData> {
  return process(decoder, [decodeSquareParams, decodeHsvParams])
    .assign("selectionType", orNull(field("selectionType", string)))
    .assign("areaLimitSettingsId", orNull(field("areaLimitSettingsId", number)))
    .andThen(toObject(DetectDefectsByThroughHolesSettingsData));
}

function decodeDefectsByKnotsData(decoder: Decoder<any>): Decoder<DetectDefectsByKnotsSettingsData> {
  return process(decoder, [decodeHsvParams])
    .assign("selectionType", orNull(field("selectionType", string)))
    .assign("areaLimitSettingsId", orNull(field("areaLimitSettingsId", number)))
    .assign("ellipseAreaRatioLess", orNull(field("ellipseAreaRatioLess", number)))
    .assign("ellipseAreaRatioLessOrEqual", orNull(field("ellipseAreaRatioLessOrEqual", number)))
    .assign("ellipseAreaRatioMore", orNull(field("ellipseAreaRatioMore", number)))
    .assign("ellipseAreaRatioMoreOrEqual", orNull(field("ellipseAreaRatioMoreOrEqual", number)))
    .andThen(toObject(DetectDefectsByKnotsSettingsData));
}

function decodeCumulativeResultsData(decoder: Decoder<any>): Decoder<CumulativeResultsSettingsData> {
  return decoder
    .assign("siblingSettingsIds", orNull(field("siblingSettingsIds", array(number)), []))
    .andThen(toObject(CumulativeResultsSettingsData));
}

function decodeManualResultsData(decoder: Decoder<any>): Decoder<ManualResultsSettingsData> {
  return decoder
    .assign("siblingSettingsIds", orNull(field("siblingSettingsIds", array(number)), []))
    .andThen(toObject(ManualResultsSettingsData));
}

function decodeWorkingAreaData(decoder: Decoder<any>): Decoder<DetectWorkingAreaSettingsData> {
  return decoder
    .assign("sourceWidth", orNull(field("sourceWidth", number)))
    .assign("sourceHeight", orNull(field("sourceHeight", number)))
    .assign("targetWidth", orNull(field("targetWidth", number)))
    .assign("targetHeight", orNull(field("targetHeight", number)))
    .andThen(toObject(DetectWorkingAreaSettingsData));
}

function decodeDefineDefectTypesCondition(): Decoder<DefineDefectTypeCondition> {
  return succeed(DefineDefectTypesConfiguration)
    .assign("conditionId", orNull(field("conditionId", string)))
    .assign("params", orNull(field("params", any), {}))
    .andThen(toObject(DefineDefectTypeCondition));
}

function decodeDefineDefectTypesConfiguration(): Decoder<DefineDefectTypesConfiguration> {
  return succeed(DefineDefectTypesConfiguration)
    .assign("defectTypeId", orNull(field("defectTypeId", number)))
    .assign("conditions", field("conditions", array(decodeDefineDefectTypesCondition())))
    .assign("orderId", orNull(field("orderId", number)))
    .andThen(toObject(DefineDefectTypesConfiguration));
}

function decodeDefineDefectTypesData(decoder: Decoder<any>): Decoder<DefineDefectTypesSettingsData> {
  return decoder
    .assign("sourceSettingsIds", field("sourceSettingsIds", array(number)))
    .assign("typeConfigurations", field("typeConfigurations", array(decodeDefineDefectTypesConfiguration())))
    .andThen(toObject(DefineDefectTypesSettingsData));
}

function decodeData(name: string, decoder: Decoder<any>): Decoder<any> {
  return new Decoder<any>(item => {
    const data = item[name];
    if (!data) {
      return orNull(field(name, decoder)).decodeAny(item);
    }
    const dataDecoder = decodeCommonData(succeed(data));
    switch (item["code"]) {
      case AlgorithmCodeEnum.detectDefectsByBrightness:
        return decodeDefectsByBrightnessData(dataDecoder).decodeAny(data);
      case AlgorithmCodeEnum.detectDefectsByThroughHoles:
        return decodeDefectsByThroughHolesData(dataDecoder).decodeAny(data);
      case AlgorithmCodeEnum.cumulativeResults:
        return decodeCumulativeResultsData(dataDecoder).decodeAny(data);
      case AlgorithmCodeEnum.manualResults:
        return decodeManualResultsData(dataDecoder).decodeAny(data);
      case AlgorithmCodeEnum.detectWorkingArea:
        return decodeWorkingAreaData(dataDecoder).decodeAny(data);
      case AlgorithmCodeEnum.detectDefectsByKnots:
        return decodeDefectsByKnotsData(dataDecoder).decodeAny(data);
      case AlgorithmCodeEnum.defineDefectTypes:
        return decodeDefineDefectTypesData(dataDecoder).decodeAny(data);
      default:
        return dataDecoder.assign(name, any).decodeAny(data);
    }
  });
}

export const algorithmSettingsDecoder = (): Decoder<AlgorithmSettings> =>
  succeed(AlgorithmSettings)
    .assign("id", orNull(field("id", number)))
    .assign("code", field("code", string))
    .assign("title", orNull(field("title", string)))
    .assign("algorithmTitle", field("algorithmTitle", string))
    .assign("data", decodeData("data", any))
    .assign("fileId", orNull(field("fileId", number)))
    .andThen(toObject(AlgorithmSettings));
