import { FRONT_ENDPOINTS } from "/src/endpoints";
import { defectsComparisonRoutes } from "/src/modules/DefectsComparison";
import { filesRoutes } from "/src/modules/Files";
import { homeRoutes } from "/src/modules/Home/index.ts";
import { loginRoutes } from "/src/modules/Login";
import { packagesRoutes } from "/src/modules/Packages";
import { settingsRoutes } from "/src/modules/Settings";
import { METHODS } from "/src/services/RequestSubmitter/Methods.ts";
import { RequestSubmitter } from "/src/services/RequestSubmitter/RequestSubmitter.ts";
import { UrlsProvider } from "/src/services/UrlsProvider.ts";
import { AuthStore } from "/src/store/AuthStore.ts";
import { LayoutStore } from "/src/store/LayoutStore.ts";
import { AxiosError, HttpStatusCode } from "axios";
import { Container } from "typedi";
import { createRouter, createWebHistory } from "vue-router";

const routes = [
  ...homeRoutes,
  ...filesRoutes,
  ...packagesRoutes,
  ...settingsRoutes,
  ...defectsComparisonRoutes,
  ...loginRoutes,
];

const handleError = (code: HttpStatusCode, error: AxiosError, handler: () => void): void => {
  if (code === error.response?.status) {
    handler();
  }
};

RequestSubmitter.beforeErrorMiddleware.push((_, error) => {
  handleError(HttpStatusCode.Unauthorized, error as AxiosError<any>, async () => {
    const authStore = Container.get(AuthStore);
    const urlsProvider = Container.get(UrlsProvider);
    if (router.currentRoute?.value?.path !== FRONT_ENDPOINTS.LOGIN) {
      await authStore.logout(urlsProvider.loginUrls.loginForm(router.currentRoute));
    }
  });
});

RequestSubmitter.beforeErrorMiddleware.push((config, error) => {
  const store: LayoutStore = Container.get(LayoutStore);
  handleError(HttpStatusCode.Forbidden, error as AxiosError<any>, () => {
    config.method === METHODS.GET ? store.error("Доступ запрещен") : store.error("Действие запрещено");
  });
  handleError(HttpStatusCode.InternalServerError, error as AxiosError<any>, () => {
    store.error("Внутренняя ошибка сервера.");
  });
});

export const router = createRouter({
  history: createWebHistory(),
  routes,
});
