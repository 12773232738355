<template>
  <v-card class="mb-2 two-cols-card">
    <v-card-text>
      <v-switch
        label="Использовать отдельные источники для проверки условия"
        v-model="useSourceSettingsIds"
        @change="() => !useSourceSettingsIds && (data.sourceSettingsIds = [])"
      />
      <v-select
        v-if="useSourceSettingsIds"
        v-model="data.sourceSettingsIds"
        label="Отдельные источники для проверки на вложенность"
        item-title="title"
        item-value="id"
        multiple
        chips
        closableChips
        clearable
        :items="algorithmSettingsList"
      />
      <v-select
        class="mb-2"
        v-model="data.types"
        label="Вариант проверки"
        item-title="title"
        item-value="id"
        hide-details="auto"
        multiple
        chips
        clearable
        :items="includesContourTypes"
      />
      <v-select
        v-if="data.types?.length > 1"
        class="mb-2"
        v-model="data.checkType"
        label="Тип проверки выбранных вариантов"
        item-title="title"
        item-value="id"
        hide-details="auto"
        :items="conditionsCheckTypes"
      />
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import type { IncludesContourParams } from "/src/entitites/AlgorithmSettings/IncludesContourParams.ts";
import { SelectItem } from "/src/entitites/Select.ts";
import { CommonSettingsCodes } from "/src/enums/AlgorithmCodeEnum.ts";
import Typography from "/src/primitives/Typography.vue";
import { AlgorithmSettingsStore } from "/src/store/AlgorithmSettingsStore.ts";
import { Container } from "typedi";
import { Component, Prop, Vue } from "vue-facing-decorator";

@Component({
  components: { Typography },
})
export default class IncludesContourFields extends Vue {
  @Prop() readonly data!: IncludesContourParams;
  includesContourTypes: SelectItem[] = [];
  conditionsCheckTypes: SelectItem[] = [];
  private store = Container.get(AlgorithmSettingsStore);
  useSourceSettingsIds = false;

  async mounted() {
    this.includesContourTypes = await this.store.loadIncludesContourTypes();
    this.conditionsCheckTypes = await this.store.loadConditionsCheckTypes();
    await this.store.loadItems();
    this.useSourceSettingsIds = !!this.data.sourceSettingsIds?.length;
  }

  get algorithmSettingsList() {
    return this.store.items
      .filter(item => CommonSettingsCodes.includes(item.code))
      .map(item => ({
        title: `#${item.id} ${item.title} (${!item.fileId ? "общий" : "для отдельного файла"})`,
        id: item.id,
      }));
  }
}
</script>

<style lang="scss" scoped></style>
