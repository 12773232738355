<template>
  <v-card>
    <v-progress-linear :active="store.loader.isLoading" :indeterminate="true" :absolute="true" bottom />
    <v-card-title>Настройки типов загружаемых файлов</v-card-title>
    <v-card-text>
      <v-btn color="primary" @click="openForm">Добавить</v-btn>
      <v-table class="table-rounded" item-key="id" v-if="store.types.length">
        <thead>
          <tr>
            <th class="text-left">id</th>
            <th class="text-left">Название</th>
            <th class="text-left">Расширения</th>
            <th class="text-left">Обработчики</th>
            <th class="text-left">Действия</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(type, key) in store.types" :key="key">
            <td>{{ type.id }}</td>
            <td>{{ type.title }}</td>
            <td>{{ type.extensions.join(", ") }}</td>
            <td>
              <div v-for="handlerCode in getSortedHandlers(type)" :key="handlerCode">
                <v-chip v-if="getHandler(handlerCode)" class="ma-2" size="small">
                  {{ getHandler(handlerCode)?.title }}
                </v-chip>
              </div>
            </td>
            <td>
              <DropdownMenu :actions="actions" @action="(action: any) => handleAction(action, type)" />
            </td>
          </tr>
        </tbody>
      </v-table>
      <div v-else>
        <br />
        <Typography :size="16"> Типы отсутствуют </Typography>
      </div>
      <FileTypeFormModal ref="formModal" @change="reload" />
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import { DropdownAction } from "/src/entitites/Dropdown.ts";
import { FileTypeSettings } from "/src/entitites/FileType.ts";
import FileTypeFormModal from "./FileTypeFormModal.vue";
import DropdownMenu from "/src/primitives/DropdownMenu.vue";
import Typography from "/src/primitives/Typography.vue";
import { FileTypesStore } from "/src/store/FileTypesStore.ts";
import { LayoutStore } from "/src/store/LayoutStore.ts";
import { clone } from "ramda";
import { Container } from "typedi";
import { Component, Ref, Vue } from "vue-facing-decorator";

@Component({
  components: { Typography, DropdownMenu, FileTypeFormModal },
})
export default class FileTypesSettings extends Vue {
  @Ref() readonly formModal!: FileTypeFormModal;
  private layoutStore: LayoutStore = Container.get(LayoutStore);
  store = Container.get(FileTypesStore);
  actions: DropdownAction[] = [
    { title: "Изменить", code: "edit", icon: "mdi-pencil" },
    {
      title: "Удалить",
      code: "delete",
      icon: "mdi-trash-can-outline",
      confirm: {
        title: "Подтверждение удаления",
        message: "Вы действительно хотите удалить запись?",
        btnApprove: "Удалить",
      },
    },
  ];

  protected async created() {
    await this.reload();
  }

  async reload() {
    await this.store.loadItems();
  }

  openForm(id: number | null) {
    let item;
    if (id) {
      item = this.store.types.filter(type => type.id === id).pop();
      item && (item = clone(item));
    }
    !item && (item = new FileTypeSettings());
    this.formModal.open(item);
  }

  async delete(id: number) {
    await this.store.delete(id);
    this.layoutStore.success("Запись удалена");
    await this.reload();
  }

  handleAction(action: DropdownAction, type: FileTypeSettings) {
    switch (action.code) {
      case "edit":
        return this.openForm(type.id);
      case "delete":
        return this.delete(type.id);
    }
  }

  getHandler(code: string) {
    return this.store.handlers.filter(handler => handler.code === code).pop();
  }

  getSortedHandlers(type: FileTypeSettings) {
    return type.handlers.sort((code1: string, code2: string) => {
      const handler1 = this.getHandler(code1);
      const handler2 = this.getHandler(code2);
      if (!handler1) return 1;
      if (!handler2) return -1;
      return handler1.sort - handler2.sort;
    });
  }
}
</script>

<style lang="scss" scoped></style>
