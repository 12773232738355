import { AlgorithmConditionType, AlgorithmSelectionType, AlgorithmSettings } from "/src/entitites/AlgorithmSettings.ts";
import { Loader } from "/src/entitites/Loader";
import { SaveAlgorithmSettingsBody } from "/src/entitites/Requests/SaveAlgorithmSettings.ts";
import { AlgorithmCodeEnum } from "/src/enums/AlgorithmCodeEnum.ts";
import { AlgorithmSettingsProvider } from "/src/services/AlgorithmSettingsProvider.ts";
import { Container, Service } from "typedi";
import { action, observable } from "mobx";

@Service({ global: true })
export class AlgorithmSettingsStore {
  @observable public items: AlgorithmSettings[] = [];
  @observable public areaLimitSettings: AlgorithmSettings[] = [];
  @observable public types: AlgorithmSettings[] = [];
  @observable public selectionTypes: AlgorithmSelectionType[] = [];
  @observable public conditionTypes: AlgorithmConditionType[] = [];
  @observable public loader = new Loader();
  @observable public errors: string[] = [];
  private provider = Container.get(AlgorithmSettingsProvider);

  @action.bound
  public async loadItems() {
    this.loader.start();
    const result = await this.provider.getItems();
    this.items = result.data.items;
    this.loader.stop();
  }

  @action.bound
  public async loadTypes() {
    this.loader.start();
    const result = await this.provider.getTypes();
    this.types = result.data.items;
    this.loader.stop();
  }

  @action.bound
  public async loadSelectionTypes() {
    this.loader.start();
    const result = await this.provider.getSelectionTypes();
    this.selectionTypes = result.data.items;
    this.loader.stop();
  }

  @action.bound
  public async loadConditionTypes() {
    this.loader.start();
    const result = await this.provider.getConditionTypes();
    this.conditionTypes = result.data.items;
    this.loader.stop();
  }

  public async loadIncludesContourTypes() {
    return (await this.provider.getIncludeContourTypes()).data.items || [];
  }

  public async loadConditionsCheckTypes() {
    return (await this.provider.getConditionsCheckTypes()).data.items || [];
  }

  public async loadItem(params: any) {
    this.loader.start();
    const result = await this.provider.getItem(params);
    this.loader.stop();
    return result;
  }

  public async loadAreaLimitSettings() {
    const codes: string[] = [AlgorithmCodeEnum.detectBorders, AlgorithmCodeEnum.detectWorkingArea];
    this.areaLimitSettings = this.items.filter(item => codes.includes(item.code));
    const emptyAlgorithm = new AlgorithmSettings();
    emptyAlgorithm.id = 0;
    emptyAlgorithm.title = "Не ограничивать";
    this.areaLimitSettings.unshift(emptyAlgorithm);
  }

  public async save(data: SaveAlgorithmSettingsBody) {
    this.loader.start();
    const result = await this.provider.save(data);
    this.loader.stop();
    return result;
  }

  public async delete(id: number, params: any = {}) {
    this.loader.start();
    const response = await this.provider.delete(id, params);
    this.loader.stop();
    return response;
  }

  @action.bound
  public clear() {
    this.errors = [];
  }
}
