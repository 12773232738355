import "reflect-metadata";
import { createApp } from "vue";
import VueCookies from "vue-cookies";
import App from "./App.vue";
import "./assets/css/style.scss";
import { router } from "./modules/Router";
import vuetify from "./plugins/vuetify.ts";

export const app = createApp(App);
app.use(vuetify);
app.use(VueCookies, { expires: "7d" });
app.use(router);

app.mount("#app");
