import { FRONT_ENDPOINTS } from "/src/endpoints";
import AlgorithmSettingsList from "/src/modules/Settings/Algorithms/AlgorithmSettingsList.vue";
import UserRolesList from "/src/modules/Settings/UserRoles/UserRolesList.vue";
import UsersList from "/src/modules/Settings/Users/UsersList.vue";
import DefectTypesList from "./DefectTypes/DefectTypesList.vue";
import FileTypesSettings from "./FileTypes/FileTypesSettings.vue";

export const settingsRoutes = [
  { path: FRONT_ENDPOINTS.SETTINGS_FILE_TYPES, component: FileTypesSettings },
  { path: FRONT_ENDPOINTS.SETTINGS_ALGORITHMS, component: AlgorithmSettingsList },
  { path: FRONT_ENDPOINTS.SETTINGS_DEFECT_TYPES, component: DefectTypesList },
  { path: FRONT_ENDPOINTS.SETTINGS_USERS_LIST, component: UsersList },
  { path: FRONT_ENDPOINTS.SETTINGS_USER_ROLES_LIST, component: UserRolesList },
];
