<template>
  <v-expansion-panels v-model="panel" class="mb-2" v-if="settings.code">
    <v-expansion-panel>
      <v-expansion-panel-title>{{ `Настройки алгоритма «${settings.title}»` }}</v-expansion-panel-title>
      <v-expansion-panel-text>
        <v-switch
          v-model="individualSettings"
          :label="`Использовать ${individualSettings ? 'индивидуальные для файла' : 'общие'} настройки`"
          @change="changeIndividual"
        ></v-switch>
        <div class="mb-4">
          <AlgorithmSettingsFields :code="result.code" :settings="settings" />
        </div>
        <v-btn :disabled="isLoading" prepend-icon="mdi-play-speed" color="primary" @click="saveAndApply">
          Сохранить и запустить алгоритмы
        </v-btn>
        <v-overlay v-if="isLoading" :model-value="true" contained persistent class="align-center justify-center">
          <v-progress-circular indeterminate :size="70" :width="7" color="primary" />
        </v-overlay>
      </v-expansion-panel-text>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script lang="ts">
import { ContoursAlgorithmResult } from "/src/entitites/AlgorithmResult.ts";
import { AlgorithmSettings } from "/src/entitites/AlgorithmSettings.ts";
import { UploadedFile } from "/src/entitites/UploadedFile.ts";
import AlgorithmSettingsFields from "/src/modules/Settings/Algorithms/Fields/AlgorithmSettingsFields.vue";
import { AlgorithmSettingsStore } from "/src/store/AlgorithmSettingsStore.ts";
import { LayoutStore } from "/src/store/LayoutStore.ts";
import { Container } from "typedi";
import { Component, Prop, Vue, Watch } from "vue-facing-decorator";

@Component({
  components: {
    AlgorithmSettingsFields,
  },
})
export default class AlgorithmIndividualSettings extends Vue {
  @Prop() file!: UploadedFile;
  @Prop() result!: ContoursAlgorithmResult;
  @Prop() isLoading!: boolean;
  @Prop({ default: false }) collapsed!: boolean;
  panel: number[] = [];
  individualSettings = false;
  settingsStore = Container.get(AlgorithmSettingsStore);
  layoutStore: LayoutStore = Container.get(LayoutStore);
  settings = new AlgorithmSettings();

  async mounted() {
    !this.collapsed && (this.panel = [0]);
    this.loadSettings();
    if (!this.settingsStore.loader.isLoading && !this.settingsStore.items.length) {
      await this.settingsStore.loadItems();
      await this.settingsStore.loadAreaLimitSettings();
    }
  }

  @Watch("result")
  loadSettings() {
    this.individualSettings = !!this.result.individualSettingsId;
    if (this.result.individualSettings) {
      this.settings = Object.assign(this.settings, this.result.individualSettings);
    } else if (this.result.settings) {
      this.settings = Object.assign(this.settings, this.result.settings);
    }
  }

  changeIndividual() {
    if (this.individualSettings && this.result.individualSettings) {
      this.settings = Object.assign(this.settings, this.result.individualSettings);
    } else if (this.result.settings) {
      this.settings = Object.assign(this.settings, this.result.settings);
      this.individualSettings && (this.settings.id = null);
    }
  }

  async saveAndApply() {
    let result;
    result = await this.settingsStore.save({
      settings: this.individualSettings ? null : this.settings,
      individualSettings: this.individualSettings ? this.settings : null,
      algorithmResultId: this.result.id,
    });
    if (!this.individualSettings && this.result.individualSettingsId) {
      result = await this.settingsStore.delete(this.result.individualSettingsId, {
        saveResults: true,
      });
    }
    if (result && result.hasErrors) {
      result.errorsAsArray.forEach(error => this.layoutStore.error(error));
      return;
    }
    this.layoutStore.success("Настройки сохранены");
    await this.$emit("settings:updated");
  }
}
</script>

<style lang="scss" scoped>
.v-expansion-panel {
  position: relative;
}
</style>
