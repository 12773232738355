import { OptionsInterface, RequestOptions } from "./RequestSubmitter.ts";

class Params implements OptionsInterface {
  urlParams: { [name: string]: string | number };
  progressReceiver?: (progress: number) => void;

  constructor() {
    this.urlParams = {};
  }
}

class Options implements RequestOptions {
  body?: any;
  options: Params;

  constructor() {
    this.options = new Params();
  }

  addUrlParam(name: string | number, value: string | number): this {
    this.options.urlParams[name] = value;
    return this;
  }

  setBody(body: any): this {
    this.body = body;
    return this;
  }

  setProgressReceiver(progressReceiver?: (progress: number) => void): this {
    this.options.progressReceiver = progressReceiver;
    return this;
  }
}

export function createRequestOptions() {
  return new Options();
}
