<template>
  <v-card height="100%">
    <v-card-text>
      <Canvas :file="file" :manual-result="result" :manual-buttons="true">
        <template v-slot:after-result>
          <v-card v-if="result.data.manualContours.length">
            <v-card-title>
              <Typography :size="16"> Все обнаруженные алгоритмами дефекты обработаны </Typography>
            </v-card-title>
            <v-card-text> Проверьте все ли дефекты на изображении указаны </v-card-text>
            <v-card-actions>
              <v-btn variant="elevated" prepend-icon="mdi-floppy" color="primary" @click="save">
                Сохранить результаты
              </v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </Canvas>
    </v-card-text>
    <v-card-title>
      <Typography size="18">Ручное определение дефектов</Typography>
    </v-card-title>
    <v-card-subtitle>
      <div v-if="result.data?.contoursCount">
        <v-icon icon="mdi-check-bold" class="text-green"></v-icon>
        Отмечено дефектов: {{ result.data?.contoursCount }}
      </div>
      <div v-else>
        <v-icon icon="mdi-close-thick" class="text-red"></v-icon>
        Ни одного дефекта не отмечено
      </div>
    </v-card-subtitle>
  </v-card>
</template>

<script lang="ts">
import { ManualAlgorithmResult } from "/src/entitites/ManualAlgorithmResult.ts";
import { UploadedFile } from "/src/entitites/UploadedFile.ts";
import Canvas from "/src/modules/Canvas/Canvas.vue";
import AlgorithmIndividualSettings from "/src/modules/Files/AlgorithmResults/AlgorithmIndividualSettings.vue";
import Typography from "/src/primitives/Typography.vue";
import { LayoutStore } from "/src/store/LayoutStore.ts";
import { ManualAlgorithmResultsStore } from "/src/store/ManualAlgorithmResultsStore.ts";
import { Container } from "typedi";
import { Component, Prop, Vue } from "vue-facing-decorator";

@Component({
  components: { AlgorithmIndividualSettings, Canvas, Typography },
})
export default class DetectDefectsManualView extends Vue {
  @Prop() file!: UploadedFile;
  @Prop() result!: ManualAlgorithmResult;
  private store = Container.get(ManualAlgorithmResultsStore);
  private layoutStore = Container.get(LayoutStore);

  async save() {
    const result = await this.store.save(this.result);
    if (result.hasErrors) {
      return result.errorsAsArray.forEach(error => this.layoutStore.error(error));
    }
    this.layoutStore.success("Изменения сохранены");
    this.$emit("reload");
  }
}
</script>

<style lang="scss" scoped></style>
