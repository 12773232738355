import { CanvasData } from "/src/entitites/CanvasData.ts";
import { CanvasZoomStore } from "/src/store/CanvasZoomStore.ts";
import { Service } from "typedi";

type CalculateDto = {
  canvasData: CanvasData;
  image: HTMLImageElement;
  zoomStore: CanvasZoomStore;
};

@Service({ global: true })
export class CanvasSizesProvider {
  public getSizes(dto: CalculateDto) {
    if (dto.canvasData.isModal) {
      const containerHeight = dto.canvasData.containerHeight;
      const height = dto.zoomStore.currentValue * containerHeight;
      const ratio = height / dto.image.naturalHeight;
      const width = ratio * dto.image.naturalWidth;
      return { width, height, ratio };
    }
    const width = dto.canvasData.containerWidth;
    const ratio = width / dto.image.naturalWidth;
    const height = ratio * dto.image.naturalHeight;
    return { width, height, ratio };
  }
}
