import { API_ENDPOINTS } from "/src/endpoints";
import { BaseResponse } from "/src/entitites/ResponseEntity.ts";
import { Service } from "typedi";
import { baseResponse } from "./Decoders/CommonDecoders.ts";
import { METHODS } from "./RequestSubmitter/Methods";
import { createRequestOptions } from "./RequestSubmitter/RequestOptions.ts";
import { RequestSubmitter } from "./RequestSubmitter/RequestSubmitter.ts";

@Service({ global: true })
export class AuthProvider extends RequestSubmitter {
  private loginRequest = this.createRequest(API_ENDPOINTS.LOGIN, METHODS.POST, baseResponse());
  private logoutRequest = this.createRequest(API_ENDPOINTS.LOGOUT, METHODS.POST, baseResponse());

  public async login(params: any): Promise<BaseResponse> {
    return await this.loginRequest(createRequestOptions().setBody(params));
  }

  public async logout(): Promise<BaseResponse> {
    return await this.logoutRequest();
  }
}
