import { FRONT_ENDPOINTS } from "/src/endpoints";
import { Loader } from "/src/entitites/Loader.ts";
import { router } from "/src/modules/Router";
import { AuthProvider } from "/src/services/AuthProvider.ts";
import { Container, Service } from "typedi";

import { action, observable } from "mobx";
import type { RouteLocationRaw } from "vue-router";

@Service({ global: true })
export class AuthStore {
  public loader = new Loader();
  private provider = Container.get(AuthProvider);
  @observable errors!: string[];

  @action.bound
  public async login(name: string, password: string, remember: boolean) {
    this.errors = [];
    this.loader.start();
    const result = await this.provider.login({ name, password, remember });
    this.loader.stop();
    if (result.hasErrors) {
      return (this.errors = result.errorsAsArray);
    }
    await router.push((router.currentRoute as any)?.query?.redirect || FRONT_ENDPOINTS.HOME);
  }

  public async logout(redirect: RouteLocationRaw) {
    await this.provider.logout();
    window.location.assign(router.resolve(redirect).href);
  }
}
