import { FRONT_ENDPOINTS } from "/src/endpoints";
import { UrlGenerator } from "/src/services/UrlGenerator.ts";
import { castRouteParams } from "/src/utils/routeParams.ts";
import { Container } from "typedi";
import PackageDetail from "./PackageDetail.vue";
import PackagesList from "./PackagesList.vue";

const urlGenerator = Container.get(UrlGenerator);

export const packagesRoutes = [
  { path: FRONT_ENDPOINTS.PACKAGES_LIST, component: PackagesList },
  {
    path: FRONT_ENDPOINTS.PACKAGES_DETAIL,
    component: PackageDetail,
    props: castRouteParams,
  },
];

export const packagesUrls = {
  detail: (id: number) => urlGenerator.replaceParams(FRONT_ENDPOINTS.PACKAGES_DETAIL, { id }),
  list: () => FRONT_ENDPOINTS.PACKAGES_LIST,
};
