import {
  AlgorithmResult,
  ContoursAlgorithmData,
  CumulativeResultsData,
  TypedContoursAlgorithmData,
} from "/src/entitites/AlgorithmResult.ts";
import { ManualAlgorithmData } from "/src/entitites/ManualAlgorithmResult.ts";
import { AlgorithmCodeEnum } from "/src/enums/AlgorithmCodeEnum.ts";
import Decoder, { array, boolean, field, number, string, succeed } from "jsonous";
import { algorithmSettingsDecoder } from "./AlgorithmSettingsDecoders.ts";
import { any, orNull, toObject } from "./CommonDecoders";
import { decodePoint, decodeTypedContour } from "./ContourDecoders.ts";
import { fileDecoder } from "./FilesDecoders.ts";

function decodeContoursAlgorithmData(): Decoder<ContoursAlgorithmData> {
  return succeed(ContoursAlgorithmData)
    .assign("contoursCount", orNull(field("contoursCount", number), 0))
    .assign("contoursPoints", orNull(field("contoursPoints", array(array(decodePoint())))))
    .assign("success", orNull(field("success", boolean), false))
    .assign("errors", orNull(field("errors", array(string))))
    .andThen(toObject(ContoursAlgorithmData));
}

function decodeCumulativeResultsData(): Decoder<CumulativeResultsData> {
  return succeed(CumulativeResultsData)
    .assign("siblingResults", orNull(field("siblingResults", array(algorithmResultDecoder())), []))
    .andThen(toObject(CumulativeResultsData));
}

function decodeManualResultsData(): Decoder<ManualAlgorithmData> {
  return succeed(ManualAlgorithmData)
    .assign("contoursCount", orNull(field("contoursCount", number), 0))
    .assign("siblingResults", orNull(field("siblingResults", array(algorithmResultDecoder())), []))
    .assign("manualContours", orNull(field("manualContours", array(decodeTypedContour())), []))
    .assign("success", orNull(field("success", boolean), false))
    .assign("errors", orNull(field("errors", array(string)), []))
    .andThen(toObject(ManualAlgorithmData));
}

function decodeTypedContoursAlgorithmData(): Decoder<TypedContoursAlgorithmData> {
  return succeed(TypedContoursAlgorithmData)
    .assign("contoursCount", orNull(field("contoursCount", number), 0))
    .assign("contoursPoints", orNull(field("contoursPoints", array(array(decodePoint())))))
    .assign("typedContours", orNull(field("typedContours", array(decodeTypedContour())), []))
    .assign("success", orNull(field("success", boolean), false))
    .assign("errors", orNull(field("errors", array(string)), []))
    .andThen(toObject(TypedContoursAlgorithmData));
}

function decodeData(name: string): Decoder<any> {
  return new Decoder<any>(item => {
    const data = item[name];
    if (!data) {
      return orNull(field(name, any)).decodeAny(item);
    }
    const dataDecoder = succeed(data);
    switch (item["code"]) {
      case AlgorithmCodeEnum.detectBorders:
      case AlgorithmCodeEnum.detectWorkingArea:
      case AlgorithmCodeEnum.detectDefectsByBrightness:
      case AlgorithmCodeEnum.detectDefectsByHistogram:
      case AlgorithmCodeEnum.detectDefectsByThroughHoles:
        return dataDecoder.decodeAny(decodeContoursAlgorithmData());
      case AlgorithmCodeEnum.cumulativeResults:
        return dataDecoder.decodeAny(decodeCumulativeResultsData());
      case AlgorithmCodeEnum.manualResults:
        return dataDecoder.decodeAny(decodeManualResultsData());
      case AlgorithmCodeEnum.defineDefectTypes:
        return dataDecoder.decodeAny(decodeTypedContoursAlgorithmData());
      default:
        return dataDecoder.decodeAny(data);
    }
  });
}

export const algorithmResultDecoder = (
  type: { new (): AlgorithmResult } = AlgorithmResult,
  withFile = false,
): Decoder<AlgorithmResult> =>
  succeed(type)
    .assign("id", field("id", number))
    .assign("code", field("code", string))
    .assign("settingsId", orNull(field("settingsId", number)))
    .assign("settings", orNull(field("settings", algorithmSettingsDecoder())))
    .assign("individualSettingsId", orNull(field("individualSettingsId", number)))
    .assign("individualSettings", orNull(field("individualSettings", algorithmSettingsDecoder())))
    .assign("data", decodeData("data"))
    .assign("file", orNull(field("file", withFile ? fileDecoder() : any)))
    .assign("statusTitle", orNull(field("statusTitle", string)))
    .assign("statusCode", orNull(field("statusCode", number)))
    .andThen(toObject(type));
