<template>
  <Modal ref="modal" @toggle="toggle" :label="title" width="400px" :persistent="true">
    <template v-slot:body>
      <FormErrors :errors="store.errors"></FormErrors>
      <v-form ref="form">
        <v-text-field v-model="model.name" :rules="[rules.required]" label="Имя пользователя"></v-text-field>
        <v-text-field v-model="model.email" :rules="[rules.required]" label="Email" type="email"></v-text-field>
        <v-text-field
          v-model="model.password"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :rules="[rules.required]"
          :type="showPassword ? 'text' : 'password'"
          label="Пароль"
          @click:append="showPassword = !showPassword"
        />
        <v-select
          label="Роли"
          v-model="model.rolesIds"
          multiple
          chips
          clearable
          item-value="id"
          :items="rolesStore.items"
          :rules="[rules.required]"
        ></v-select>
      </v-form>
    </template>
    <template v-slot:footer>
      <v-btn :disabled="store.loader.isLoading" variant="elevated" type="submit" color="primary" @click="save">
        Сохранить
      </v-btn>
      <v-btn variant="tonal" @click="close">Закрыть</v-btn>
    </template>
  </Modal>
</template>

<script lang="ts">
import { User } from "/src/entitites/User.ts";
import FormErrors from "/src/primitives/FormErrors.vue";
import Modal from "/src/primitives/Modal.vue";
import { LayoutStore } from "/src/store/LayoutStore.ts";
import { UserRolesStore } from "/src/store/UserRolesStore.ts";
import { UsersStore } from "/src/store/UsersStore.ts";
import { RULES } from "/src/utils/ValidationRules.ts";
import { Container } from "typedi";
import { Component, Emit, Ref, Vue } from "vue-facing-decorator";

@Component({
  components: { FormErrors, Modal },
  emits: ["change"],
})
export default class UserFormModal extends Vue {
  @Ref() readonly modal!: Modal;
  @Ref() readonly form!: types.AbstractForm;
  private layoutStore: LayoutStore = Container.get(LayoutStore);
  store = Container.get(UsersStore);
  rolesStore = Container.get(UserRolesStore);
  model = new User();
  rules = RULES;
  showPassword = false;

  get title() {
    return this.model.id
      ? `Редактирование пользователя #${this.model.id} ${this.model.name}`
      : "Добавление пользователя";
  }

  @Emit()
  public toggle(isVisible: boolean) {
    this.form && this.form.resetValidation();
    return isVisible;
  }

  public open(model: User) {
    this.model = model;
    this.rolesStore.loadItems({});
    this.modal.open();
  }

  public close() {
    this.modal.close();
  }

  public async save() {
    if (!(await this.form.validate()).valid) {
      this.layoutStore.error("Не все обязательные поля заполнены корректно", false);
      return;
    }
    if (!(await this.store.save(this.model))) {
      this.layoutStore.error("Ошибка при сохранении изменений");
      return;
    }
    this.layoutStore.success("Изменения сохранены");
    this.$emit("change");
    this.close();
  }
}
</script>

<style lang="scss" scoped></style>
