<template>
  <div class="notifications-container">
    <Notifier v-for="(notification, id) in notifications" :model="notification" :key="id" />
  </div>
</template>

<script lang="ts">
import { NotifierItem } from "/src/entitites/NotifierItem.ts";
import Notifier from "/src/modules/Layout/Notifier.vue";
import { LayoutStore } from "/src/store/LayoutStore.ts";
import { Container } from "typedi";
import { Component, Vue } from "vue-facing-decorator";

@Component({
  components: { Notifier },
})
export default class NotificationsContainer extends Vue {
  private layoutStore = Container.get(LayoutStore);
  notifications: NotifierItem[] = [];

  mounted() {
    this.layoutStore.addCallback(() => this.refresh());
  }

  refresh() {
    this.notifications = [];
    this.notifications.push(...this.layoutStore.notifications);
  }
}
</script>

<style scoped>
.notifications-container {
  position: fixed;
  top: 30px;
  right: 30px;
  display: flex;
  flex-direction: column;
  z-index: 1;
}
</style>
