import { Service } from "typedi";

@Service({ global: true })
export class HtmlImageLoader {
  public load(url: string): Promise<HTMLImageElement> {
    const image = new Image();
    const promise: Promise<HTMLImageElement> = new Promise((resolve, reject) => {
      image.onload = () => resolve(image);
      image.onerror = () => reject(image);
    });
    image.src = url;
    return promise;
  }
}
