<template>
  <v-card>
    <v-progress-linear :active="store.loader.isLoading" :indeterminate="true" :absolute="true" bottom />
    <v-card-title>Файл {{ item ? item.name : "" }}</v-card-title>
    <v-card-text v-if="item">
      <v-row justify="start">
        <v-col cols="auto">
          <v-btn prepend-icon="mdi-arrow-left" color="primary" :to="urlsProvider.files.list()">
            К общему списку файлов
          </v-btn>
        </v-col>
        <v-col cols="auto">
          <v-btn
            v-if="item.parentPackageId"
            prepend-icon="mdi-arrow-left"
            color="primary"
            :to="urlsProvider.packages.detail(item.parentPackageId)"
          >
            К пакету
          </v-btn>
        </v-col>
      </v-row>
      <v-table class="table-rounded">
        <tbody>
          <tr>
            <td class="text-left">id</td>
            <td class="text-left">{{ item.id }}</td>
          </tr>
          <tr>
            <td class="text-left">Загружен</td>
            <td class="text-left">{{ item.createdAt.toLocaleString() }}</td>
          </tr>
          <tr>
            <td class="text-left">Действия</td>
            <td class="text-left">
              <v-btn prepend-icon="mdi-play-speed" color="primary" @click="processAlgorithms">
                Запустить алгоритмы
              </v-btn>
            </td>
          </tr>
        </tbody>
      </v-table>
      <div>
        <v-container>
          <v-row no-gutters class="d-flex justify-start">
            <v-col class="ma-2">
              <v-sheet height="100%">
                <v-card>
                  <v-card-text>
                    <v-img
                      width="350px"
                      v-if="isImage(item.name)"
                      :src="urlsProvider.files.download(item.hash)"
                      :alt="item.name"
                      :class="{ loading: !imageLoaded }"
                      @load="imageLoaded = true"
                    />
                    <v-overlay
                      v-if="!imageLoaded"
                      :model-value="true"
                      contained
                      persistent
                      class="align-center justify-center"
                    >
                      <v-progress-circular indeterminate :size="70" :width="7" color="primary" />
                    </v-overlay>
                  </v-card-text>
                  <v-card-title>
                    <Typography size="18">Оригинал</Typography>
                  </v-card-title>
                  <v-card-subtitle> Файл {{ item.name }} </v-card-subtitle>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <TooltipBtn
                      btn-variant="text"
                      btn-class="text-blue"
                      icon="mdi-download"
                      :href="urlsProvider.files.download(item.hash)"
                      tooltip="Скачать файл"
                    />
                  </v-card-actions>
                </v-card>
              </v-sheet>
            </v-col>
            <v-col class="ma-2" v-for="(result, key) in item?.algorithmResults" :key="key">
              <v-sheet height="100%" v-if="imageLoaded" position="relative">
                <AlgorithmResultView
                  :result="result"
                  :file="item"
                  :isLoading="store.loader.isLoading"
                  @settings:updated="processAlgorithms"
                  @reload="reload"
                />
                <v-overlay
                  v-if="store.loader.isLoading"
                  :model-value="true"
                  contained
                  persistent
                  class="align-center justify-center"
                >
                  <v-progress-circular indeterminate :size="70" :width="7" color="primary" />
                </v-overlay>
              </v-sheet>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import { UploadedFile } from "/src/entitites/UploadedFile.ts";
import AlgorithmResultView from "/src/modules/Files/AlgorithmResults/AlgorithmResultView.vue";
import Typography from "/src/primitives/Typography.vue";
import { LayoutStore } from "/src/store/LayoutStore.ts";
import TooltipBtn from "/src/primitives/TooltipBtn.vue";
import { UrlsProvider } from "/src/services/UrlsProvider.ts";
import { FilesStore } from "/src/store/FilesStore.ts";
import { Container } from "typedi";
import { Component, Prop, Vue } from "vue-facing-decorator";

@Component({
  components: {
    AlgorithmResultView,
    Typography,
    TooltipBtn,
  },
})
export default class FileDetail extends Vue {
  @Prop() private id!: number;
  urlsProvider = Container.get(UrlsProvider);
  store = Container.get(FilesStore);
  imageExtensions = ["jpeg", "jpg", "bmp"];
  item: UploadedFile | null = null;
  layoutStore: LayoutStore = Container.get(LayoutStore);
  imageLoaded = false;

  protected async created() {
    await this.reload();
  }

  async reload() {
    const item = await this.store.loadItem(this.id);
    item && (this.item = item);
  }

  isImage(fileName: string) {
    const extension = fileName.split(".").pop();
    return extension && this.imageExtensions.includes(extension);
  }

  async processAlgorithms() {
    const result = await this.store.processItem(this.id);
    if (result.hasErrors) {
      result.errorsAsArray.forEach(error => this.layoutStore.error(error));
    } else {
      this.layoutStore.success("Алгоритмы выполнены");
    }
    await this.reload();
  }
}
</script>

<style lang="scss" scoped>
.v-img {
  box-shadow: 0 0 0 1px lightgray;
  &.loading {
    min-height: 350px;
  }
}
.v-col {
  max-width: 370px;
}
</style>
