import { API_ENDPOINTS, FRONT_ENDPOINTS } from "/src/endpoints";
import { UrlGenerator } from "/src/services/UrlGenerator.ts";
import { castRouteParams } from "/src/utils/routeParams.ts";
import { Container } from "typedi";
import FileDetail from "./FileDetail.vue";
import FilesList from "./FilesList.vue";

const urlGenerator = Container.get(UrlGenerator);

export const filesRoutes = [
  { path: FRONT_ENDPOINTS.FILES_LIST, component: FilesList },
  { path: FRONT_ENDPOINTS.FILES_DETAIL, component: FileDetail, props: castRouteParams },
];

export const filesUrls = {
  detail: (id: number) => urlGenerator.replaceParams(FRONT_ENDPOINTS.FILES_DETAIL, { id }),
  list: () => FRONT_ENDPOINTS.FILES_LIST,
  download: (hash: string) => urlGenerator.replaceParams(API_ENDPOINTS.FILES_DOWNLOAD, { hash }),
};
