<template>
  <Modal ref="modal" label="Мой профиль" width="400px" @toggle="toggle" :persistent="true">
    <template v-slot:body>
      <FormErrors :errors="store.errors" />
      <v-form ref="form">
        <v-text-field v-model="model.name" :rules="[rules.required]" label="Имя пользователя"></v-text-field>
        <v-text-field v-model="model.email" :rules="[rules.required]" label="Email" type="email"></v-text-field>
        <v-text-field
          v-model="model.password"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :rules="[rules.required]"
          :type="showPassword ? 'text' : 'password'"
          label="Пароль"
          @click:append="showPassword = !showPassword"
        />
      </v-form>
    </template>
    <template v-slot:footer>
      <v-btn :disabled="store.loader.isLoading" color="primary" type="submit" variant="elevated" @click="save">
        Сохранить
      </v-btn>
      <v-btn variant="tonal" @click="close">Закрыть</v-btn>
    </template>
  </Modal>
</template>

<script lang="ts">
import { User } from "/src/entitites/User.ts";
import FormErrors from "/src/primitives/FormErrors.vue";
import Modal from "/src/primitives/Modal.vue";
import { LayoutStore } from "/src/store/LayoutStore.ts";
import { OwnUserStore } from "/src/store/OwnUserStore.ts";
import { RULES } from "/src/utils/ValidationRules.ts";
import { Container } from "typedi";
import { Component, Emit, Ref, Vue } from "vue-facing-decorator";

@Component({
  components: { FormErrors, Modal },
  emits: ["change"],
})
export default class MyProfileFormModal extends Vue {
  @Ref() readonly modal!: Modal;
  @Ref() readonly form!: types.AbstractForm;
  store = Container.get(OwnUserStore);
  model = new User();
  rules = RULES;
  showPassword = false;
  private layoutStore: LayoutStore = Container.get(LayoutStore);

  @Emit()
  public toggle(isVisible: boolean) {
    this.form && this.form.resetValidation();
    return isVisible;
  }

  public open() {
    this.modal.open();
    this.reload();
  }

  public close() {
    this.modal.close();
  }

  public async save() {
    if (!(await this.form.validate()).valid) {
      this.layoutStore.error("Не все обязательные поля заполнены корректно", false);
      return;
    }
    const result = await this.store.save(this.model);
    if (result.hasErrors) {
      result.errorsAsArray.forEach(error => this.layoutStore.error(error, false));
    } else {
      this.layoutStore.success("Изменения сохранены");
      this.close();
    }
  }

  private async reload() {
    const user = await this.store.load();
    if (user) {
      this.model.id = user.id;
      this.model.name = user.name;
      this.model.email = user.email;
    }
  }
}
</script>

<style lang="scss" scoped></style>
