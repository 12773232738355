<template>
  <v-dialog
    v-model="visible"
    @click:outside="toggleModal"
    content-class="modal"
    :width="width"
    :scrollable="true"
    :persistent="isLoading || persistent"
  >
    <v-card>
      <v-toolbar :color="color" :title="label">
        <v-btn density="comfortable" icon="mdi-close" @click="close"></v-btn>
      </v-toolbar>

      <slot />

      <v-card-text v-if="!!$slots.body" ref="cardBody" :class="cardBodyClass">
        <slot name="body" />
      </v-card-text>

      <v-card-actions v-if="!!$slots.footer">
        <slot name="footer" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { Vue, Component, Prop } from "vue-facing-decorator";
import Typography from "./Typography.vue";

@Component({
  components: { Typography },
  emits: ["toggle"],
})
export default class Modal extends Vue {
  @Prop() label!: string;
  @Prop({ default: "primary" }) color!: string;
  @Prop({ default: "70%" }) width!: number | string;
  @Prop({ default: false }) isLoading!: boolean;
  @Prop({ default: false }) persistent!: boolean;
  @Prop({ default: "" }) cardBodyClass!: string;

  visible = false;

  toggleModal() {
    if (!this.persistent && (!this.visible || !this.isLoading)) {
      this.visible = !this.visible;
    }
    this.$emit("toggle", this.visible);
  }

  open() {
    this.visible = true;
    this.$emit("toggle", this.visible);
  }

  close() {
    if (!this.isLoading) {
      this.visible = false;
    }
    this.$emit("toggle", this.visible);
  }
}
</script>

<style scoped lang="scss">
:deep(.modal) {
  min-height: 300px;
}
</style>
