<template>
  <v-card>
    <v-card-title>Список пакетов файлов</v-card-title>
    <v-card-text>
      <v-btn class="mb-4" color="primary" @click="uploadModal.open()">Загрузить</v-btn>
      <v-data-table-server
        :items-per-page="webQueryStore.perPage"
        :items-per-page-options="webQueryStore.itemsPerPageOptions"
        :headers="headers"
        :items-length="totalItems"
        :items="items"
        :loading="isLoading"
        :sort-by="webQueryStore.sortBy"
        v-bind:page="webQueryStore.currentPage"
        class="elevation-1"
        items-per-page-text="Элементов на странице"
        no-data-text="Пакеты в хранилище отсутствуют"
        @update:options="updateOptions"
      >
        <template v-slot:[`item.file`]="{ item }">
          <TooltipBtn
            btn-variant="text"
            btn-class="text-blue"
            icon="mdi-package-variant"
            :to="urlsProvider.packages.detail(item.columns.file.id)"
            tooltip="К деталям пакета"
          />
          <span> {{ item.columns.file.file.name }} </span>
        </template>
        <template v-slot:[`item.createdAt`]="{ item }">
          {{ item.columns.createdAt.toLocaleString() }}
        </template>
        <template v-slot:[`item.nestedFiles`]="{ item }">
          <div v-for="nestedFile in item.columns.nestedFiles" :key="nestedFile.hash">
            <TooltipBtn
              btn-variant="text"
              btn-class="text-blue"
              icon="mdi-magnify"
              :to="urlsProvider.files.detail(nestedFile.id)"
              tooltip="К деталям файла"
            />
            <TooltipBadge
              v-if="nestedFile.algorithmResultsCount"
              :badge-content="nestedFile.algorithmResultsCount"
              :tooltip="`Есть результаты выполнения алгоритмов`"
            >
              <template v-slot:content>
                {{ nestedFile.name }}
              </template>
            </TooltipBadge>
            <span v-else>{{ nestedFile.name }}</span>
          </div>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <DropdownMenu :actions="actions" @action="(action: any) => handleAction(action, item.columns.actions)" />
        </template>
      </v-data-table-server>
    </v-card-text>
    <UploadModal ref="uploadModal" @uploaded="reload" />
  </v-card>
</template>

<script lang="ts">
import { DropdownAction } from "/src/entitites/Dropdown.ts";
import { FilePackage } from "/src/entitites/FilePackage.ts";
import DropdownMenu from "/src/primitives/DropdownMenu.vue";
import TooltipBadge from "/src/primitives/TooltipBadge.vue";
import TooltipBtn from "/src/primitives/TooltipBtn.vue";
import { UrlsProvider } from "/src/services/UrlsProvider.ts";
import { LayoutStore } from "/src/store/LayoutStore.ts";
import { PackagesStore } from "/src/store/PackagesStore.ts";
import { WebQueryStore } from "/src/store/WebQueryStore.ts";
import UploadModal from "./UploadModal.vue";
import Typography from "/src/primitives/Typography.vue";
import { Container } from "typedi";
import { Component, Ref, Vue } from "vue-facing-decorator";

@Component({
  components: { TooltipBadge, TooltipBtn, DropdownMenu, Typography, UploadModal },
})
export default class PackagesList extends Vue {
  @Ref() readonly uploadModal!: UploadModal;
  private packagesStore = Container.get(PackagesStore);
  private layoutStore: LayoutStore = Container.get(LayoutStore);
  private tableKey = "PackagesList";
  totalItems = 0;
  rawItems: FilePackage[] = [];
  webQueryStore = Container.get(WebQueryStore);
  urlsProvider = Container.get(UrlsProvider);
  actions: DropdownAction[] = [
    {
      title: "Детали",
      code: "details",
      icon: "mdi-package-variant",
    },
    {
      title: "Удалить",
      code: "delete",
      icon: "mdi-trash-can-outline",
      confirm: {
        title: "Подтверждение удаления",
        message: "Вы действительно хотите удалить запись?",
        btnApprove: "Удалить",
      },
    },
  ];
  headers: any[] = [
    {
      title: "id",
      sortable: true,
      key: "id",
    },
    { title: "Файл", key: "file", sortable: false },
    { title: "Дата загрузки", key: "createdAt", sortable: false },
    { title: "Вложения", key: "nestedFiles", sortable: false },
    { title: "Действия", key: "actions", sortable: false },
  ];

  protected async created() {
    await this.webQueryStore.load(this.tableKey);
    await this.reload();
  }

  public get items() {
    return this.rawItems.map(item => {
      return {
        id: item.id,
        file: item,
        createdAt: item.createdAt,
        nestedFiles: item.nestedFiles,
        actions: item,
      };
    });
  }

  public get isLoading() {
    return this.packagesStore.loader.isLoading;
  }

  public async reload() {
    const result = await this.packagesStore.loadItems({
      tableKey: this.tableKey,
    });
    this.totalItems = result.data.totalItems;
    this.rawItems = result.data.items;
  }

  async delete(id: number) {
    await this.packagesStore.delete(id);
    this.layoutStore.success("Запись удалена");
    await this.reload();
  }

  async handleAction(action: DropdownAction, item: FilePackage) {
    switch (action.code) {
      case "delete":
        return this.delete(item.id);
      case "details":
        return await this.$router.push(this.urlsProvider.packages.detail(item.id));
    }
  }

  async updateOptions(options: any) {
    if (await this.webQueryStore.updateOptions(options)) {
      await this.reload();
    }
  }
}
</script>

<style lang="scss" scoped></style>
