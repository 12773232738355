import { FilePackage } from "/src/entitites/FilePackage.ts";
import { Loader } from "/src/entitites/Loader";
import { PackagesProvider } from "/src/services/PackagesProvider.ts";
import { Container, Service } from "typedi";
import { action, observable } from "mobx";

@Service({ global: true })
export class PackagesStore {
  @observable public loader = new Loader();
  @observable public errors: string[] = [];
  private provider = Container.get(PackagesProvider);

  @action.bound
  public async loadItems(params: any) {
    this.loader.start();
    const result = await this.provider.getItems(params);
    this.loader.stop();
    return result;
  }

  public async loadItem(id: number): Promise<FilePackage | null> {
    this.loader.start();
    const result = await this.provider.getItem(id);
    this.loader.stop();
    return result.data;
  }

  @action.bound
  public async upload(files: File[]) {
    this.loader.start("Загрузка файла");
    this.clear();
    try {
      const response = await this.provider.upload(files);
      response?.hasErrors && (this.errors = response.errorsAsArray);
      this.loader.stop();
      return response.success;
    } catch (e) {
      console.error(e);
      this.errors.push("Произошла ошибка при загрузке файла");
      this.loader.stop();
      return false;
    }
  }

  @action.bound
  public async delete(id: number) {
    this.clear();
    this.loader.start();
    const response = await this.provider.delete(id);
    response?.hasErrors && (this.errors = response.errorsAsArray);
    this.loader.stop();
    return response.success;
  }

  @action.bound
  public clear() {
    this.errors = [];
  }
}
