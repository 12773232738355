<template>
  <div>
    <v-file-input
      v-model="files"
      :multiple="true"
      :accept="accept"
      :loading="isLoading"
      @change="() => change()"
      @click:clear="clear"
      :chips="true"
      :counter="true"
      :show-size="true"
    >
      <template v-slot:label>
        <div class="label-default" @click.prevent>
          <Typography :size="16">
            Перетащите файлы сюда или выберите файлы вручную
            <Typography :size="10"> (общий размер не должен превышать 2Гб)</Typography>
          </Typography>
        </div>
      </template>
    </v-file-input>
    <FullScreenFileDrop v-if="!hideFullDrop" @on-drop="onDrop" />
  </div>
</template>

<script lang="ts">
import FullScreenFileDrop from "/src/primitives/Form/FullScreenFileDrop.vue";
import { PackagesStore } from "/src/store/PackagesStore.ts";
import { is } from "ramda";
import { Component, Emit, Prop, Vue } from "vue-facing-decorator";
import Typography from "/src/primitives/Typography.vue";

@Component({
  components: { Typography, FullScreenFileDrop },
})
export default class InputFile extends Vue {
  @Prop() accept!: string;
  @Prop({ default: false }) hideFullDrop!: boolean;
  @Prop({ required: true }) fileStore!: PackagesStore;
  public files: File[] = [];

  @Emit("change")
  public clear() {
    !is(Array, this.files) && (this.files = []);
    this.files.splice(0);
    return this.files;
  }

  public get isLoading(): boolean {
    return this.fileStore.loader.isLoading;
  }

  @Emit()
  public change(file: File | File[] | null = null): File[] {
    if (file && !is(Array, file)) {
      this.clear();
      this.files.push(file);
    }
    return this.files;
  }

  public onDrop(filesList: FileList | null) {
    if (filesList) {
      this.clear();
      const fileList = Array.from(filesList);
      this.files.push(...fileList);
      this.change();
    }
  }
}
</script>

<style scoped lang="scss"></style>
