import { FileTypeHandler, FileTypeSettings } from "/src/entitites/FileType.ts";
import { Loader } from "/src/entitites/Loader";
import { FileTypesProvider } from "/src/services/FileTypesProvider.ts";
import { Container, Service } from "typedi";
import { action, observable } from "mobx";

@Service({ global: true })
export class FileTypesStore {
  @observable public types: FileTypeSettings[] = [];
  @observable public handlers: FileTypeHandler[] = [];
  @observable public loader = new Loader();
  @observable public errors: string[] = [];
  private provider = Container.get(FileTypesProvider);

  @action.bound
  public async loadItems() {
    this.loader.start();
    const typesResult = await this.provider.getTypes();
    const handlersResult = await this.provider.getHandlers();
    this.types = typesResult.data.items;
    this.handlers = handlersResult.data.items;
    this.loader.stop();
  }

  @action.bound
  public async save(model: FileTypeSettings) {
    this.clear();
    this.loader.start();
    const response = await this.provider.save(model);
    response?.hasErrors && (this.errors = response.errorsAsArray);
    this.loader.stop();
    return response.success;
  }

  @action.bound
  public async delete(id: number) {
    this.clear();
    this.loader.start();
    const response = await this.provider.delete(id);
    response?.hasErrors && (this.errors = response.errorsAsArray);
    this.loader.stop();
    return response.success;
  }

  @action.bound
  public clear() {
    this.errors = [];
  }
}
