<template>
  <v-card>
    <v-card-title>Список результатов</v-card-title>
    <v-card-text>
      <v-data-table-server
        :items-per-page="webQueryStore.perPage"
        :items-per-page-options="webQueryStore.itemsPerPageOptions"
        :headers="headers"
        :items-length="totalItems"
        :items="items"
        :loading="store.loader.isLoading"
        :sort-by="webQueryStore.sortBy"
        v-bind:page="webQueryStore.currentPage"
        class="elevation-1"
        items-per-page-text="Элементов на странице"
        no-data-text="Файлы в хранилище отсутствуют"
        @update:options="updateOptions"
      >
        <template v-slot:[`item.file`]="{ item }">
          <span> {{ item.columns.file.name }} </span>
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <v-chip :color="getStatus(item.raw.item.statusCode).color" class="ma-2">
            <v-icon start :icon="getStatus(item.raw.item.statusCode).icon" />
            {{ item.columns.status }}
          </v-chip>
        </template>
        <template v-slot:[`item.createdAt`]="{ item }">
          {{ item.columns.createdAt }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            v-if="canSetInWork(item.columns.id)"
            prepend-icon="mdi-play-speed"
            color="primary"
            @click="setInWork(item.columns.id)"
          >
            Взять в работу
          </v-btn>
          <v-btn
            v-else
            prepend-icon="mdi-magnify"
            color="primary"
            :to="urlsProvider.defectsComparison.detail(item.columns.id)"
          >
            К деталям
          </v-btn>
        </template>
      </v-data-table-server>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import { ManualAlgorithmResult } from "/src/entitites/ManualAlgorithmResult.ts";
import { AlgorithmStatusEnum } from "/src/enums/AlgorithmStatusEnum.ts";
import Modal from "/src/primitives/Modal.vue";
import TooltipBadge from "/src/primitives/TooltipBadge.vue";
import TooltipBtn from "/src/primitives/TooltipBtn.vue";
import Typography from "/src/primitives/Typography.vue";
import { UrlsProvider } from "/src/services/UrlsProvider.ts";
import { LayoutStore } from "/src/store/LayoutStore.ts";
import { ManualAlgorithmResultsStore } from "/src/store/ManualAlgorithmResultsStore.ts";
import { WebQueryStore } from "/src/store/WebQueryStore.ts";
import { Container } from "typedi";
import { Component, Vue } from "vue-facing-decorator";

@Component({
  components: { Typography, Modal, TooltipBtn, TooltipBadge },
})
export default class DefectsComparisonList extends Vue {
  headers: any[] = [
    { title: "id", sortable: true, key: "id" },
    { title: "Файл", key: "file", sortable: false },
    { title: "Статус", key: "status", sortable: true },
    { title: "Дата загрузки", key: "createdAt", sortable: false },
    { title: "Действия", key: "actions", sortable: false },
  ];
  totalItems = 0;
  rawItems: ManualAlgorithmResult[] = [];
  webQueryStore = Container.get(WebQueryStore);
  store = Container.get(ManualAlgorithmResultsStore);
  urlsProvider = Container.get(UrlsProvider);
  private layoutStore: LayoutStore = Container.get(LayoutStore);
  private tableKey = "DefectsComparisonList";

  protected async created() {
    await this.webQueryStore.load(this.tableKey);
    await this.reload();
  }

  public get items() {
    return this.rawItems.map(item => {
      return {
        id: item.id,
        file: { id: item.file?.id, name: item.file?.name },
        status: item.statusTitle,
        createdAt: item.file?.createdAt?.toLocaleString(),
        item: item,
      };
    });
  }

  getStatus(statusCode: any) {
    switch (statusCode) {
      case AlgorithmStatusEnum.FINISHED:
        return { color: "green", icon: "mdi-progress-check" };
      case AlgorithmStatusEnum.IN_PROGRESS:
        return { color: "amber", icon: "mdi-progress-pencil" };
      default:
        return { color: "indigo", icon: "mdi-progress-clock" };
    }
  }

  async updateOptions(options: any) {
    if (await this.webQueryStore.updateOptions(options)) {
      await this.reload();
    }
  }

  public async reload() {
    const result = await this.store.loadItems({
      tableKey: this.tableKey,
    });
    this.totalItems = result.data.totalItems;
    this.rawItems = result.data.items;
  }

  public canSetInWork(id: number) {
    const item = this.rawItems.find(value => value.id === id);
    return item && [AlgorithmStatusEnum.NEW].includes(item.statusCode);
  }

  public async setInWork(id: number) {
    const result = await this.store.setInWork(id);
    if (result.hasErrors) {
      return result.errorsAsArray.forEach(error => this.layoutStore.error(error));
    }
    await this.reload();
    this.layoutStore.info("Можно приступить к подтверждению дефектов");
    this.$router.push(this.urlsProvider.defectsComparison.detail(id));
  }
}
</script>

<style lang="scss" scoped></style>
