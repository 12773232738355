import { UploadedFile } from "/src/entitites/UploadedFile.ts";
import { algorithmResultDecoder } from "/src/services/Decoders/AlgorithmDecoders.ts";
import { packageDecoder } from "/src/services/Decoders/PackagesDecoders.ts";
import Decoder, { array, date, field, number, string, succeed } from "jsonous";
import { any, orNull, toObject } from "./CommonDecoders";

export const fileDecoder = (decodePackages = true): Decoder<UploadedFile> =>
  succeed(UploadedFile)
    .assign("id", field("id", number))
    .assign("name", field("name", string))
    .assign("hash", field("hash", string))
    .assign("createdAt", orNull(field("createdAt", date)))
    .assign("updatedAt", orNull(field("updatedAt", date)))
    .assign("packageId", orNull(field("packageId", number)))
    .assign("package", orNull(field("package", decodePackages ? packageDecoder(false) : any)))
    .assign("parentPackageId", orNull(field("parentPackageId", number)))
    .assign("parentPackage", orNull(field("parentPackage", decodePackages ? packageDecoder(false) : any)))
    .assign("algorithmResults", orNull(field("algorithmResults", array(algorithmResultDecoder())), []))
    .assign("algorithmResultsCount", orNull(field("algorithmResultsCount", number), 0))
    .andThen(toObject(UploadedFile));
