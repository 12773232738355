<template>
  <v-form @submit.prevent="submit" ref="form">
    <v-text-field v-model="name" :rules="[rules.required]" label="Логин" placeholder="Имя пользователя" />
    <v-text-field
      v-model="password"
      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
      :type="showPassword ? 'text' : 'password'"
      @click:append="showPassword = !showPassword"
      :rules="[rules.required]"
      label="Пароль"
      placeholder="Пароль"
    />
    <div class="d-flex justify-space-between">
      <v-checkbox v-model="remember" label="Запомнить меня" :ripple="false" />
    </div>

    <v-btn
      type="submit"
      color="primary"
      class="submit-btn font-weight-regular"
      :loading="authStore.loader.isLoading"
      :disabled="authStore.loader.isLoading"
    >
      Вход
    </v-btn>
  </v-form>
</template>

<script lang="ts">
import { AuthStore } from "/src/store/AuthStore.ts";
import { LayoutStore } from "/src/store/LayoutStore.ts";
import { RULES } from "/src/utils/ValidationRules.ts";
import { Container } from "typedi";
import { Component, Ref, Vue, Watch } from "vue-facing-decorator";

@Component({
  components: {},
})
export default class LoginForm extends Vue {
  @Ref() readonly form!: types.AbstractForm;
  authStore = Container.get(AuthStore);
  private layoutStore = Container.get(LayoutStore);
  name = "";
  password = "";
  remember = true;
  showPassword = false;
  rules = RULES;

  async submit() {
    if (!(await this.form.validate()).valid) {
      this.layoutStore.error("Не все обязательные поля заполнены корректно", false);
      return;
    }
    await this.authStore.login(this.name, this.password, this.remember);
    this.authStore.errors.forEach(error => this.layoutStore.error(error, false));
  }

  @Watch("name")
  @Watch("password")
  clearError() {
    this.authStore.errors = [];
  }
}
</script>

<style lang="scss" scoped>
.v-img {
  box-shadow: 0 0 0 1px lightgray;
  &.loading {
    min-height: 350px;
  }
}
.v-col {
  max-width: 370px;
}
</style>
