<template>
  <v-alert variant="flat" :type="model.type" :text="model.text" closable v-model="model.visible" class="ma-2" />
</template>

<script lang="ts">
import { NotifierItem } from "/src/entitites/NotifierItem.ts";
import { Component, Prop, Vue } from "vue-facing-decorator";

@Component({
  components: {},
})
export default class Notifier extends Vue {
  @Prop() model!: NotifierItem;
}
</script>

<style scoped>
.v-alert {
  z-index: 2401;
}
</style>
