import { MenuItem } from "/src/entitites/MenuItem.ts";
import { toObject } from "/src/services/Decoders/CommonDecoders.ts";
import Decoder, { array, field, string, succeed } from "jsonous";

export const menuDecoder = (decodeSubItems = true): Decoder<MenuItem> => {
  let decoder: Decoder<MenuItem & any> = succeed(MenuItem).assign("code", field("code", string));
  if (decodeSubItems) {
    decoder = decoder.assign("subItems", field("subItems", array(menuDecoder(false))));
  }
  return decoder.andThen(toObject(MenuItem));
};
