import { API_ENDPOINTS } from "/src/endpoints";
import { BaseResponse } from "/src/entitites/ResponseEntity.ts";
import { TableData } from "/src/entitites/Table.ts";
import { UserPermission, UserRole } from "/src/entitites/User.ts";
import { baseResponse } from "/src/services/Decoders/CommonDecoders.ts";
import { tableDecoder } from "/src/services/Decoders/TableDecoders.ts";
import { userPermissionDecoder, userRoleDecoder } from "/src/services/Decoders/UsersDecoders.ts";
import { createRequestOptions } from "/src/services/RequestSubmitter/RequestOptions.ts";
import { Service } from "typedi";
import { METHODS } from "./RequestSubmitter/Methods";
import { RequestSubmitter } from "./RequestSubmitter/RequestSubmitter";

@Service({ global: true })
export class UserRolesProvider extends RequestSubmitter {
  private listRequest = this.createRequest(
    API_ENDPOINTS.SETTINGS_USER_ROLES_LIST,
    METHODS.GET,
    tableDecoder(userRoleDecoder),
  );
  private saveRequest = this.createRequest(API_ENDPOINTS.SETTINGS_USER_ROLES_FORM, METHODS.POST, baseResponse());
  private deleteRequest = this.createRequest(API_ENDPOINTS.SETTINGS_USER_ROLES_DELETE, METHODS.POST, baseResponse());
  private permissionsListRequest = this.createRequest(
    API_ENDPOINTS.SETTINGS_USER_PERMISSIONS_LIST,
    METHODS.GET,
    tableDecoder(userPermissionDecoder),
  );

  public async getItems(params: any): Promise<TableData<UserRole>> {
    return await this.listRequest(createRequestOptions().setBody(params));
  }

  public async getPermissions(): Promise<TableData<UserPermission>> {
    return await this.permissionsListRequest();
  }

  public async save(model: UserRole): Promise<BaseResponse> {
    return await this.saveRequest(createRequestOptions().setBody({ data: model }));
  }

  public async delete(id: number): Promise<BaseResponse> {
    return await this.deleteRequest(createRequestOptions().addUrlParam("id", id));
  }
}
