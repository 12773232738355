<template>
  <div class="actions">
    <v-row class="d-flex justify-center ma-0">
      <v-col cols="auto" class="pa-0">
        <TooltipBtn btn-variant="plain" icon="mdi-plus" tooltip="Увеличить" @click="() => zoomStore.zoomIn()" />
        <TooltipBtn btn-variant="plain" icon="mdi-minus" tooltip="Уменьшить" @click="() => zoomStore.zoomOut()" />
        <TooltipBtn btn-variant="plain" icon="mdi-backup-restore" tooltip="Сбросить" @click="() => zoomStore.reset()" />
      </v-col>
      <v-col v-if="manualButtons" cols="auto">
        <v-divider vertical color="black"></v-divider>
      </v-col>
      <v-col v-if="manualButtons" cols="auto" class="pa-0">
        <slot name="manual-actions" />
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import TooltipBtn from "/src/primitives/TooltipBtn.vue";
import { CanvasZoomStore } from "/src/store/CanvasZoomStore.ts";
import { Component, Prop, Vue } from "vue-facing-decorator";

@Component({
  components: { TooltipBtn },
})
export default class CanvasActions extends Vue {
  @Prop() zoomStore!: CanvasZoomStore;
  @Prop({ default: false }) manualButtons!: boolean;
}
</script>

<style lang="scss" scoped>
.actions {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  background-color: #d3d3d378;
  .v-col {
    display: flex;
    align-items: center;
  }
  .v-select {
    min-width: 160px;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .v-divider {
    height: 100%;
  }
}
</style>
