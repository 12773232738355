<template>
  <div class="full-screen-file-drop" :class="{ 'full-screen-file-drop--visible': visible }">
    <div class="full-screen-file-drop__content">{{ text }}</div>
  </div>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from "vue-facing-decorator";

@Component({
  components: {},
})
export default class FullScreenFileDrop extends Vue {
  @Prop({ default: "upload" }) formFieldName!: string;
  @Prop({ default: "Загрузить файлы" }) text!: string;
  visible = false;
  private lastTarget: EventTarget | null = null;

  private onDragEnter(e: Event) {
    this.lastTarget = e.target;
    this.visible = true;
  }
  private onDragLeave(e: Event) {
    if (e.target === this.lastTarget) {
      this.visible = false;
    }
  }
  private onDragOver(e: Event) {
    e.preventDefault();
  }

  @Emit("on-drop")
  private onDrop(e: DragEvent) {
    e.preventDefault();
    this.visible = false;
    return e.dataTransfer?.files;
  }
  protected created() {
    document.addEventListener("dragenter", this.onDragEnter);
    document.addEventListener("dragleave", this.onDragLeave);
    document.addEventListener("dragover", this.onDragOver);
    document.addEventListener("drop", this.onDrop);
  }
  protected beforeDestroy() {
    document.removeEventListener("dragenter", this.onDragEnter);
    document.removeEventListener("dragleave", this.onDragLeave);
    document.removeEventListener("dragover", this.onDragOver);
    document.removeEventListener("drop", this.onDrop);
  }
}
</script>

<style lang="scss">
.full-screen-file-drop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  visibility: hidden;
  opacity: 0;
  transition: visibility 200ms, opacity 200ms;
}

.full-screen-file-drop--visible {
  opacity: 1;
  visibility: visible;
}

.full-screen-file-drop__content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: #fff;
  font-size: 4em;
}

.full-screen-file-drop__content:before {
  border: 5px dashed #fff;
  content: "";
  bottom: 60px;
  left: 60px;
  position: absolute;
  right: 60px;
  top: 60px;
}
</style>
