import { FRONT_ENDPOINTS } from "/src/endpoints";

export type MenuParam = {
  url?: string;
  icon?: string;
  title: string;
};

export const MENU_PARAMS: { [propertyName: string]: MenuParam } = {
  files: { title: "Работа с файлами" },
  filesList: { url: FRONT_ENDPOINTS.FILES_LIST, icon: "mdi-list-box-outline", title: "Файлы в хранилище" },
  packagesList: { url: FRONT_ENDPOINTS.PACKAGES_LIST, icon: "mdi-file-multiple-outline", title: "Пакеты файлов" },
  defectsComparison: {
    url: FRONT_ENDPOINTS.MANUAL_RESULTS_LIST,
    icon: "mdi-list-status",
    title: "Сопоставление дефектов",
  },
  settings: { title: "Настройки" },
  settingsFileTypes: {
    url: FRONT_ENDPOINTS.SETTINGS_FILE_TYPES,
    icon: "mdi-file-upload",
    title: "Типы файлов",
  },
  settingsDefectTypes: {
    url: FRONT_ENDPOINTS.SETTINGS_DEFECT_TYPES,
    icon: "mdi-cloud-search-outline",
    title: "Типы дефектов",
  },
  settingsAlgorithms: {
    url: FRONT_ENDPOINTS.SETTINGS_ALGORITHMS,
    icon: "mdi-code-braces-box",
    title: "Алгоритмы",
  },
  settingsUsers: {
    url: FRONT_ENDPOINTS.SETTINGS_USERS_LIST,
    icon: "mdi-account-box-multiple-outline",
    title: "Пользователи",
  },
  settingsUserRoles: {
    url: FRONT_ENDPOINTS.SETTINGS_USER_ROLES_LIST,
    icon: "mdi-account-check-outline",
    title: "Роли пользователей",
  },
};
