import { CanvasData } from "/src/entitites/CanvasData.ts";
import { PointsContour } from "/src/entitites/PointsContour.ts";
import { CanvasDrawer } from "/src/services/CanvasDrawer.ts";
import { CanvasSizesProvider } from "/src/services/CanvasSizesProvider.ts";
import { HtmlImageLoader } from "/src/services/HtmlImageLoader.ts";
import { CanvasZoomStore } from "/src/store/CanvasZoomStore.ts";
import { observable } from "mobx";
import { Service, Container } from "typedi";

export type SetupOptions = {
  zoomStore: CanvasZoomStore;
  imageUrl: string;
};

@Service({ transient: true })
export class CanvasStore {
  @observable public ratio = 1;
  private zoomStore!: CanvasZoomStore;
  private htmlImageLoader = Container.get(HtmlImageLoader);
  private canvasSizesProvider = Container.get(CanvasSizesProvider);
  private canvasDrawer = Container.get(CanvasDrawer);
  private image: HTMLImageElement | null = null;
  private contours: PointsContour[] = [];

  public async setup(options: SetupOptions) {
    this.zoomStore = options.zoomStore;
    this.image = await this.htmlImageLoader.load(options.imageUrl);
  }

  public addContours(contours: PointsContour[]) {
    this.contours.push(...contours);
  }

  public clearContours() {
    this.contours = [];
  }

  public async updateCanvas(canvasData: CanvasData) {
    const canvas = canvasData.canvas;
    const ctx = canvas?.getContext("2d");
    if (!canvas || !ctx || !this.image) {
      return;
    }

    const sizesDto = { canvasData, image: this.image, zoomStore: this.zoomStore };
    const sizes = this.canvasSizesProvider.getSizes(sizesDto);
    canvas.width = sizes.width;
    canvas.height = sizes.height;
    ctx.drawImage(this.image, 0, 0, sizes.width, sizes.height);
    this.ratio = sizes.ratio;

    this.contours.forEach(contour => {
      this.canvasDrawer.drawContour(ctx, sizes.ratio, contour.points, contour.style);
    });
  }
}
