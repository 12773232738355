import { Service } from "typedi";

@Service({ global: true })
export class UrlGenerator {
  replaceParams(url: string, params: { [propertyName: string]: string | number }) {
    for (const key in params) {
      url = url.replace(`:${key}`, params[key] as string);
    }
    return url;
  }
}
