import { FRONT_ENDPOINTS } from "/src/endpoints";
import DefectsComparisonDetail from "/src/modules/DefectsComparison/DefectsComparisonDetail.vue";
import DefectsComparisonList from "/src/modules/DefectsComparison/DefectsComparisonList.vue";
import { UrlGenerator } from "/src/services/UrlGenerator.ts";
import { castRouteParams } from "/src/utils/routeParams.ts";
import { Container } from "typedi";

const urlGenerator = Container.get(UrlGenerator);

export const defectsComparisonRoutes = [
  { path: FRONT_ENDPOINTS.MANUAL_RESULTS_LIST, component: DefectsComparisonList },
  { path: FRONT_ENDPOINTS.MANUAL_RESULTS_DETAIL, component: DefectsComparisonDetail, props: castRouteParams },
];

export const defectsComparisonUrls = {
  detail: (id: number) => urlGenerator.replaceParams(FRONT_ENDPOINTS.MANUAL_RESULTS_DETAIL, { id }),
  list: () => FRONT_ENDPOINTS.MANUAL_RESULTS_LIST,
};
