<template>
  <v-card class="mb-2 two-cols-card">
    <v-card-title>
      <Typography :size="16"> Коэффициент площади фактического дефекта к площади эллипса </Typography>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col>
          <v-text-field label="Больше" type="number" v-model="data.ellipseAreaRatioMore" hide-details />
        </v-col>
        <v-col>
          <v-text-field
            label="Больше или равно"
            type="number"
            v-model="data.ellipseAreaRatioMoreOrEqual"
            hide-details
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            label="Меньше или равно"
            type="number"
            v-model="data.ellipseAreaRatioLessOrEqual"
            hide-details
          />
        </v-col>
        <v-col>
          <v-text-field label="Меньше" type="number" v-model="data.ellipseAreaRatioLess" hide-details />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import type { EllipseAreaRatioParams } from "/src/entitites/AlgorithmSettings/EllipseAreaRatioParams.ts";
import Typography from "/src/primitives/Typography.vue";
import { Component, Prop, Vue } from "vue-facing-decorator";

@Component({
  components: { Typography },
})
export default class EllipseAreaRatioFields extends Vue {
  @Prop() readonly data!: EllipseAreaRatioParams;
}
</script>

<style lang="scss" scoped>
.two-cols-card {
  .v-input.v-text-field:not(.v-select) {
    max-width: 200px;
  }
}
</style>
