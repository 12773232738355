export class User {
  public id!: number;
  public name!: string;
  public email!: string;
  public password = "";
  public rolesIds!: number[];
}

export class UserRole {
  public id!: number;
  public name!: string;
  public title!: string;
  public permissionIds!: string[];
}

export class UserPermission {
  public id!: string;
  public title!: string;
}
