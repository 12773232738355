import { User, UserPermission, UserRole } from "/src/entitites/User.ts";
import Decoder, { array, field, number, string, succeed } from "jsonous";
import { toObject } from "./CommonDecoders";

export const userDecoder = (): Decoder<User> =>
  succeed(User)
    .assign("id", field("id", number))
    .assign("name", field("name", string))
    .assign("email", field("email", string))
    .assign("rolesIds", field("rolesIds", array(number)))
    .andThen(toObject(User));

export const userRoleDecoder = (): Decoder<UserRole> =>
  succeed(UserRole)
    .assign("id", field("id", number))
    .assign("name", field("name", string))
    .assign("title", field("title", string))
    .assign("permissionIds", field("permissionIds", array(string)))
    .andThen(toObject(UserRole));

export const userPermissionDecoder = (): Decoder<UserPermission> =>
  succeed(UserPermission)
    .assign("id", field("id", string))
    .assign("title", field("title", string))
    .andThen(toObject(UserPermission));
