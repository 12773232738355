<template>
  <Modal ref="modal" @toggle="toggle" :label="title" width="900px">
    <template v-slot:body>
      <FormErrors :errors="store.errors" />
      <v-form ref="form">
        <v-select
          v-model="model.code"
          label="Алгоритм"
          :items="store.types"
          :rules="[rules.required]"
          item-title="algorithmTitle"
          item-value="code"
          @update:model-value="updateModel"
        />
        <v-text-field label="Название настроек" v-model="model.title" :rules="[rules.required]"></v-text-field>
        <AlgorithmSettingsFields :code="model.code" :settings="model" />
      </v-form>
    </template>
    <template v-slot:footer>
      <v-btn :disabled="store.loader.isLoading" variant="elevated" type="submit" color="primary" @click="save">
        Сохранить
      </v-btn>
      <v-btn variant="tonal" @click="close">Закрыть</v-btn>
    </template>
  </Modal>
</template>

<script lang="ts">
import { AlgorithmSettings } from "/src/entitites/AlgorithmSettings.ts";
import AlgorithmSettingsFields from "/src/modules/Settings/Algorithms/Fields/AlgorithmSettingsFields.vue";
import FormErrors from "/src/primitives/FormErrors.vue";
import { AlgorithmSettingsStore } from "/src/store/AlgorithmSettingsStore.ts";
import Modal from "/src/primitives/Modal.vue";
import { LayoutStore } from "/src/store/LayoutStore.ts";
import { RULES } from "/src/utils/ValidationRules.ts";
import { Container } from "typedi";
import { Component, Emit, Ref, Vue } from "vue-facing-decorator";

@Component({
  components: { FormErrors, AlgorithmSettingsFields, Modal },
  emits: ["change"],
})
export default class AlgorithmSettingsFormModal extends Vue {
  @Ref() readonly modal!: Modal;
  @Ref() readonly form!: types.AbstractForm;
  private layoutStore: LayoutStore = Container.get(LayoutStore);
  store = Container.get(AlgorithmSettingsStore);
  model = new AlgorithmSettings();
  rules = RULES;

  get title() {
    const title = this.model.algorithmTitle || "";
    return this.model.id ? `Редактирование #${this.model.id} ${title}` : "Добавление настроек алгоритма";
  }

  updateModel(code: string) {
    const model = this.store.types.filter(item => item.code === code).pop();
    model && (this.model = Object.assign({}, model));
  }

  @Emit()
  public toggle(isVisible: boolean) {
    this.form && this.form.resetValidation();
    return isVisible;
  }

  public open(model: AlgorithmSettings) {
    this.model = model;
    this.modal.open();
    this.store.loadTypes();
  }

  public close() {
    this.modal.close();
  }

  public async save() {
    if (!(await this.form.validate()).valid) {
      this.layoutStore.error("Не все поля правильно заполнены");
      return;
    }
    const result = await this.store.save({ settings: this.model, individualSettings: null, algorithmResultId: null });
    if (result.hasErrors) {
      return result.errorsAsArray.forEach(error => this.layoutStore.error(error));
    }
    this.layoutStore.success("Изменения сохранены");
    this.$emit("change");
    this.close();
  }
}
</script>

<style lang="scss" scoped></style>
