<template>
  <v-card>
    <v-card-title>Список файлов</v-card-title>
    <v-card-text>
      <v-data-table-server
        :items-per-page="webQueryStore.perPage"
        :items-per-page-options="webQueryStore.itemsPerPageOptions"
        :headers="headers"
        :items-length="totalItems"
        :items="items"
        :loading="isLoading"
        :sort-by="webQueryStore.sortBy"
        v-bind:page="webQueryStore.currentPage"
        class="elevation-1"
        items-per-page-text="Элементов на странице"
        no-data-text="Файлы в хранилище отсутствуют"
        @update:options="updateOptions"
      >
        <template v-slot:[`item.file`]="{ item }">
          <TooltipBtn
            v-if="!item.columns.file.packageId"
            btn-variant="text"
            btn-class="text-blue"
            icon="mdi-magnify"
            :to="urlsProvider.files.detail(item.columns.file.id)"
            tooltip="К деталям файла"
          />
          <TooltipBtn
            v-if="item.columns.file.packageId"
            btn-variant="text"
            btn-class="text-blue"
            icon="mdi-package-variant"
            :to="urlsProvider.packages.detail(item.columns.file.packageId)"
            tooltip="К деталям пакета"
          />
          <TooltipBadge
            v-if="item.columns.file.algorithmResultsLength"
            :badge-content="item.columns.file.algorithmResultsLength"
            :tooltip="`Есть результаты выполнения алгоритмов`"
          >
            <template v-slot:content>
              {{ item.columns.file.name }}
            </template>
          </TooltipBadge>
          <span v-else> {{ item.columns.file.name }} </span>
        </template>
        <template v-slot:[`item.package`]="{ item }">
          <TooltipBtn
            v-if="item.columns.package.parentPackageId"
            btn-variant="text"
            btn-class="text-blue"
            icon="mdi-package-variant"
            :to="urlsProvider.packages.detail(item.columns.package.parentPackageId)"
            tooltip="К деталям пакета"
          />
          {{ item.columns.package.parentPackage.file ? item.columns.package.parentPackage.file.name : "" }}
        </template>
        <template v-slot:[`item.createdAt`]="{ item }">
          {{ item.columns.createdAt.toLocaleString() }}
        </template>
      </v-data-table-server>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import { UploadedFile } from "/src/entitites/UploadedFile.ts";
import Modal from "/src/primitives/Modal.vue";
import TooltipBadge from "/src/primitives/TooltipBadge.vue";
import TooltipBtn from "/src/primitives/TooltipBtn.vue";
import Typography from "/src/primitives/Typography.vue";
import { UrlsProvider } from "/src/services/UrlsProvider.ts";
import { FilesStore } from "/src/store/FilesStore.ts";
import { WebQueryStore } from "/src/store/WebQueryStore.ts";
import { Container } from "typedi";
import { Component, Vue } from "vue-facing-decorator";

@Component({
  components: { Typography, Modal, TooltipBtn, TooltipBadge },
})
export default class FilesList extends Vue {
  headers: any[] = [
    {
      title: "id",
      sortable: true,
      key: "id",
    },
    { title: "Файл", key: "file", sortable: false },
    { title: "Пакет", key: "package", sortable: false },
    { title: "Дата загрузки", key: "createdAt", sortable: false },
  ];
  totalItems = 0;
  rawItems: UploadedFile[] = [];
  urlsProvider = Container.get(UrlsProvider);
  webQueryStore = Container.get(WebQueryStore);
  protected filesStore = Container.get(FilesStore);
  private tableKey = "FilesList";

  protected async created() {
    await this.webQueryStore.load(this.tableKey);
    await this.reload();
  }

  public get items() {
    return this.rawItems.map(item => {
      return {
        id: item.id,
        file: {
          packageId: item.packageId,
          id: item.id,
          algorithmResultsLength: item.algorithmResultsCount,
          name: item.name,
        },
        package: {
          parentPackageId: item.parentPackageId,
          parentPackage: item.parentPackage || {},
        },
        createdAt: item.createdAt,
      };
    });
  }

  public get isLoading() {
    return this.filesStore.loader.isLoading;
  }

  async updateOptions(options: any) {
    if (await this.webQueryStore.updateOptions(options)) {
      await this.reload();
    }
  }

  public async reload() {
    const result = await this.filesStore.loadItems({
      tableKey: this.tableKey,
    });
    this.totalItems = result.data.totalItems;
    this.rawItems = result.data.items;
  }
}
</script>

<style lang="scss" scoped></style>
