<template>
  <Modal ref="modal" @toggle="toggle" label="Загрузка файлов" width="50%">
    <template v-slot:body>
      <FormErrors :errors="packagesStore.errors" />
      <v-form ref="form">
        <InputFile :file-store="packagesStore" @change="onUploadFiles" accept=".zip, .datx" />
      </v-form>
    </template>
    <template v-slot:footer>
      <v-btn
        :disabled="packagesStore.loader.isLoading"
        variant="elevated"
        type="submit"
        color="primary"
        @click="submit"
      >
        Отправить
      </v-btn>
      <v-btn variant="tonal" @click="close">Закрыть</v-btn>
    </template>
  </Modal>
</template>

<script lang="ts">
import InputFile from "/src/primitives/Form/InputFile.vue";
import FormErrors from "/src/primitives/FormErrors.vue";
import Modal from "/src/primitives/Modal.vue";
import { PackagesStore } from "/src/store/PackagesStore.ts";
import { Container } from "typedi";
import { Component, Emit, Ref, Vue } from "vue-facing-decorator";

@Component({
  components: { FormErrors, Modal, InputFile },
})
export default class UploadModal extends Vue {
  @Ref() readonly modal!: Modal;
  @Ref() readonly form!: types.AbstractForm;
  packagesStore = Container.get(PackagesStore);
  private files: File[] = [];

  @Emit()
  public toggle(isVisible: boolean) {
    this.form && this.form.resetValidation();
    return isVisible;
  }

  public open() {
    this.packagesStore.clear();
    this.modal.open();
  }

  public close() {
    this.modal.close();
  }

  public async submit() {
    if (await this.packagesStore.upload(this.files)) {
      this.close();
    }
    return this.$emit("uploaded");
  }

  public onUploadFiles(files: File[]) {
    this.files = files;
  }
}
</script>

<style lang="scss" scoped></style>
