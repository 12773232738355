<template>
  <span :class="className" :style="elementStyle" @click="$emit('click', $event)">
    <slot />
  </span>
</template>

<script lang="ts">
import { is } from "ramda";
import { Component, Prop, Vue } from "vue-facing-decorator";

@Component({})
export default class Typography extends Vue {
  @Prop() useDots!: boolean;
  @Prop() noWrap!: boolean;
  @Prop({ default: "regular" }) weight!: "regular" | "medium" | "bold";
  @Prop({ default: 13 }) size!: number | string;
  @Prop({ default: "grey darken-3" }) color!: string;

  get elementStyle() {
    return {
      fontSize: `${this.fontSize}px`,
      lineHeight: `${this.fontSize + 1}px`,
    };
  }

  get className() {
    const classes = {
      typography: true,
      base: true,
      [`font-weight-${this.weight}`]: true,
      "no-wrap": this.noWrap,
      "use-dots": this.useDots,
    };
    return Object.assign(classes, this.colorClasses);
  }

  get colorClasses() {
    let classes = {
      [`${this.color}--text`]: true,
    };
    if (this.color.match(/\s/)) {
      const colorParts = this.color.split(" ");
      classes = {
        [`${colorParts[0]}--text`]: true,
        [`text--${colorParts[1]}`]: true,
      };
    }
    return classes;
  }

  private get fontSize(): number {
    return is(Number, this.size) ? this.size : Number.parseInt(this.size);
  }
}
</script>

<style lang="scss" scoped>
.base {
  display: inline-block;
  line-height: 18px;
}

.no-wrap {
  white-space: nowrap;
}

.use-dots {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
}
</style>
