import { DefectType } from "/src/entitites/DefectType.ts";
import { Loader } from "/src/entitites/Loader";
import { DefectTypesProvider } from "/src/services/DefectTypesProvider.ts";
import { Container, Service } from "typedi";
import { action, observable } from "mobx";

@Service({ global: true })
export class DefectTypesStore {
  @observable public types: DefectType[] = [];
  @observable public loader = new Loader();
  @observable public errors: string[] = [];
  private provider = Container.get(DefectTypesProvider);
  private loadPromise: Promise<void> | null = null;

  @action.bound
  public async loadItems() {
    if (this.loadPromise) {
      return await this.loadPromise;
    }
    this.loadPromise = new Promise(resolve => {
      this.loader.start();
      this.provider.getItems().then(result => {
        this.types = result.data.items;
        this.loader.stop();
        resolve();
      });
    });
    await this.loadPromise;
    this.loadPromise = null;
  }

  public async getType(id: number) {
    !this.types.length && (await this.loadItems());
    return this.types.find(item => item.id === id);
  }

  @action.bound
  public async save(model: DefectType) {
    this.clear();
    this.loader.start();
    const response = await this.provider.save(model);
    response?.hasErrors && (this.errors = response.errorsAsArray);
    this.loader.stop();
    return response.success;
  }

  @action.bound
  public async delete(id: number) {
    this.clear();
    this.loader.start();
    const response = await this.provider.delete(id);
    response?.hasErrors && (this.errors = response.errorsAsArray);
    this.loader.stop();
    return response.success;
  }

  @action.bound
  public clear() {
    this.errors = [];
  }
}
