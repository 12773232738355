<template>
  <v-tooltip location="bottom" z-index="1001">
    <template v-slot:activator="{ props }">
      <v-badge :content="badgeContent" floating v-bind="props">
        <slot name="content" />
      </v-badge>
    </template>
    <span>{{ tooltip }}</span>
  </v-tooltip>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-facing-decorator";

@Component({
  components: {},
})
export default class TooltipBadge extends Vue {
  @Prop() badgeContent!: any;
  @Prop() tooltip!: string;
}
</script>

<style scoped></style>
