import { API_ENDPOINTS } from "/src/endpoints";
import { AlgorithmConditionType, AlgorithmSelectionType, AlgorithmSettings } from "/src/entitites/AlgorithmSettings.ts";
import { SaveAlgorithmSettingsBody } from "/src/entitites/Requests/SaveAlgorithmSettings.ts";
import { SelectItem } from "/src/entitites/Select.ts";
import { DetailData, TableData } from "/src/entitites/Table.ts";
import { algorithmConditionTypeDecoder } from "/src/services/Decoders/AlgorithmConditionTypeDecoders.ts";
import { algorithmSelectionTypesDecoder } from "/src/services/Decoders/AlgorithmSelectionTypeDecoders.ts";
import { algorithmSettingsDecoder } from "/src/services/Decoders/AlgorithmSettingsDecoders.ts";
import { selectDecoder } from "/src/services/Decoders/SelectDecoders.ts";
import { detailDecoder, tableDecoder } from "/src/services/Decoders/TableDecoders.ts";
import { createRequestOptions } from "/src/services/RequestSubmitter/RequestOptions.ts";
import { BaseResponse } from "/src/entitites/ResponseEntity";
import { Service } from "typedi";
import { baseResponse } from "./Decoders/CommonDecoders";
import { METHODS } from "./RequestSubmitter/Methods";
import { RequestSubmitter } from "./RequestSubmitter/RequestSubmitter";

@Service({ global: true })
export class AlgorithmSettingsProvider extends RequestSubmitter {
  private listRequest = this.createRequest(
    API_ENDPOINTS.SETTINGS_ALGORITHMS_LIST,
    METHODS.GET,
    tableDecoder(algorithmSettingsDecoder),
  );
  private typesRequest = this.createRequest(
    API_ENDPOINTS.SETTINGS_ALGORITHMS_TYPES,
    METHODS.GET,
    tableDecoder(algorithmSettingsDecoder),
  );
  private selectionTypesRequest = this.createRequest(
    API_ENDPOINTS.SETTINGS_ALGORITHMS_SELECTION_TYPES,
    METHODS.GET,
    tableDecoder(algorithmSelectionTypesDecoder),
  );
  private conditionTypesRequest = this.createRequest(
    API_ENDPOINTS.SETTINGS_ALGORITHMS_CONDITION_TYPES,
    METHODS.GET,
    tableDecoder(algorithmConditionTypeDecoder),
  );
  private includeContourTypesRequest = this.createRequest(
    API_ENDPOINTS.SETTINGS_ALGORITHMS_INCLUDE_CONTOUR_TYPES,
    METHODS.GET,
    tableDecoder(selectDecoder),
  );
  private conditionsCheckTypesRequest = this.createRequest(
    API_ENDPOINTS.SETTINGS_ALGORITHMS_CONDITION_CHECK_TYPES,
    METHODS.GET,
    tableDecoder(selectDecoder),
  );

  private detailRequest = this.createRequest(
    API_ENDPOINTS.SETTINGS_ALGORITHMS_DETAIL,
    METHODS.GET,
    detailDecoder(algorithmSettingsDecoder),
  );
  private saveRequest = this.createRequest(API_ENDPOINTS.SETTINGS_ALGORITHMS_FORM, METHODS.POST, baseResponse());
  private deleteRequest = this.createRequest(API_ENDPOINTS.SETTINGS_ALGORITHMS_DELETE, METHODS.POST, baseResponse());

  public async getItems(): Promise<TableData<AlgorithmSettings>> {
    return await this.listRequest();
  }

  public async getTypes(): Promise<TableData<AlgorithmSettings>> {
    return await this.typesRequest();
  }

  public async getSelectionTypes(): Promise<TableData<AlgorithmSelectionType>> {
    return await this.selectionTypesRequest();
  }

  public async getConditionTypes(): Promise<TableData<AlgorithmConditionType>> {
    return await this.conditionTypesRequest();
  }
  public async getIncludeContourTypes(): Promise<TableData<SelectItem>> {
    return await this.includeContourTypesRequest();
  }
  public async getConditionsCheckTypes(): Promise<TableData<SelectItem>> {
    return await this.conditionsCheckTypesRequest();
  }

  public async getItem(params: any): Promise<DetailData<AlgorithmSettings, null>> {
    return await this.detailRequest(createRequestOptions().setBody({ data: params }));
  }

  public async save(data: SaveAlgorithmSettingsBody): Promise<BaseResponse> {
    return await this.saveRequest(createRequestOptions().setBody({ data }));
  }

  public async delete(id: number, params: any = {}): Promise<BaseResponse> {
    return await this.deleteRequest(createRequestOptions().addUrlParam("id", id).setBody(params));
  }
}
