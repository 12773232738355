import { API_ENDPOINTS } from "/src/endpoints";
import { ManualAlgorithmResult } from "/src/entitites/ManualAlgorithmResult.ts";
import { DetailData, TableData } from "/src/entitites/Table.ts";
import { algorithmResultDecoder } from "/src/services/Decoders/AlgorithmDecoders.ts";
import { detailDecoder, tableDecoder } from "/src/services/Decoders/TableDecoders.ts";
import { createRequestOptions } from "/src/services/RequestSubmitter/RequestOptions.ts";
import { BaseResponse } from "/src/entitites/ResponseEntity";
import { Service } from "typedi";
import { baseResponse } from "./Decoders/CommonDecoders";
import { METHODS } from "./RequestSubmitter/Methods";
import { RequestSubmitter } from "./RequestSubmitter/RequestSubmitter";

@Service({ global: true })
export class ManualAlgorithmResultsProvider extends RequestSubmitter {
  private listRequest = this.createRequest(
    API_ENDPOINTS.MANUAL_RESULTS_LIST,
    METHODS.GET,
    tableDecoder(() => algorithmResultDecoder(ManualAlgorithmResult, true)),
  );

  private detailRequest = this.createRequest(
    API_ENDPOINTS.MANUAL_RESULTS_DETAIL,
    METHODS.GET,
    detailDecoder(() => algorithmResultDecoder(ManualAlgorithmResult, true)),
  );

  private setInWorkRequest = this.createRequest(API_ENDPOINTS.MANUAL_RESULTS_IN_WORK, METHODS.POST, baseResponse());
  private saveRequest = this.createRequest(API_ENDPOINTS.MANUAL_RESULTS_FORM, METHODS.POST, baseResponse());

  public async getItems(params: any): Promise<TableData<ManualAlgorithmResult>> {
    return await this.listRequest(createRequestOptions().setBody(params));
  }

  public async setInWork(id: number): Promise<BaseResponse> {
    return await this.setInWorkRequest(createRequestOptions().addUrlParam("id", id));
  }

  public async getItem(id: number): Promise<DetailData<ManualAlgorithmResult, null>> {
    return await this.detailRequest(createRequestOptions().addUrlParam("id", id));
  }

  public async save(data: ManualAlgorithmResult): Promise<BaseResponse> {
    return await this.saveRequest(createRequestOptions().setBody({ data }));
  }
}
