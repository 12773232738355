<template>
  <v-card height="100%">
    <v-card-text>
      <Canvas :file="file" :results="[result]">
        <template v-slot:after-result>
          <AlgorithmIndividualSettings
            :isLoading="isLoading"
            :file="file"
            :result="result"
            @settings:updated="onSettingsUpdated"
          />
        </template>
      </Canvas>
    </v-card-text>
    <v-card-title>
      <Typography size="18">{{ (result.individualSettings || result.settings)?.title }}</Typography>
    </v-card-title>
    <v-card-subtitle>
      <div v-if="result.data.contoursCount">
        <v-icon icon="mdi-check-bold" class="text-green"></v-icon>
        Рабочая область определена
      </div>
      <div v-else>
        <v-icon icon="mdi-close-thick" class="text-red"></v-icon>
        Алгоритм запускался, но не смог определить рабочую область
      </div>
    </v-card-subtitle>
  </v-card>
</template>

<script lang="ts">
import { ContoursAlgorithmResult } from "/src/entitites/AlgorithmResult.ts";
import { UploadedFile } from "/src/entitites/UploadedFile.ts";
import Canvas from "/src/modules/Canvas/Canvas.vue";
import AlgorithmIndividualSettings from "/src/modules/Files/AlgorithmResults/AlgorithmIndividualSettings.vue";
import Typography from "/src/primitives/Typography.vue";
import { Component, Emit, Prop, Vue } from "vue-facing-decorator";

@Component({
  components: { AlgorithmIndividualSettings, Typography, Canvas },
})
export default class DetectWorkingAreaView extends Vue {
  @Prop() file!: UploadedFile;
  @Prop() result!: ContoursAlgorithmResult;
  @Prop() isLoading!: boolean;

  @Emit("settings:updated")
  onSettingsUpdated() {
    return;
  }
}
</script>

<style lang="scss" scoped></style>
