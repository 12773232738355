import { Loader } from "/src/entitites/Loader";
import { User } from "/src/entitites/User.ts";
import { UsersProvider } from "/src/services/UsersProvider.ts";
import { Container, Service } from "typedi";
import { action, observable } from "mobx";

@Service({ global: true })
export class UsersStore {
  @observable public loader = new Loader();
  @observable public errors: string[] = [];
  @observable public items: User[] = [];
  @observable public totalItems = 0;
  private provider = Container.get(UsersProvider);

  public async loadItems(params: any) {
    this.clear();
    this.loader.start();
    const result = await this.provider.getItems(params);
    this.loader.stop();
    result.hasErrors && (this.errors = result.errorsAsArray);
    this.items = result.data?.items || [];
    this.totalItems = result.data?.totalItems || 0;
  }

  @action.bound
  public async save(model: User) {
    this.clear();
    this.loader.start();
    const response = await this.provider.save(model);
    response?.hasErrors && (this.errors = response.errorsAsArray);
    this.loader.stop();
    return response.success;
  }

  @action.bound
  public async delete(id: number) {
    this.clear();
    this.loader.start();
    const response = await this.provider.delete(id);
    response?.hasErrors && (this.errors = response.errorsAsArray);
    this.loader.stop();
    return response.success;
  }

  @action.bound
  public clear() {
    this.errors = [];
  }
}
