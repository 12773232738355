import { isArray, isFunction, isNumber, isPureObject, isString } from "/src/utils/is.ts";

function filterUnique(value: any, index: number, self: Array<any>) {
  return self.indexOf(value) === index;
}

export function convertToArray(errors: any): Array<string> {
  const converted: Array<string> = [];
  if (isString(errors)) {
    return [errors];
  }
  if (isFunction(errors)) {
    return [];
  }
  if (isNumber(errors)) {
    return [String(errors)];
  }
  if (isPureObject(errors)) {
    Object.keys(errors).forEach(key => {
      converted.push(...convertToArray(errors[key]));
    });
  }
  if (isArray(errors)) {
    errors.forEach(error => {
      converted.push(...convertToArray(error));
    });
  }
  return converted.filter(filterUnique);
}
