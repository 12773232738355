<template>
  <v-card height="100%" class="w-100">
    <v-card-text class="w-100">
      <ComparisonManualCanvas :manual-result="result" :file="result.file" @save="save" />
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import { ManualAlgorithmResult } from "/src/entitites/ManualAlgorithmResult.ts";
import ComparisonManualCanvas from "/src/modules/Canvas/ComparisonManualCanvas.vue";
import AlgorithmIndividualSettings from "/src/modules/Files/AlgorithmResults/AlgorithmIndividualSettings.vue";
import AlgorithmSettingsFields from "/src/modules/Settings/Algorithms/Fields/AlgorithmSettingsFields.vue";
import Typography from "/src/primitives/Typography.vue";
import { Component, Prop, Vue } from "vue-facing-decorator";

@Component({
  components: { AlgorithmSettingsFields, AlgorithmIndividualSettings, ComparisonManualCanvas, Typography },
})
export default class ComparisonManualCanvasView extends Vue {
  @Prop() result!: ManualAlgorithmResult;

  async save(item: ManualAlgorithmResult) {
    this.$emit("save", item);
  }
}
</script>

<style lang="scss" scoped></style>
