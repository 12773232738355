<template>
  <div class="login-page">
    <v-card class="content" max-width="400" outlined elevation="10">
      <v-card-title class="justify-center">
        <Typography :size="28">Вход в личный кабинет</Typography>
      </v-card-title>
      <v-card-text>
        <LoginForm />
      </v-card-text>
    </v-card>
  </div>
</template>

<script lang="ts">
import Notifier from "/src/modules/Layout/Notifier.vue";
import Typography from "/src/primitives/Typography.vue";
import { Vue, Component } from "vue-facing-decorator";
import LoginForm from "./LoginForm.vue";

@Component({
  components: { Notifier, Typography, LoginForm },
})
export default class Login extends Vue {
  tab = 0;
}
</script>

<style scoped lang="scss">
.login-page {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .content {
    padding: 5px 10px;
  }
}
</style>
