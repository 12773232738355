import { Point, PointsContour, Style } from "/src/entitites/PointsContour.ts";
import { Service } from "typedi";

@Service({ global: true })
export class RectDefiner {
  public define(points: Point[], ratio = 1) {
    let [minX, minY, maxX, maxY] = [0, 0, 0, 0];
    points.forEach(point => {
      (!minX || point.x < minX) && (minX = point.x);
      (!maxX || point.x >= maxX) && (maxX = point.x);
      (!minY || point.y < minY) && (minY = point.y);
      (!maxY || point.y >= maxY) && (maxY = point.y);
    });
    return [minX, minY, maxX, maxY].map(value => value * ratio);
  }

  public defineContour(points: Point[], style: Style, ratio = 1) {
    const [minX, minY, maxX, maxY] = this.define(points, ratio);
    const contour = new PointsContour();
    contour.points.push(new Point(minX, minY), new Point(minX, maxY), new Point(maxX, maxY), new Point(maxX, minY));
    contour.style = style;
    return contour;
  }

  public defineParams(points: Point[], ratio = 1) {
    const [minX, minY, maxX, maxY] = this.define(points, ratio);
    return { height: maxY - minY, width: maxX - minX };
  }
}
