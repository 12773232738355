import { DetailData, TableData } from "/src/entitites/Table.ts";
import Decoder, { array, field, number, succeed } from "jsonous";
import { baseResponse, orNull } from "./CommonDecoders";

export const tableDecoder = <T>(itemDecoder: () => Decoder<T>): Decoder<TableData<T>> => {
  return baseResponse<TableData<T> | any>(decoder =>
    decoder.assign(
      "data",
      orNull(
        field(
          "data",
          succeed({})
            .assign("items", field("items", array(itemDecoder())))
            .assign("totalItems", orNull(field("totalItems", number), 0)),
        ),
        { items: [], totalItems: 0 },
      ),
    ),
  );
};

export const detailDecoder = <T, T2 = null>(
  itemDecoder: () => Decoder<T>,
  defaultValue: T2 | null = null,
): Decoder<DetailData<T, T2 | null>> => {
  return baseResponse(decoder => decoder.assign("data", field("data", orNull(itemDecoder(), defaultValue))));
};
