import { UploadedFile } from "/src/entitites/UploadedFile";
import { Loader } from "/src/entitites/Loader";
import { FilesProvider } from "/src/services/FilesProvider";
import { Container, Service } from "typedi";
import { action, observable } from "mobx";

@Service({ global: true })
export class FilesStore {
  @observable public loader = new Loader();
  @observable public errors: string[] = [];
  private provider = Container.get(FilesProvider);

  @action.bound
  public async loadItems(params: any) {
    this.loader.start();
    const result = await this.provider.getItems(params);
    this.loader.stop();
    return result;
  }

  @action.bound
  public async upload(files: File[]) {
    this.loader.start("Загрузка файла");
    this.clear();
    try {
      const response = await this.provider.upload(files);
      response?.hasErrors && (this.errors = response.errorsAsArray);
      this.loader.stop();
      return response.success;
    } catch (e) {
      console.error(e);
      this.errors.push("Произошла ошибка при загрузке файла");
      this.loader.stop();
      return false;
    }
  }

  public async loadItem(id: number): Promise<UploadedFile | null> {
    this.loader.start();
    const result = await this.provider.getItem(id);
    this.loader.stop();
    return result.data;
  }

  public async processItem(id: number) {
    this.loader.start();
    const result = await this.provider.processItem(id);
    this.loader.stop();
    return result;
  }

  @action.bound
  public clear() {
    this.errors = [];
  }
}
