export enum NotifierType {
  Info = "info",
  Error = "error",
  Success = "success",
}

export const TIMEOUT = 5000;

export class NotifierItem {
  public type!: NotifierType;
  public text!: string;
  public visible = false;

  info(text: string) {
    return this.setup(text, NotifierType.Info);
  }

  error(text: string) {
    return this.setup(text, NotifierType.Error);
  }

  success(text: string) {
    return this.setup(text, NotifierType.Success);
  }

  private setup(text: string, type: NotifierType) {
    this.text = text;
    this.type = type;
    this.visible = true;
    return this;
  }
}
