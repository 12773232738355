import { FRONT_ENDPOINTS } from "/src/endpoints";
import Login from "/src/modules/Login/Login.vue";

export const loginRoutes = [{ path: FRONT_ENDPOINTS.LOGIN, component: Login }];

export const loginUrls = {
  loginForm: (to: any) => ({
    path: FRONT_ENDPOINTS.LOGIN,
    component: Login,
    query: { redirect: to.fullPath },
  }),
};
