import { AlgorithmResult, ContoursAlgorithmResult } from "/src/entitites/AlgorithmResult.ts";
import { FilePackage } from "/src/entitites/FilePackage.ts";

export class UploadedFile {
  public id!: number;
  public name!: string;
  public hash!: string;
  public createdAt!: Date;
  public updatedAt!: Date;
  public packageId!: number;
  public package!: FilePackage | null;
  public parentPackageId!: number;
  public parentPackage!: FilePackage | null;
  public algorithmResults!: (AlgorithmResult | ContoursAlgorithmResult)[];
  public algorithmResultsCount!: number;
}
