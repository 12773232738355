import { TypedContour } from "/src/entitites/AlgorithmResult.ts";
import { Point, Style } from "/src/entitites/PointsContour.ts";
import Decoder, { array, field, number, string, succeed } from "jsonous";
import { orNull, toObject } from "./CommonDecoders";

export function decodePoint(): Decoder<Point> {
  return succeed(Point).assign("x", field("x", number)).assign("y", field("y", number)).andThen(toObject(Point));
}

export function decodeTypedContour(): Decoder<TypedContour> {
  return succeed(TypedContour)
    .assign("points", orNull(field("points", array(decodePoint())), []))
    .assign("typeId", orNull(field("typeId", number)))
    .andThen(toObject(TypedContour));
}

export const decodeLineStyle = (decoder: Decoder<any> = succeed({})): Decoder<Style> =>
  decoder
    .assign("lineColor", orNull(field("lineColor", string), ""))
    .assign("lineSize", orNull(field("lineSize", string), 1))
    .andThen(toObject(Style));
