import { TypedContour } from "/src/entitites/AlgorithmResult.ts";
import { AlgorithmSettings } from "/src/entitites/AlgorithmSettings.ts";
import { Point, PointsContour } from "/src/entitites/PointsContour.ts";
import { DefectTypesStore } from "/src/store/DefectTypesStore.ts";
import { Container, Service } from "typedi";

@Service({ global: true })
export class PointsContourProvider {
  private defectTypesStore = Container.get(DefectTypesStore);
  private readonly defaults = {
    lineColor: "blue",
    lineSize: "2",
  };

  public async processTypedContours(contours: TypedContour[]) {
    const list = contours.map(async contour => {
      const pointsContour = new PointsContour();
      pointsContour.points = contour.points;
      const type = await this.defectTypesStore.getType(contour.typeId);
      pointsContour.style.lineColor = type?.params?.lineColor || this.defaults.lineColor;
      pointsContour.style.lineSize = type?.params?.lineSize || this.defaults.lineColor;
      return pointsContour;
    });
    return Promise.all(list);
  }

  public processSimpleContours(contours: Point[][], settings: AlgorithmSettings | null) {
    return contours.map(points => {
      const pointsContour = new PointsContour();
      pointsContour.points = points;
      pointsContour.style.lineColor = settings?.data?.lineColor || this.defaults.lineColor;
      pointsContour.style.lineSize = settings?.data?.lineSize || this.defaults.lineColor;
      return pointsContour;
    });
  }
}
