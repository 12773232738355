<template>
  <div class="manual-actions">
    <TooltipBtn
      v-if="defectTypesStore.types.length"
      btn-variant="plain"
      :btn-class="isManualAddingMode ? 'text-decoration-overline text-deep-purple' : ''"
      icon="mdi-vector-square-plus"
      :tooltip="`Режим добавления ${isManualAddingMode ? 'включен' : 'выключен'}`"
      @click="canvasStore.changeAddingMode(!isManualAddingMode, canvasData())"
    />
    <v-select
      v-if="defectTypesStore.types.length && isManualAddingMode"
      label="Тип дефекта"
      v-model="canvasStore.manualDefectType"
      :items="defectTypesStore.types"
      variant="solo"
      item-value="id"
      hide-details
      density="compact"
    ></v-select>
    <TooltipBtn
      btn-variant="plain"
      :btn-class="isManualDeletingMode ? 'text-decoration-overline text-deep-purple' : ''"
      icon="mdi-vector-square-remove"
      :tooltip="`Режим удаления ${isManualDeletingMode ? 'включен' : 'выключен'}`"
      @click="canvasStore.changeDeletingMode(!isManualDeletingMode, canvasData())"
    />
  </div>
</template>

<script lang="ts">
import { CanvasData } from "/src/entitites/CanvasData.ts";
import TooltipBtn from "/src/primitives/TooltipBtn.vue";
import { DefectTypesStore } from "/src/store/DefectTypesStore.ts";
import { ManualCanvasStore } from "/src/store/ManualCanvasStore.ts";
import { Component, Prop, Vue } from "vue-facing-decorator";

@Component({
  components: { TooltipBtn },
})
export default class ManualCanvasActions extends Vue {
  @Prop() defectTypesStore!: DefectTypesStore;
  @Prop() canvasStore!: ManualCanvasStore;
  @Prop() canvasData!: () => CanvasData;

  get isManualAddingMode() {
    return this.canvasStore.isManualAddingMode;
  }

  get isManualDeletingMode() {
    return this.canvasStore.isManualDeletingMode;
  }
}
</script>

<style lang="scss" scoped>
.manual-actions {
  display: flex;
  align-items: center;
}
</style>
