import { MENU_PARAMS } from "/src/enums/MenuEnum.ts";
import { Container, Service } from "typedi";
import { action, observable } from "mobx";
import { MenuProvider } from "/src/services/MenuProvider";
import { MenuItem } from "/src/entitites/MenuItem";

@Service({ global: true })
export class MenuStore {
  @observable items: MenuItem[] = [];
  private provider = Container.get(MenuProvider);

  @action.bound
  async loadItems() {
    const items = await this.provider.getItems();
    items.data?.items?.forEach((item: MenuItem) => this.fillByParams(item));
    this.items = items.data?.items || [];
  }

  private fillByParams(item: MenuItem) {
    const params = MENU_PARAMS[item.code];
    params?.url && (item.url = params?.url);
    params?.title && (item.name = params?.title);
    params?.icon && (item.icon = params?.icon);
    if (item.subItems?.length) {
      item.subItems.forEach(subItem => this.fillByParams(subItem));
    }
  }
}
