import { API_ENDPOINTS } from "/src/endpoints";
import { FileTypeHandler, FileTypeSettings } from "/src/entitites/FileType.ts";
import { TableData } from "/src/entitites/Table.ts";
import { tableDecoder } from "/src/services/Decoders/TableDecoders.ts";
import { createRequestOptions } from "/src/services/RequestSubmitter/RequestOptions.ts";
import { fileTypeHandlerDecoder, fileTypeSettingsDecoder } from "./Decoders/FileTypeDecoders.ts";
import { BaseResponse } from "/src/entitites/ResponseEntity";
import { Service } from "typedi";
import { baseResponse } from "./Decoders/CommonDecoders";
import { METHODS } from "./RequestSubmitter/Methods";
import { RequestSubmitter } from "./RequestSubmitter/RequestSubmitter";

@Service({ global: true })
export class FileTypesProvider extends RequestSubmitter {
  private typesListRequest = this.createRequest(
    API_ENDPOINTS.SETTINGS_FILE_TYPES_LIST,
    METHODS.GET,
    tableDecoder(fileTypeSettingsDecoder),
  );
  private handlersListRequest = this.createRequest(
    API_ENDPOINTS.SETTINGS_FILE_TYPES_HANDLERS_LIST,
    METHODS.GET,
    tableDecoder(fileTypeHandlerDecoder),
  );

  private saveRequest = this.createRequest(API_ENDPOINTS.SETTINGS_FILE_TYPE_FORM, METHODS.POST, baseResponse());
  private deleteRequest = this.createRequest(API_ENDPOINTS.SETTINGS_FILE_TYPE_DELETE, METHODS.POST, baseResponse());

  public async getTypes(): Promise<TableData<FileTypeSettings>> {
    return await this.typesListRequest();
  }

  public async getHandlers(): Promise<TableData<FileTypeHandler>> {
    return await this.handlersListRequest();
  }

  public async save(model: FileTypeSettings): Promise<BaseResponse> {
    return await this.saveRequest(createRequestOptions().setBody({ data: model }));
  }

  public async delete(id: number): Promise<BaseResponse> {
    return await this.deleteRequest(createRequestOptions().addUrlParam("id", id));
  }
}
