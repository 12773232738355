import { API_ENDPOINTS } from "/src/endpoints";
import { DetailData } from "/src/entitites/Table.ts";
import { User } from "/src/entitites/User.ts";
import { detailDecoder } from "/src/services/Decoders/TableDecoders.ts";
import { userDecoder } from "/src/services/Decoders/UsersDecoders.ts";
import { createRequestOptions } from "/src/services/RequestSubmitter/RequestOptions.ts";
import { Service } from "typedi";
import { baseResponse } from "./Decoders/CommonDecoders";
import { METHODS } from "./RequestSubmitter/Methods";
import { RequestSubmitter } from "./RequestSubmitter/RequestSubmitter";

@Service({ global: true })
export class OwnUserProvider extends RequestSubmitter {
  private ownRequest = this.createRequest(
    API_ENDPOINTS.SETTINGS_USERS_OWN_DETAIL,
    METHODS.GET,
    detailDecoder(userDecoder),
  );

  private saveOwnRequest = this.createRequest(API_ENDPOINTS.SETTINGS_USERS_OWN_FORM, METHODS.POST, baseResponse());

  public async load(): Promise<DetailData<User, null>> {
    return await this.ownRequest();
  }

  public async save(model: User) {
    return await this.saveOwnRequest(createRequestOptions().setBody({ data: model }));
  }
}
