<template>
  <div>
    <v-select
      v-model="settings.data.siblingSettingsIds"
      label="Алгоритмы"
      item-title="title"
      item-value="id"
      multiple
      chips
      closableChips
      clearable
      :items="items"
      :rules="[rules.required]"
    />
  </div>
</template>

<script lang="ts">
import { ManualResultsSettings } from "/src/entitites/AlgorithmSettings.ts";
import { CommonSettingsCodes } from "/src/enums/AlgorithmCodeEnum.ts";
import { AlgorithmSettingsStore } from "/src/store/AlgorithmSettingsStore.ts";
import { RULES } from "/src/utils/ValidationRules.ts";
import { Container } from "typedi";
import { Component, Prop, Vue } from "vue-facing-decorator";

@Component({
  components: {},
})
export default class ManualResultsFields extends Vue {
  @Prop() readonly settings!: ManualResultsSettings;
  store = Container.get(AlgorithmSettingsStore);
  rules = RULES;

  created() {
    this.store.loadItems();
  }

  get items() {
    return this.store.items
      .filter(item => CommonSettingsCodes.includes(item.code))
      .map(item => ({
        title: `#${item.id} ${item.title} (${!item.fileId ? "общий" : "для отдельного файла"})`,
        id: item.id,
      }));
  }
}
</script>

<style lang="scss" scoped></style>
