export function Debounce(timeout: number) {
  return function validate(
    _target: any,
    _propertyName: string,
    descriptor: TypedPropertyDescriptor<(...args: any[]) => unknown>,
  ) {
    const originalFn = descriptor.value!;
    if (!originalFn) {
      throw new Error("This decorator must be used on a method.");
    }

    let timeoutId: number | NodeJS.Timeout = 0;

    const clear = () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
        timeoutId = 0;
      }
    };
    descriptor.value = function (...args) {
      clear();
      timeoutId = setTimeout(() => {
        timeoutId = 0;
        originalFn.apply(this, args);
      }, timeout);
    };
  };
}
