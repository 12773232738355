export class Point {
  constructor(public x = 0, public y = 0) {}
}

export class Style {
  public lineColor!: string;
  public lineSize!: number | string;
}

export class PointsContour {
  public points: Point[] = [];
  public style = new Style();
}
