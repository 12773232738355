import { API_ENDPOINTS } from "/src/endpoints";
import { DefectType } from "/src/entitites/DefectType.ts";
import { TableData } from "/src/entitites/Table.ts";
import { defectTypeDecoder } from "/src/services/Decoders/DefectTypeDecoders.ts";
import { BaseResponse } from "/src/entitites/ResponseEntity";
import { tableDecoder } from "/src/services/Decoders/TableDecoders.ts";
import { createRequestOptions } from "/src/services/RequestSubmitter/RequestOptions.ts";
import { Service } from "typedi";
import { baseResponse } from "./Decoders/CommonDecoders";
import { METHODS } from "./RequestSubmitter/Methods";
import { RequestSubmitter } from "./RequestSubmitter/RequestSubmitter";

@Service({ global: true })
export class DefectTypesProvider extends RequestSubmitter {
  private listRequest = this.createRequest(
    API_ENDPOINTS.SETTINGS_DEFECT_TYPES,
    METHODS.GET,
    tableDecoder(defectTypeDecoder),
  );
  private saveRequest = this.createRequest(API_ENDPOINTS.SETTINGS_DEFECT_TYPES_FORM, METHODS.POST, baseResponse());
  private deleteRequest = this.createRequest(API_ENDPOINTS.SETTINGS_DEFECT_TYPE_DELETE, METHODS.POST, baseResponse());

  public async getItems(): Promise<TableData<DefectType>> {
    return await this.listRequest();
  }

  public async save(model: DefectType): Promise<BaseResponse> {
    return await this.saveRequest(createRequestOptions().setBody({ data: model }));
  }

  public async delete(id: number): Promise<BaseResponse> {
    return await this.deleteRequest(createRequestOptions().addUrlParam("id", id));
  }
}
