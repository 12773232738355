import { API_ENDPOINTS } from "/src/endpoints";
import { FilePackage } from "/src/entitites/FilePackage.ts";
import { DetailData, TableData } from "/src/entitites/Table.ts";
import { detailDecoder, tableDecoder } from "/src/services/Decoders/TableDecoders.ts";
import { packageDecoder } from "./Decoders/PackagesDecoders.ts";
import { createRequestOptions } from "./RequestSubmitter/RequestOptions.ts";
import { BaseResponse } from "/src/entitites/ResponseEntity";
import { Service } from "typedi";
import { baseResponse } from "./Decoders/CommonDecoders";
import { METHODS } from "./RequestSubmitter/Methods";
import { RequestSubmitter } from "./RequestSubmitter/RequestSubmitter";

@Service({ global: true })
export class PackagesProvider extends RequestSubmitter {
  private detailRequest = this.createRequest(API_ENDPOINTS.PACKAGES_DETAIL, METHODS.GET, detailDecoder(packageDecoder));
  private listRequest = this.createRequest(API_ENDPOINTS.PACKAGES_LIST, METHODS.GET, tableDecoder(packageDecoder));
  private uploadRequest = this.createRequest(API_ENDPOINTS.PACKAGES_UPLOAD, METHODS.POST, baseResponse());
  private deleteRequest = this.createRequest(API_ENDPOINTS.PACKAGES_DELETE, METHODS.POST, baseResponse());

  public async getItems(params: any): Promise<TableData<FilePackage>> {
    return await this.listRequest(createRequestOptions().setBody(params));
  }

  public async getItem(id: number): Promise<DetailData<FilePackage, null>> {
    return await this.detailRequest(createRequestOptions().addUrlParam("id", id));
  }

  public async upload(files: File[], progressReceiver?: (progress: number) => void): Promise<BaseResponse> {
    const body = new FormData();
    files.forEach(file => body.append("files[]", file));
    const options = createRequestOptions().setBody(body).setProgressReceiver(progressReceiver);
    return await this.uploadRequest(options);
  }

  public async delete(id: number): Promise<BaseResponse> {
    return await this.deleteRequest(createRequestOptions().addUrlParam("id", id));
  }
}
