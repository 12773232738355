<template>
  <Modal ref="modal" @toggle="toggle" :label="title" width="400px">
    <template v-slot:body>
      <FormErrors :errors="store.errors" />
      <v-form ref="form">
        <v-text-field v-model="model.title" :rules="[rules.required]" label="Название"></v-text-field>
        <v-select
          v-model="model.extensions"
          label="Расширения"
          multiple
          chips
          closableChips
          clearable
          :items="['zip', 'rar', 'datx', 'bmp']"
          :rules="[rules.required]"
        />
        <v-select
          v-model="model.handlers"
          label="Обработчики"
          multiple
          chips
          closableChips
          clearable
          item-title="title"
          item-value="code"
          :items="store.handlers"
          :rules="[rules.required]"
        />
      </v-form>
    </template>
    <template v-slot:footer>
      <v-btn :disabled="store.loader.isLoading" variant="elevated" type="submit" color="primary" @click="save">
        Сохранить
      </v-btn>
      <v-btn variant="tonal" @click="close">Закрыть</v-btn>
    </template>
  </Modal>
</template>

<script lang="ts">
import { FileTypeSettings } from "/src/entitites/FileType.ts";
import FormErrors from "/src/primitives/FormErrors.vue";
import Modal from "/src/primitives/Modal.vue";
import { FileTypesStore } from "/src/store/FileTypesStore.ts";
import { LayoutStore } from "/src/store/LayoutStore.ts";
import { RULES } from "/src/utils/ValidationRules.ts";
import { Container } from "typedi";
import { Component, Emit, Ref, Vue } from "vue-facing-decorator";

@Component({
  components: { FormErrors, Modal },
  emits: ["change"],
})
export default class FileTypeFormModal extends Vue {
  @Ref() readonly modal!: Modal;
  @Ref() readonly form!: types.AbstractForm;
  private layoutStore: LayoutStore = Container.get(LayoutStore);
  store = Container.get(FileTypesStore);
  model = new FileTypeSettings();
  rules = RULES;

  get title() {
    return this.model.id ? `Редактирование типа #${this.model.id} ${this.model.title}` : "Добавление типа";
  }

  @Emit()
  public toggle(isVisible: boolean) {
    this.form && this.form.resetValidation();
    return isVisible;
  }

  public open(model: FileTypeSettings) {
    this.model = model;
    this.modal.open();
  }

  public close() {
    this.modal.close();
  }

  public async save() {
    if (!(await this.form.validate()).valid) {
      return;
    }
    if (!(await this.store.save(this.model))) {
      this.layoutStore.error("Ошибка при сохранении изменений");
      return;
    }
    this.layoutStore.success("Изменения сохранены");
    this.$emit("change");
    this.close();
  }
}
</script>

<style lang="scss" scoped></style>
