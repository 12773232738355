<template>
  <div>
    <v-card class="mb-2 two-cols-card">
      <v-card-title><Typography :size="16">Исходные размеры листа в мм</Typography></v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <v-text-field
              label="Высота"
              type="number"
              v-model="settings.data.sourceHeight"
              hide-details="auto"
              :rules="[rules.required]"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              label="Ширина"
              type="number"
              v-model="settings.data.sourceWidth"
              hide-details="auto"
              :rules="[rules.required]"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card class="mb-2 two-cols-card">
      <v-card-title><Typography :size="16">Целевые размеры листа в мм</Typography></v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <v-text-field
              label="Высота"
              type="number"
              v-model="settings.data.targetHeight"
              hide-details="auto"
              :rules="[rules.required]"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              label="Ширина"
              type="number"
              v-model="settings.data.targetWidth"
              hide-details="auto"
              :rules="[rules.required]"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script lang="ts">
import { DetectWorkingAreaSettings } from "/src/entitites/AlgorithmSettings.ts";
import Typography from "/src/primitives/Typography.vue";
import { AlgorithmSettingsStore } from "/src/store/AlgorithmSettingsStore.ts";
import { RULES } from "/src/utils/ValidationRules.ts";
import { Container } from "typedi";
import { Component, Prop, Vue } from "vue-facing-decorator";

@Component({
  components: { Typography },
})
export default class DetectWorkingAreaFields extends Vue {
  @Prop() readonly settings!: DetectWorkingAreaSettings;
  rules = RULES;
  store = Container.get(AlgorithmSettingsStore);

  created() {
    this.store.loadSelectionTypes();
  }
}
</script>

<style lang="scss" scoped>
.two-cols-card {
  .v-input.v-text-field:not(.v-select) {
    max-width: 200px;
  }
}
</style>
