import { DefectType } from "/src/entitites/DefectType.ts";
import { decodeLineStyle } from "/src/services/Decoders/ContourDecoders.ts";
import Decoder, { field, number, string, succeed } from "jsonous";
import { orNull, toObject } from "./CommonDecoders";

export const defectTypeDecoder = (): Decoder<DefectType> =>
  succeed(DefectType)
    .assign("id", field("id", number))
    .assign("title", orNull(field("title", string), ""))
    .assign("code", orNull(field("code", string), ""))
    .assign("params", field("params", decodeLineStyle()))
    .andThen(toObject(DefectType));
