<template>
  <v-card>
    <v-card-title>Veneer Scanner</v-card-title>
    <v-card-text>
      <v-row>
        <v-col>
          <Typography :size="16"> Приложение для проверки алгоритмов </Typography>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <Typography :size="14"> Выберите интересующий вас раздел в меню слева </Typography>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import Typography from "/src/primitives/Typography.vue";
import { Component, Vue } from "vue-facing-decorator";

@Component({
  components: { Typography },
})
export default class Home extends Vue {}
</script>

<style lang="scss" scoped></style>
