import { API_ENDPOINTS } from "/src/endpoints";
import { WebQuery } from "/src/entitites/ItemList.ts";
import { DetailData } from "/src/entitites/Table.ts";
import { detailDecoder } from "/src/services/Decoders/TableDecoders.ts";
import { decodeWebQuery } from "/src/services/Decoders/WebQueryDecoders.ts";
import { createRequestOptions } from "/src/services/RequestSubmitter/RequestOptions.ts";
import { BaseResponse } from "/src/entitites/ResponseEntity";
import { Service } from "typedi";
import { baseResponse } from "./Decoders/CommonDecoders";
import { METHODS } from "./RequestSubmitter/Methods";
import { RequestSubmitter } from "./RequestSubmitter/RequestSubmitter";

@Service({ global: true })
export class WebQueryProvider extends RequestSubmitter {
  private fetchRequest = this.createRequest(API_ENDPOINTS.WEB_QUERY, METHODS.GET, detailDecoder(decodeWebQuery));
  private saveRequest = this.createRequest(API_ENDPOINTS.WEB_QUERY, METHODS.POST, baseResponse());

  public async fetch(tableKey: string): Promise<DetailData<WebQuery, null>> {
    return await this.fetchRequest(createRequestOptions().addUrlParam("key", tableKey));
  }

  public async save(tableKey: string, data: any): Promise<BaseResponse> {
    return await this.saveRequest(createRequestOptions().addUrlParam("key", tableKey).setBody(data));
  }
}
