<template>
  <span class="line-example" :style="`border-bottom: ${settings.lineSize}px solid ${settings.lineColor};`"></span>
</template>

<script lang="ts">
import { Style } from "/src/entitites/PointsContour.ts";
import { Component, Prop, Vue } from "vue-facing-decorator";

@Component
export default class DefectTypeView extends Vue {
  @Prop({ required: true }) settings!: Style;
}
</script>

<style scoped lang="scss">
.line-example {
  display: inline-block;
  width: 100px;
  vertical-align: middle;
}
</style>
