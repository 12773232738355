<template>
  <v-toolbar dark prominent density="compact" color="transparent">
    <v-spacer></v-spacer>
    <span v-if="user">{{ user.name }}</span>
    <div class="mr-6 ml-6">
      <DropdownMenu :actions="actions" density="default" @action="handleAction" />
    </div>
    <MyProfileFormModal ref="myProfileModal" />
  </v-toolbar>
</template>

<script lang="ts">
import { DropdownAction } from "/src/entitites/Dropdown.ts";
import { User } from "/src/entitites/User.ts";
import Notifier from "/src/modules/Layout/Notifier.vue";
import { router } from "/src/modules/Router";
import MyProfileFormModal from "/src/modules/Users/MyProfileFormModal.vue";
import DropdownMenu from "/src/primitives/DropdownMenu.vue";
import { UrlsProvider } from "/src/services/UrlsProvider.ts";
import { AuthStore } from "/src/store/AuthStore.ts";
import { LayoutStore } from "/src/store/LayoutStore.ts";
import { OwnUserStore } from "/src/store/OwnUserStore.ts";
import { Container } from "typedi";
import { Component, Ref, Vue } from "vue-facing-decorator";

@Component({
  components: { DropdownMenu, Notifier, MyProfileFormModal },
})
export default class UserToolbar extends Vue {
  @Ref() readonly myProfileModal!: MyProfileFormModal;
  layoutStore = Container.get(LayoutStore);
  authStore = Container.get(AuthStore);
  urlsProvider = Container.get(UrlsProvider);
  usersStore = Container.get(OwnUserStore);
  user!: User | null;
  actions: DropdownAction[] = [
    {
      title: "Мой профиль",
      code: "myProfile",
      icon: "mdi-account-box-outline",
    },
    {
      title: "Выйти",
      code: "logout",
      icon: "mdi-export",
      confirm: {
        title: "Подтверждение выхода",
        message: "Вы действительно хотите выйти из учетной записи?",
        btnApprove: "Выйти",
      },
    },
  ];

  async mounted() {
    this.user = await this.usersStore.load();
  }

  async handleAction(action: DropdownAction) {
    switch (action.code) {
      case "myProfile":
        return this.myProfileModal.open();
      case "logout":
        return this.authStore.logout(this.urlsProvider.loginUrls.loginForm(router.currentRoute) as any);
    }
  }
}
</script>

<style scoped></style>
