<template>
  <v-card height="100%">
    <v-card-text>
      <Canvas :file="file" :results="result.data.siblingResults">
        <template v-slot:after-result>
          <AlgorithmIndividualSettings
            v-for="(siblingResult, key) in result.data.siblingResults"
            :isLoading="isLoading"
            :file="file"
            :result="siblingResult"
            :key="key"
            :collapsed="true"
            @settings:updated="onSettingsUpdated"
          />
        </template>
      </Canvas>
    </v-card-text>
    <v-card-title>
      <Typography size="18">{{ result.settings?.title }}</Typography>
    </v-card-title>
    <v-card-subtitle>
      <div v-if="result.data.siblingResults?.length">
        <v-icon icon="mdi-check-bold" class="text-green"></v-icon>
        Есть результаты алгоритмов ({{ result.data.siblingResults?.length }} шт.)
      </div>
    </v-card-subtitle>
  </v-card>
</template>

<script lang="ts">
import { CumulativeResultsAlgorithmResult } from "/src/entitites/AlgorithmResult.ts";
import { UploadedFile } from "/src/entitites/UploadedFile.ts";
import Canvas from "/src/modules/Canvas/Canvas.vue";
import AlgorithmIndividualSettings from "/src/modules/Files/AlgorithmResults/AlgorithmIndividualSettings.vue";
import Typography from "/src/primitives/Typography.vue";
import { Component, Emit, Prop, Vue } from "vue-facing-decorator";

@Component({
  components: { AlgorithmIndividualSettings, Typography, Canvas },
})
export default class CumulativeResultsView extends Vue {
  @Prop() file!: UploadedFile;
  @Prop() result!: CumulativeResultsAlgorithmResult;
  @Prop() isLoading!: boolean;

  @Emit("settings:updated")
  onSettingsUpdated() {
    return;
  }
}
</script>

<style lang="scss" scoped></style>
