import { NotifierItem, TIMEOUT } from "/src/entitites/NotifierItem.ts";
import { Debounce } from "/src/utils/Decorators.ts";
import { Service } from "typedi";
import { action, observable } from "mobx";

@Service({ global: true })
export class LayoutStore {
  @observable notifications: NotifierItem[] = [];
  private callbacks: (() => void)[] = [];

  public info(message: string, withDebounce = true) {
    const notificationItem = new NotifierItem().info(message);
    withDebounce ? this.setupWithDebounce(notificationItem) : this.setupNotification(notificationItem);
  }

  public error(message: string, withDebounce = true) {
    const notificationItem = new NotifierItem().error(message);
    withDebounce ? this.setupWithDebounce(notificationItem) : this.setupNotification(notificationItem);
  }

  public success(message: string, withDebounce = true) {
    const notificationItem = new NotifierItem().success(message);
    withDebounce ? this.setupWithDebounce(notificationItem) : this.setupNotification(notificationItem);
  }

  @action.bound
  public addCallback(callback: () => void) {
    this.callbacks.push(callback);
  }

  @action.bound
  private setupNotification(notification: NotifierItem) {
    this.notifications.push(notification);
    this.callbacks.forEach(callback => callback());
    setTimeout(() => this.clearFirst(), TIMEOUT);
  }

  @action.bound
  @Debounce(200)
  private setupWithDebounce(notification: NotifierItem) {
    this.setupNotification(notification);
  }

  @action.bound
  private clearFirst() {
    this.notifications.shift();
    this.callbacks.forEach(callback => callback());
  }
}
