<template>
  <v-card class="mb-2 two-cols-card">
    <v-card-title><Typography :size="16">Площадь дефекта</Typography></v-card-title>
    <v-card-text>
      <v-row>
        <v-col>
          <v-text-field label="Больше" type="number" v-model="data.squareMore" hide-details />
        </v-col>
        <v-col>
          <v-text-field label="Больше или равно" type="number" v-model="data.squareMoreOrEqual" hide-details />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field label="Меньше или равно" type="number" v-model="data.squareLessOrEqual" hide-details />
        </v-col>
        <v-col>
          <v-text-field label="Меньше" type="number" v-model="data.squareLess" hide-details />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import type { SquareParams } from "/src/entitites/AlgorithmSettings/SquareParams.ts";
import Typography from "/src/primitives/Typography.vue";
import { Component, Prop, Vue } from "vue-facing-decorator";

@Component({
  components: { Typography },
})
export default class SquareParamsFields extends Vue {
  @Prop() readonly data!: SquareParams;
}
</script>

<style lang="scss" scoped>
.two-cols-card {
  .v-input.v-text-field:not(.v-select) {
    max-width: 200px;
  }
}
</style>
