export class FileTypeSettings {
  public id!: number;
  public title!: string;
  public extensions: string[] = [];
  public handlers: string[] = [];
}

export class FileTypeHandler {
  public code!: string;
  public sort!: number;
  public title!: string;
}
