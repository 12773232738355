<template>
  <v-tooltip location="bottom" z-index="1001">
    <template v-slot:activator="{ props }">
      <v-btn
        :icon="icon"
        :variant="btnVariant"
        :class="btnClass"
        v-bind="props"
        @click="$emit('click', $event)"
        :to="to"
        :href="href"
      />
    </template>
    <span>{{ tooltip }}</span>
  </v-tooltip>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-facing-decorator";

@Component({
  components: {},
})
export default class TooltipBtn extends Vue {
  @Prop() icon!: string;
  @Prop() btnVariant!: "flat" | "text" | "elevated" | "tonal" | "outlined" | "plain" | any;
  @Prop() btnClass!: string;
  @Prop() tooltip!: string;
  @Prop({ default: "" }) color!: string;
  @Prop() to!: any;
  @Prop() href!: any;
}
</script>

<style scoped>
.v-btn--variant-plain:focus {
  opacity: 0.62;
}
.v-btn--variant-plain:focus:hover {
  opacity: 1;
}
</style>
