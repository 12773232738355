<template>
  <Modal ref="modal" @toggle="toggle" :label="title" width="400px">
    <template v-slot:body>
      <FormErrors :errors="store.errors"></FormErrors>
      <v-form ref="form">
        <v-text-field v-model="model.title" :rules="[rules.required]" label="Название" />
        <v-text-field v-model="model.code" :rules="[rules.required]" label="Код дефекта" />
        <v-text-field type="number" v-model="model.params.lineSize" :rules="[rules.required]" label="Размер линии" />
        <p>
          Цвет линии
          <v-color-picker v-model="model.params.lineColor" :modes="['hexa']" width="100%" />
        </p>
      </v-form>
    </template>
    <template v-slot:footer>
      <v-btn :disabled="store.loader.isLoading" variant="elevated" type="submit" color="primary" @click="save">
        Сохранить
      </v-btn>
      <v-btn variant="tonal" @click="close">Закрыть</v-btn>
    </template>
  </Modal>
</template>

<script lang="ts">
import { DefectType } from "/src/entitites/DefectType.ts";
import FormErrors from "/src/primitives/FormErrors.vue";
import Modal from "/src/primitives/Modal.vue";
import { DefectTypesStore } from "/src/store/DefectTypesStore.ts";
import { LayoutStore } from "/src/store/LayoutStore.ts";
import { RULES } from "/src/utils/ValidationRules.ts";
import { Container } from "typedi";
import { Component, Emit, Ref, Vue } from "vue-facing-decorator";

@Component({
  components: { FormErrors, Modal },
  emits: ["change"],
})
export default class DefectTypeFormModal extends Vue {
  @Ref() readonly modal!: Modal;
  @Ref() readonly form!: types.AbstractForm;
  private layoutStore: LayoutStore = Container.get(LayoutStore);
  store = Container.get(DefectTypesStore);
  model = new DefectType();
  rules = RULES;

  get title() {
    return this.model.id ? `Редактирование дефекта #${this.model.id} ${this.model.title}` : "Добавление дефекта";
  }

  @Emit()
  public toggle(isVisible: boolean) {
    this.form && this.form.resetValidation();
    return isVisible;
  }

  public open(model: DefectType) {
    this.model = model;
    this.modal.open();
  }

  public close() {
    this.modal.close();
  }

  public async save() {
    if (!(await this.form.validate()).valid) {
      return;
    }
    if (!(await this.store.save(this.model))) {
      this.layoutStore.error("Ошибка при сохранении изменений");
      return;
    }
    this.layoutStore.success("Изменения сохранены");
    this.$emit("change");
    this.close();
  }
}
</script>

<style lang="scss" scoped></style>
