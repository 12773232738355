import { action, observable, computed } from "mobx";
import { Service } from "typedi";

@Service({ transient: true })
export class Loader {
  @observable public operationName!: string;
  @observable public isLoading = false;
  @observable private count: number;
  @observable private _isFirstLoad = true;
  private readonly isMultiple: boolean;

  constructor(isMultiple = false) {
    this.isMultiple = isMultiple;
    this.count = 0;
  }

  @action.bound
  start(operationName: string | null = null) {
    this.isLoading = true;
    this.count++;
    operationName && (this.operationName = operationName);
  }

  @action.bound
  stop() {
    this._isFirstLoad = false;
    this.count--;
    if (this.isMultiple && this.count > 0) {
      return;
    }
    this.operationName = "";
    this.isLoading = false;
  }

  @computed
  get isInitialLoad() {
    return this._isFirstLoad && this.isLoading;
  }

  get isFirstLoad(): boolean {
    return this._isFirstLoad;
  }
}
