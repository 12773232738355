<template>
  <div>
    <v-card class="mb-2">
      <v-card-text>
        <v-row>
          <v-col>
            <v-select
              v-model="settings.data.selectionType"
              label="Способ представления дефекта"
              item-title="title"
              item-value="id"
              :items="store.selectionTypes"
              :rules="[rules.required]"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-select
              v-model="settings.data.areaLimitSettingsId"
              label="Ограничить область определения дефектов алгоритмом"
              item-title="title"
              item-value="id"
              hide-details="auto"
              :items="store.areaLimitSettings"
              :rules="[rules.required]"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <HsvFields :data="settings.data"></HsvFields>
    <EllipseAreaRatioFields :data="settings.data"></EllipseAreaRatioFields>
  </div>
</template>

<script lang="ts">
import { DetectDefectsByKnotsSettings } from "/src/entitites/AlgorithmSettings.ts";
import Typography from "/src/primitives/Typography.vue";
import { AlgorithmSettingsStore } from "/src/store/AlgorithmSettingsStore.ts";
import { RULES } from "/src/utils/ValidationRules.ts";
import { Container } from "typedi";
import { Component, Prop, Vue } from "vue-facing-decorator";
import EllipseAreaRatioFields from "./EllipseAreaRatioFields.vue";
import HsvFields from "./HsvFields.vue";

@Component({
  components: { EllipseAreaRatioFields, HsvFields, Typography },
})
export default class DetectDefectsByKnotsFields extends Vue {
  @Prop() readonly settings!: DetectDefectsByKnotsSettings;
  rules = RULES;
  store = Container.get(AlgorithmSettingsStore);

  created() {
    this.store.loadSelectionTypes();
  }
}
</script>

<style lang="scss" scoped></style>
