export enum API_ENDPOINTS {
  LOGIN = "/api/v1/login",
  LOGOUT = "/api/v1/logout",
  MENU = "/api/v1/menu",
  FILES_UPLOAD = "/api/v1/files/upload",
  FILES_LIST = "/api/v1/files/list",
  FILES_DETAIL = "/api/v1/files/:id/detail",
  FILES_DOWNLOAD = "/api/v1/files/:hash",
  FILES_PROCESS = "/api/v1/files/:id/process",
  PACKAGES_LIST = "/api/v1/packages/list",
  PACKAGES_DETAIL = "/api/v1/packages/:id/detail",
  PACKAGES_UPLOAD = "/api/v1/packages/upload",
  PACKAGES_DELETE = "/api/v1/packages/:id/delete",
  MANUAL_RESULTS_LIST = "/api/v1/results/manual-list",
  MANUAL_RESULTS_DETAIL = "/api/v1/results/manual-list/:id/detail",
  MANUAL_RESULTS_IN_WORK = "/api/v1/results/manual-list/:id/set-in-work",
  MANUAL_RESULTS_FORM = "/api/v1/results/manual-list/form",
  SETTINGS_FILE_TYPES_LIST = "/api/v1/settings/file-types/list",
  SETTINGS_FILE_TYPES_HANDLERS_LIST = "/api/v1/settings/file-types/handlers-list",
  SETTINGS_FILE_TYPE_FORM = "/api/v1/settings/file-types/form",
  SETTINGS_FILE_TYPE_DELETE = "/api/v1/settings/file-types/:id/delete",
  SETTINGS_ALGORITHMS_LIST = "/api/v1/settings/algorithms/list",
  SETTINGS_ALGORITHMS_TYPES = "/api/v1/settings/algorithms/types",
  SETTINGS_ALGORITHMS_SELECTION_TYPES = "/api/v1/settings/algorithms/selections-types",
  SETTINGS_ALGORITHMS_CONDITION_TYPES = "/api/v1/settings/algorithms/condition-types",
  SETTINGS_ALGORITHMS_INCLUDE_CONTOUR_TYPES = "/api/v1/settings/algorithms/include-contour-types",
  SETTINGS_ALGORITHMS_CONDITION_CHECK_TYPES = "/api/v1/settings/algorithms/conditions-check-types",
  SETTINGS_ALGORITHMS_DETAIL = "/api/v1/settings/algorithms/detail",
  SETTINGS_ALGORITHMS_FORM = "/api/v1/settings/algorithms/form",
  SETTINGS_ALGORITHMS_DELETE = "/api/v1/settings/algorithms/:id/delete",
  SETTINGS_DEFECT_TYPES = "/api/v1/settings/defect-types/list",
  SETTINGS_DEFECT_TYPES_FORM = "/api/v1/settings/defect-types/form",
  SETTINGS_DEFECT_TYPE_DELETE = "/api/v1/settings/defect-types/:id/delete",
  SETTINGS_USERS_OWN_DETAIL = "/api/v1/settings/users/own",
  SETTINGS_USERS_OWN_FORM = "/api/v1/settings/users/own/form",
  SETTINGS_USERS_LIST = "/api/v1/settings/users/list",
  SETTINGS_USERS_FORM = "/api/v1/settings/users/form",
  SETTINGS_USERS_DELETE = "/api/v1/settings/users/:id/delete",
  SETTINGS_USER_ROLES_LIST = "/api/v1/settings/user-roles/list",
  SETTINGS_USER_ROLES_FORM = "/api/v1/settings/user-roles/form",
  SETTINGS_USER_ROLES_DELETE = "/api/v1/settings/user-roles/:id/delete",
  SETTINGS_USER_PERMISSIONS_LIST = "/api/v1/settings/user-roles/permissions-list",
  WEB_QUERY = "/api/v1/web-query/:key",
}

export enum FRONT_ENDPOINTS {
  LOGIN = "/app/login",
  HOME = "/app",
  FILES_LIST = "/app/files/list",
  FILES_DETAIL = "/app/files/:id",
  PACKAGES_LIST = "/app/packages/list",
  PACKAGES_DETAIL = "/app/packages/:id",
  MANUAL_RESULTS_LIST = "/app/results/manual-list",
  MANUAL_RESULTS_DETAIL = "/app/results/manual-list/:id",
  SETTINGS_FILE_TYPES = "/app/settings/file-types",
  SETTINGS_ALGORITHMS = "/app/settings/algorithms",
  SETTINGS_DEFECT_TYPES = "/app/settings/defect-types",
  SETTINGS_USERS_LIST = "/app/settings/users-list",
  SETTINGS_USER_ROLES_LIST = "/app/settings/user-roles-list",
}
