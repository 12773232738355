<template>
  <div v-if="errors.length">
    <v-alert variant="outlined" type="error" title="Необходимо исправить ошибки:" :prominent="true" border="top">
      <ul>
        <li v-for="(error, key) in errors" :key="key">{{ error }}</li>
      </ul>
    </v-alert>
    <br />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-facing-decorator";

@Component
export default class FormErrors extends Vue {
  @Prop({ default: () => [] }) errors!: string[];
}
</script>

<style scoped lang="scss"></style>
