import { Service } from "typedi";

@Service({ transient: true })
export class CanvasZoomStore {
  private currentZoom = 1;
  private minZoom = 1;
  private maxZoom = 10;
  private stepSize = 0.3;
  private callbackOnChange: (() => void) | null = null;

  public get currentValue() {
    return this.currentZoom;
  }

  public setZoom(newZoom: number) {
    if (newZoom < this.minZoom) newZoom = this.minZoom;
    if (newZoom > this.maxZoom) newZoom = this.maxZoom;
    if (newZoom !== this.currentValue) {
      this.currentZoom = newZoom;
      this.callbackOnChange && this.callbackOnChange();
    }
  }

  public zoomIn() {
    return this.change(1);
  }

  public zoomOut() {
    return this.change(-1);
  }

  public reset() {
    return this.change(0);
  }

  public setCallbackOnChange(cb: () => void) {
    this.callbackOnChange = cb;
  }

  private change(direction: number) {
    const newZoom = direction ? Number((this.currentZoom + direction * this.stepSize).toFixed(2)) : 1;
    this.setZoom(newZoom);
  }
}
