import { CanvasData } from "/src/entitites/CanvasData.ts";
import { DefectType } from "/src/entitites/DefectType.ts";
import { ManualAlgorithmResult } from "/src/entitites/ManualAlgorithmResult.ts";
import { Point, PointsContour } from "/src/entitites/PointsContour.ts";
import { PointsContourProvider } from "/src/services/PointsContourProvider.ts";
import { RectDefiner } from "/src/services/RectDefiner.ts";
import { CanvasStore, SetupOptions } from "/src/store/CanvasStore.ts";
import { DefectTypesStore } from "/src/store/DefectTypesStore.ts";
import { LayoutStore } from "/src/store/LayoutStore.ts";
import { observable } from "mobx";
import { Service, Container } from "typedi";

export type BeforeUpdateCallback = ((ratio: number) => any) | null;
export type AfterUpdateCallback = (() => any) | null;
export type AfterUpdateManualCallback = ((ratio: number) => any) | null;

@Service({ transient: true })
export class ManualCanvasStore {
  @observable isManualAddingMode = false;
  @observable isManualDeletingMode = false;
  @observable manualDefectType: number | null = null;
  @observable readonly canvasStore = Container.get(CanvasStore);
  private defectTypesStore = Container.get(DefectTypesStore);
  private layoutStore = Container.get(LayoutStore);
  private rectDefiner = Container.get(RectDefiner);
  private pointsContourProvider = Container.get(PointsContourProvider);
  private manualResult: ManualAlgorithmResult | null = null;
  private beforeUpdateCallback: BeforeUpdateCallback | null = null;
  private afterUpdateCallback: AfterUpdateCallback = null;
  private afterUpdateManualCallback: AfterUpdateManualCallback = null;
  private additionalContours: PointsContour[] = [];

  public async setup(options: SetupOptions, manualResult: ManualAlgorithmResult) {
    this.manualResult = manualResult;
    await this.canvasStore.setup(options);
  }

  public setAdditionalContours(contours: PointsContour[]) {
    this.additionalContours = contours;
  }

  public async updateCanvas(canvasData: CanvasData) {
    this.canvasStore.clearContours();
    if (this.manualResult) {
      const contours = this.manualResult.data.manualContours;
      this.canvasStore.addContours(await this.pointsContourProvider.processTypedContours(contours));
    }
    this.canvasStore.addContours(this.additionalContours);
    await this.canvasStore.updateCanvas(canvasData);
    this.beforeUpdateCallback && this.beforeUpdateCallback(this.canvasStore.ratio);
    this.afterUpdateManualCallback && this.afterUpdateManualCallback(this.canvasStore.ratio);
    this.afterUpdateCallback && this.afterUpdateCallback();
  }

  public changeAddingMode(value: boolean, canvasData: CanvasData) {
    this.isManualAddingMode = value;
    this.isManualDeletingMode = false;
    this.layoutStore.info(`Режим добавления дефектов ${value ? "включен" : "выключен"}`);
    this.onStartAdd(canvasData);
  }

  public changeDeletingMode(value: boolean, canvasData: CanvasData) {
    this.isManualAddingMode = false;
    this.isManualDeletingMode = value;
    this.layoutStore.info(`Режим удаления дефектов ${value ? "включен" : "выключен"}`);
    this.onDelete(canvasData);
  }

  public turnOffMode() {
    this.isManualAddingMode = false;
    this.isManualDeletingMode = false;
  }

  onStartAdd(canvasData: CanvasData) {
    const manualResult = this.manualResult;
    const c1 = canvasData.canvas;
    const ctx1 = c1?.getContext("2d");
    if (!ctx1 || !c1 || !manualResult) {
      return;
    }

    const getDefectType = () => this.defectTypesStore.types.filter(item => item.id === this.manualDefectType).pop();

    let origin: any | unknown = null;
    const drawSelection = (e: MouseEvent) => {
      if (!origin) {
        return;
      }
      const defectType = getDefectType();
      if (!defectType) {
        return this.layoutStore.error("Не выбран тип дефекта");
      }
      ctx1.strokeStyle = defectType.params.lineColor;
      ctx1.lineWidth = Number(defectType.params.lineSize);
      ctx1.beginPath();
      ctx1.rect(origin.x, origin.y, e.offsetX - origin.x, e.offsetY - origin.y);
      ctx1.stroke();
    };

    const render = async (e: MouseEvent, beforeUpdateCb: BeforeUpdateCallback = null) => {
      this.afterUpdateCallback = () => drawSelection(e);
      this.beforeUpdateCallback = beforeUpdateCb;
      this.afterUpdateManualCallback = null;
      await this.updateCanvas(canvasData);
    };

    const runIfManualAddingMode = (cb: () => void) => {
      this.isManualAddingMode && cb();
    };

    c1.onmousedown = (e: MouseEvent) => {
      runIfManualAddingMode(() => (origin = { x: e.offsetX, y: e.offsetY }));
    };
    c1.onmouseup = (e: MouseEvent) => {
      runIfManualAddingMode(async () => {
        if (!origin) return;
        const originCopy = origin;
        const beforeUpdateCb = (ratio: number) => {
          const points = [
            { x: originCopy.x, y: originCopy.y },
            { x: e.offsetX, y: originCopy.y },
            { x: e.offsetX, y: e.offsetY },
            { x: originCopy.x, y: e.offsetY },
          ].map(point => ({ x: Math.round(point.x / ratio), y: Math.round(point.y / ratio) }));
          const defectType = getDefectType();
          defectType && manualResult.data.manualContours.push({ points, typeId: defectType.id });
        };
        origin = null;
        await render(e, beforeUpdateCb);
      });
    };
    c1.onmousemove = (e: MouseEvent) => runIfManualAddingMode(async () => await render(e));
  }

  onDelete(canvasData: CanvasData) {
    const manualResult = this.manualResult;
    const c1 = canvasData.canvas;
    const ctx1 = c1?.getContext("2d");
    if (!ctx1 || !c1 || !manualResult) {
      return;
    }

    const drawSelection = (points: Point[], type: DefectType, ratio: number) => {
      ctx1.fillStyle = type.params.lineColor;
      const [minX, minY, maxX, maxY] = this.rectDefiner.define(points, ratio);
      ctx1.fillRect(minX, minY, maxX - minX, maxY - minY);
    };

    const mouseCrossContour = (e: MouseEvent, contour: Point[], ratio: number) => {
      const [minX, minY, maxX, maxY] = this.rectDefiner.define(contour, ratio);
      if (minX > e.offsetX || maxX < e.offsetX) {
        return false;
      }
      return minY <= e.offsetY && maxY >= e.offsetY;
    };

    const render = async (e: MouseEvent) => {
      this.afterUpdateCallback = null;
      this.beforeUpdateCallback = null;
      this.afterUpdateManualCallback = (ratio: number) => {
        this.manualResult?.data.manualContours.forEach(contour => {
          if (mouseCrossContour(e, contour.points, ratio)) {
            const type = this.defectTypesStore.types.filter(type => type.id === contour.typeId).pop();
            type && drawSelection(contour.points, type, ratio);
          }
        });
      };
      await this.updateCanvas(canvasData);
    };

    const runIfManualDeletingMode = (cb: () => void) => {
      this.isManualDeletingMode && cb();
    };

    c1.onclick = (e: MouseEvent) => {
      runIfManualDeletingMode(async () => {
        this.afterUpdateCallback = null;
        this.beforeUpdateCallback = null;
        this.afterUpdateManualCallback = (ratio: number) => {
          if (!this.manualResult) return;
          this.manualResult.data.manualContours = this.manualResult.data.manualContours.filter(contour => {
            return !mouseCrossContour(e, contour.points, ratio);
          });
        };
        await this.updateCanvas(canvasData);
      });
    };
    c1.onmousemove = (e: MouseEvent) => runIfManualDeletingMode(() => render(e));
  }
}
