<template>
  <div>
    <v-card class="mb-2 three-cols-card">
      <v-card-text>
        <Typography :size="16">Минимальные параметры HSV</Typography>
        <v-row>
          <v-col>
            <v-text-field
              label="minHsvHue"
              type="number"
              v-model="data.minHsvHue"
              hide-details="auto"
              :rules="[rules.required]"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              label="minHsvSaturation"
              type="number"
              v-model="data.minHsvSaturation"
              hide-details="auto"
              :rules="[rules.required]"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              label="minHsvBrightness"
              type="number"
              v-model="data.minHsvBrightness"
              hide-details="auto"
              :rules="[rules.required]"
            ></v-text-field>
          </v-col>
        </v-row>
        <Typography class="mt-3" :size="16">Максимальные параметры HSV</Typography>
        <v-row>
          <v-col>
            <v-text-field
              label="maxHsvHue"
              type="number"
              v-model="data.maxHsvHue"
              hide-details="auto"
              :rules="[rules.required]"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              label="maxHsvSaturation"
              type="number"
              v-model="data.maxHsvSaturation"
              hide-details="auto"
              :rules="[rules.required]"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              label="maxHsvBrightness"
              type="number"
              v-model="data.maxHsvBrightness"
              hide-details="auto"
              :rules="[rules.required]"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script lang="ts">
import type { HsvParams } from "/src/entitites/AlgorithmSettings/HsvParams.ts";
import Typography from "/src/primitives/Typography.vue";
import { RULES } from "/src/utils/ValidationRules.ts";
import { Component, Prop, Vue } from "vue-facing-decorator";

@Component({
  components: { Typography },
})
export default class HsvFields extends Vue {
  @Prop() readonly data!: HsvParams;
  rules = RULES;
}
</script>

<style lang="scss" scoped>
.three-cols-card {
  .v-input.v-text-field:not(.v-select) {
    max-width: 135px;
  }
}
</style>
