<template>
  <v-app>
    <NotificationsContainer />
    <router-view v-if="isPublicPath($route.path)" />
    <Layout v-else>
      <router-view />
    </Layout>
  </v-app>
</template>

<script lang="ts">
import { FRONT_ENDPOINTS } from "/src/endpoints";
import NotificationsContainer from "/src/modules/Layout/NotificationsContainer.vue";
import { Component, Vue } from "vue-facing-decorator";
import Layout from "./modules/Layout/index.vue";

@Component({
  components: {
    NotificationsContainer,
    Layout,
  },
})
export default class App extends Vue {
  isPublicPath(currentPath: string): boolean {
    const publicPaths = [FRONT_ENDPOINTS.LOGIN];
    return publicPaths.some(path => currentPath.includes(path));
  }
}
</script>

<style scoped></style>
