import { convertToArray } from "/src/services/RequestSubmitter/ErrorsConverter.ts";

export class BaseResponse<T = null> {
  public success!: boolean;
  public errors: types.Errors = {};
  public data: T | null = null;

  public get hasErrors(): boolean {
    if (!this.errors) {
      return false;
    }
    if (Array.isArray(this.errors)) {
      return !!this.errors.length;
    }
    return !!Object.values(this.errors).length;
  }

  public get errorsAsArray(): string[] {
    return convertToArray(this.errors);
  }
}

export class CreateEntityResponse extends BaseResponse {
  public id!: number;
}

export class UpdateEntityResponse extends BaseResponse {
  public id!: number;
}
