import { API_ENDPOINTS } from "/src/endpoints";
import { MenuItem } from "/src/entitites/MenuItem.ts";
import { TableData } from "/src/entitites/Table.ts";
import { menuDecoder } from "/src/services/Decoders/MenuDecoders.ts";
import { tableDecoder } from "/src/services/Decoders/TableDecoders.ts";
import { METHODS } from "/src/services/RequestSubmitter/Methods.ts";
import { RequestSubmitter } from "/src/services/RequestSubmitter/RequestSubmitter.ts";
import { Service } from "typedi";

@Service({ global: true })
export class MenuProvider extends RequestSubmitter {
  private menuRequest = this.createRequest(API_ENDPOINTS.MENU, METHODS.GET, tableDecoder(menuDecoder));

  public async getItems(): Promise<TableData<MenuItem>> {
    return await this.menuRequest();
  }
}
