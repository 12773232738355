<template>
  <div class="mb-2">
    <v-expansion-panels v-model="panel">
      <v-expansion-panel value="line">
        <v-expansion-panel-title>
          <span class="mr-2">Настройки линии</span>
          <span
            v-if="panel !== 'line' && settings.data.lineColor && settings.data.lineSize"
            class="line-example"
            :style="`border-bottom: ${settings.data.lineSize}px solid ${settings.data.lineColor};`"
          ></span>
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <v-row>
            <v-col>
              <div class="text-caption">Цвет линии</div>
              <v-color-picker v-model="settings.data.lineColor" :modes="['hexa']" width="100%" />
            </v-col>
            <v-col>
              <div class="text-caption">
                Размер линии <span v-if="settings.data.lineSize">({{ settings.data.lineSize }})</span>
              </div>
              <v-slider v-model="settings.data.lineSize" :min="1" :max="20" :step="1" />
              <p v-if="settings.data.lineColor && settings.data.lineSize">
                <strong>Иллюстрация: </strong>
                <DefectTypeView :settings="settings.data" />
              </p>
            </v-col>
          </v-row>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script lang="ts">
import { AlgorithmSettings } from "/src/entitites/AlgorithmSettings.ts";
import DefectTypeView from "/src/modules/Settings/DefectTypes/DefectTypeView.vue";
import { RULES } from "/src/utils/ValidationRules.ts";
import { Component, Prop, Vue } from "vue-facing-decorator";

@Component({
  components: { DefectTypeView },
})
export default class CommonFields extends Vue {
  @Prop() readonly settings!: AlgorithmSettings;
  rules = RULES;
  panel = null;
}
</script>

<style lang="scss" scoped></style>
