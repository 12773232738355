import { AlgorithmResult, ContoursAlgorithmResult, TypedContour } from "./AlgorithmResult.ts";

export class ManualAlgorithmData {
  public contoursCount!: number;
  public siblingResults: ContoursAlgorithmResult[] = [];
  public manualContours: TypedContour[] = [];
  public success = true;
  public errors: string[] = [];
}

export class ManualAlgorithmResult extends AlgorithmResult {
  public data: ManualAlgorithmData = new ManualAlgorithmData();
}
