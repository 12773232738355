<template>
  <v-card>
    <v-progress-linear :active="store.loader.isLoading" :indeterminate="true" :absolute="true" bottom />
    <v-card-title>Роли пользователей</v-card-title>
    <v-card-text>
      <v-btn class="mb-4" color="primary" @click="openForm">Добавить</v-btn>
      <v-data-table-server
        :items-per-page="webQueryStore.perPage"
        :items-per-page-options="webQueryStore.itemsPerPageOptions"
        :headers="headers"
        :items-length="store.totalItems"
        :items="items"
        :loading="store.loader.isLoading"
        :sort-by="webQueryStore.sortBy"
        v-bind:page="webQueryStore.currentPage"
        class="elevation-1"
        items-per-page-text="Элементов на странице"
        no-data-text="Роли отсутствуют"
        @update:options="updateOptions"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <DropdownMenu :actions="actions" @action="(action: any) => handleAction(action, item.raw.item)" />
        </template>
        <template v-slot:[`item.permissions`]="{ item }">
          <p v-for="(permission, key) in item.columns.permissions" :key="key">{{ permission }}</p>
        </template>
      </v-data-table-server>
      <UserRoleFormModal ref="formModal" @change="reload" />
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import { DropdownAction } from "/src/entitites/Dropdown.ts";
import { UserRole } from "/src/entitites/User.ts";
import UserRoleFormModal from "/src/modules/Settings/UserRoles/UserRoleFormModal.vue";
import DropdownMenu from "/src/primitives/DropdownMenu.vue";
import { UrlsProvider } from "/src/services/UrlsProvider.ts";
import { LayoutStore } from "/src/store/LayoutStore.ts";
import { UserRolesStore } from "/src/store/UserRolesStore.ts";
import { WebQueryStore } from "/src/store/WebQueryStore.ts";
import { clone } from "ramda";
import { Container } from "typedi";
import { Component, Ref, Vue } from "vue-facing-decorator";

@Component({
  components: { UserRoleFormModal, DropdownMenu },
})
export default class UserRolesList extends Vue {
  @Ref() readonly formModal!: UserRoleFormModal;
  private layoutStore: LayoutStore = Container.get(LayoutStore);
  store = Container.get(UserRolesStore);
  webQueryStore = Container.get(WebQueryStore);
  headers: any[] = [
    { title: "id", key: "id", sortable: true },
    { title: "Название", key: "title", sortable: true },
    { title: "Код", key: "name", sortable: true },
    { title: "Разрешения", key: "permissions", sortable: false },
    { title: "", key: "actions", sortable: false },
  ];
  urlsProvider = Container.get(UrlsProvider);
  actions: DropdownAction[] = [
    { title: "Изменить", code: "edit", icon: "mdi-pencil" },
    {
      title: "Удалить",
      code: "delete",
      icon: "mdi-trash-can-outline",
      confirm: {
        title: "Подтверждение удаления",
        message: "Вы действительно хотите удалить запись?",
        btnApprove: "Удалить",
      },
    },
  ];
  private tableKey = "UserRolesList";
  items: any[] = [];

  protected async created() {
    await this.webQueryStore.load(this.tableKey);
    await this.reload();
  }

  async reload() {
    await this.store.loadItems({
      tableKey: this.tableKey,
    });
    this.items = await Promise.all(
      this.store.items.map(async item => {
        return {
          id: item.id,
          name: item.name,
          title: item.title,
          item: item,
          permissions: await this.formatPermissions(item),
        };
      }),
    );
  }

  private async formatPermissions(role: UserRole) {
    const getTitle = async (id: string) => (await this.store.getPermission(id))?.title;
    return Promise.all(role.permissionIds.map(getTitle).filter(permission => !!permission));
  }

  async updateOptions(options: any) {
    if (await this.webQueryStore.updateOptions(options)) {
      await this.reload();
    }
  }

  handleAction(action: DropdownAction, user: UserRole) {
    switch (action.code) {
      case "edit":
        return this.openForm(user.id);
      case "delete":
        return this.delete(user.id);
    }
  }

  openForm(id: number | null) {
    let role;
    if (id) {
      role = this.store.items.filter(type => type.id === id).pop();
      role && (role = Object.assign({}, role));
    }
    !role && (role = new UserRole());
    this.formModal.open(clone(role));
  }

  async delete(id: number) {
    if (!(await this.store.delete(id))) {
      this.store.errors.forEach(error => this.layoutStore.error(error));
    } else {
      this.layoutStore.success("Роль удалена");
    }
    await this.reload();
  }
}
</script>

<style lang="scss" scoped></style>
