import { EllipseAreaRatioParams } from "/src/entitites/AlgorithmSettings/EllipseAreaRatioParams.ts";
import { HsvParams } from "/src/entitites/AlgorithmSettings/HsvParams.ts";
import { IncludesContourParams } from "/src/entitites/AlgorithmSettings/IncludesContourParams.ts";
import { SquareParams } from "/src/entitites/AlgorithmSettings/SquareParams.ts";
import { WidthHeightRatioParams } from "/src/entitites/AlgorithmSettings/WidthHeightRatioParams.ts";
import { Style } from "/src/entitites/PointsContour.ts";

export class AlgorithmSettings {
  public id!: number | null;
  public code!: string;
  public title!: string;
  public algorithmTitle!: string;
  public data: CommonSettingsData = new CommonSettingsData();
  public fileId!: number | null;
}

export class AlgorithmSelectionType {
  public id!: string;
  public title!: string;
}

export class AlgorithmConditionType {
  public id!: string;
  public title!: string;
}

export class CommonSettingsData extends Style {}

export class DetectDefectsByThroughHolesSettingsData extends CommonSettingsData implements HsvParams, SquareParams {
  public selectionType!: string;
  public areaLimitSettingsId!: number;
  public minHsvHue!: number;
  public minHsvSaturation!: number;
  public minHsvBrightness!: number;
  public maxHsvHue!: number;
  public maxHsvSaturation!: number;
  public maxHsvBrightness!: number;
  public squareLess!: number | null;
  public squareLessOrEqual!: number | null;
  public squareMore!: number | null;
  public squareMoreOrEqual!: number | null;
}

export class DetectDefectsByBrightnessSettingsData
  extends CommonSettingsData
  implements WidthHeightRatioParams, SquareParams
{
  public selectionType!: string;
  public areaLimitSettingsId!: number;
  public inRangeMaskCoefficient!: number;
  public widthHeightRatioLess!: number | null;
  public widthHeightRatioLessOrEqual!: number | null;
  public widthHeightRatioMore!: number | null;
  public widthHeightRatioMoreOrEqual!: number | null;
  public squareLess!: number | null;
  public squareLessOrEqual!: number | null;
  public squareMore!: number | null;
  public squareMoreOrEqual!: number | null;
}

export class DetectDefectsByKnotsSettingsData extends CommonSettingsData implements HsvParams, EllipseAreaRatioParams {
  public selectionType!: string;
  public areaLimitSettingsId!: number;
  public minHsvHue!: number;
  public minHsvSaturation!: number;
  public minHsvBrightness!: number;
  public maxHsvHue!: number;
  public maxHsvSaturation!: number;
  public maxHsvBrightness!: number;
  public ellipseAreaRatioLess!: number;
  public ellipseAreaRatioLessOrEqual!: number;
  public ellipseAreaRatioMore!: number;
  public ellipseAreaRatioMoreOrEqual!: number;
}

export class CumulativeResultsSettingsData extends CommonSettingsData {
  public siblingSettingsIds!: number[];
}

export class ManualResultsSettingsData extends CommonSettingsData {
  public siblingSettingsIds!: number[];
}

export class DetectWorkingAreaSettingsData extends CommonSettingsData {
  public sourceWidth!: number;
  public sourceHeight!: number;
  public targetWidth!: number;
  public targetHeight!: number;
}

export class DefineDefectTypeCondition {
  public conditionId = "";
  public params: SquareParams | EllipseAreaRatioParams | WidthHeightRatioParams | IncludesContourParams = {};
}

export class DefineDefectTypesConfiguration {
  public defectTypeId: string | number = "";
  public conditions: DefineDefectTypeCondition[] = [];
  public orderId = 0;
}

export class DefineDefectTypesSettingsData extends CommonSettingsData {
  public sourceSettingsIds!: number[];
  public typeConfigurations: DefineDefectTypesConfiguration[] = [];
}

export class DetectDefectsByBrightnessSettings extends AlgorithmSettings {
  public declare data: DetectDefectsByBrightnessSettingsData;
}

export class DetectDefectsByThroughHolesSettings extends AlgorithmSettings {
  public declare data: DetectDefectsByThroughHolesSettingsData;
}

export class DetectDefectsByKnotsSettings extends AlgorithmSettings {
  public declare data: DetectDefectsByKnotsSettingsData;
}

export class CumulativeResultsSettings extends AlgorithmSettings {
  public declare data: CumulativeResultsSettingsData;
}

export class ManualResultsSettings extends AlgorithmSettings {
  public declare data: ManualResultsSettingsData;
}

export class DetectWorkingAreaSettings extends AlgorithmSettings {
  public declare data: DetectWorkingAreaSettingsData;
}

export class DefineDefectTypesSettings extends AlgorithmSettings {
  public declare data: DefineDefectTypesSettingsData;
}
