<template>
  <div>
    <ContoursCanvas v-if="results.length" :file="file" :results="results">
      <template v-slot:after-result>
        <slot name="after-result" />
      </template>
    </ContoursCanvas>
    <ManualCanvas v-if="manualResult" :file="file" :manual-result="manualResult" :manual-buttons="true">
      <template v-slot:after-result>
        <slot name="after-result" />
      </template>
    </ManualCanvas>
  </div>
</template>

<script lang="ts">
import { ContoursAlgorithmResult } from "/src/entitites/AlgorithmResult.ts";
import { ManualAlgorithmResult } from "/src/entitites/ManualAlgorithmResult.ts";
import { UploadedFile } from "/src/entitites/UploadedFile.ts";
import AlgorithmIndividualSettings from "/src/modules/Files/AlgorithmResults/AlgorithmIndividualSettings.vue";
import ContoursCanvas from "./ContoursCanvas.vue";
import ManualCanvas from "./ManualCanvas.vue";
import { Component, Prop, Vue } from "vue-facing-decorator";

@Component({
  components: { AlgorithmIndividualSettings, ContoursCanvas, ManualCanvas },
})
export default class Canvas extends Vue {
  @Prop() file!: UploadedFile;
  @Prop({ default: () => [] }) results!: ContoursAlgorithmResult[];
  @Prop({ default: null }) manualResult!: ManualAlgorithmResult | null;
  @Prop({ default: false }) manualButtons!: boolean;
  // private uniqId: string = Math.random().toString(36).substring(7);
}
</script>

<style lang="scss" scoped></style>
