import { defectsComparisonUrls } from "/src/modules/DefectsComparison";
import { filesUrls } from "/src/modules/Files";
import { loginUrls } from "/src/modules/Login";
import { packagesUrls } from "/src/modules/Packages";
import { Service } from "typedi";

@Service({ global: true })
export class UrlsProvider {
  public readonly files = filesUrls;
  public readonly packages = packagesUrls;
  public readonly defectsComparison = defectsComparisonUrls;
  public readonly loginUrls = loginUrls;
}
