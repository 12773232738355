import { WebQuery } from "/src/entitites/ItemList.ts";
import { Loader } from "/src/entitites/Loader.ts";
import { WebQueryProvider } from "/src/services/WebQueryProvider.ts";
import { Container, Service } from "typedi";
import { action, observable } from "mobx";

@Service({ transient: true })
export class WebQueryStore {
  @observable public loader = new Loader();
  @observable query: WebQuery | null = null;
  private tableKey = "";
  private provider = Container.get(WebQueryProvider);

  @action.bound
  public async load(tableKey: string) {
    this.tableKey = tableKey;
    this.loader.start();
    const result = await this.provider.fetch(tableKey);
    this.loader.stop();
    this.query = result.data;
  }

  @action.bound
  private async save() {
    this.loader.start();
    await this.provider.save(this.tableKey, this.query);
    this.loader.stop();
  }

  public get perPage() {
    return this.query?.pagination.perPage || 0;
  }

  @action.bound
  public async updateOptions(options: any) {
    if (!this.query || this.loader.isLoading) {
      return false;
    }
    const { itemsPerPage, page, sortBy } = options;
    const sort = (sortBy || []).pop() || {};
    const query = {
      pagination: { perPage: itemsPerPage, currentPage: page },
      sort: { sort: sort.key, order: sort.order },
    };
    if (this.isEqual(query)) {
      return false;
    }
    this.query.sort.sort = query.sort.sort;
    this.query.sort.order = query.sort.order;
    this.query.pagination.perPage = query.pagination.perPage;
    this.query.pagination.currentPage = query.pagination.currentPage;
    await this.save();
    return true;
  }

  private isEqual(query: any) {
    return ![
      this.query?.sort.sort === query.sort.sort,
      this.query?.sort.order === query.sort.order,
      this.query?.pagination.perPage === query.pagination.perPage,
      this.query?.pagination.currentPage === query.pagination.currentPage,
    ].includes(false);
  }

  public get currentPage(): any {
    return this.query ? this.query.pagination.currentPage : undefined;
  }

  public get sortBy(): any {
    return this.query ? [{ key: this.query.sort.sort, order: this.query.sort.order }] : undefined;
  }

  public get itemsPerPageOptions(): any {
    return [
      { title: "10", value: 10 },
      { title: "25", value: 25 },
      { title: "50", value: 50 },
      { title: "100", value: 100 },
      { title: "500", value: 500 },
    ];
  }
}
