<template>
  <DetectDefectsManualView
    v-if="result.code === algorithmCodes.manualResults"
    :result="result"
    :file="file"
    :isLoading="isLoading"
    @settings:updated="onSettingsUpdated"
    @reload="reload"
  />
  <DetectBordersView
    v-else-if="result.code === algorithmCodes.detectBorders"
    :result="result"
    :file="file"
    :isLoading="isLoading"
    @settings:updated="onSettingsUpdated"
  />
  <DetectWorkingAreaView
    v-else-if="result.code === algorithmCodes.detectWorkingArea"
    :result="result"
    :file="file"
    :isLoading="isLoading"
    @settings:updated="onSettingsUpdated"
  />
  <DetectDefectsByBrightnessView
    v-else-if="result.code === algorithmCodes.detectDefectsByBrightness"
    :result="result"
    :file="file"
    :isLoading="isLoading"
    @settings:updated="onSettingsUpdated"
  />
  <DetectDefectsByHistogramView
    v-else-if="result.code === algorithmCodes.detectDefectsByHistogram"
    :result="result"
    :file="file"
    :isLoading="isLoading"
    @settings:updated="onSettingsUpdated"
  />
  <DetectDefectsByThroughHolesView
    v-else-if="result.code === algorithmCodes.detectDefectsByThroughHoles"
    :result="result"
    :file="file"
    :isLoading="isLoading"
    @settings:updated="onSettingsUpdated"
  />
  <CumulativeResultsView
    v-else-if="result.code === algorithmCodes.cumulativeResults"
    :result="result"
    :file="file"
    :isLoading="isLoading"
    @settings:updated="onSettingsUpdated"
  />
  <DetectDefectsByKnotsView
    v-else-if="result.code === algorithmCodes.detectDefectsByKnots"
    :result="result"
    :file="file"
    :isLoading="isLoading"
    @settings:updated="onSettingsUpdated"
  />
  <DefineDefectTypesView
    v-else-if="result.code === algorithmCodes.defineDefectTypes"
    :result="result"
    :file="file"
    :isLoading="isLoading"
    @settings:updated="onSettingsUpdated"
  />
</template>

<script lang="ts">
import { AlgorithmResult } from "/src/entitites/AlgorithmResult.ts";
import { UploadedFile } from "/src/entitites/UploadedFile.ts";
import { AlgorithmCodeEnum } from "/src/enums/AlgorithmCodeEnum.ts";
import CumulativeResultsView from "/src/modules/Files/AlgorithmResults/CumulativeResultsView.vue";
import DefineDefectTypesView from "/src/modules/Files/AlgorithmResults/DefineDefectTypesView.vue";
import DetectBordersView from "/src/modules/Files/AlgorithmResults/DetectBordersView.vue";
import DetectDefectsByBrightnessView from "/src/modules/Files/AlgorithmResults/DetectDefectsByBrightnessView.vue";
import DetectDefectsByHistogramView from "/src/modules/Files/AlgorithmResults/DetectDefectsByHistogramView.vue";
import DetectDefectsByKnotsView from "/src/modules/Files/AlgorithmResults/DetectDefectsByKnotsView.vue";
import DetectDefectsByThroughHolesView from "/src/modules/Files/AlgorithmResults/DetectDefectsByThroughHolesView.vue";
import DetectDefectsManualView from "/src/modules/Files/AlgorithmResults/DetectDefectsManualView.vue";
import DetectWorkingAreaView from "/src/modules/Files/AlgorithmResults/DetectWorkingAreaView.vue";
import { Component, Emit, Prop, Vue } from "vue-facing-decorator";

@Component({
  components: {
    CumulativeResultsView,
    DetectDefectsByThroughHolesView,
    DetectDefectsByHistogramView,
    DetectDefectsByBrightnessView,
    DetectDefectsByKnotsView,
    DetectDefectsManualView,
    DetectBordersView,
    DetectWorkingAreaView,
    DefineDefectTypesView,
  },
})
export default class AlgorithmResultView extends Vue {
  @Prop() file!: UploadedFile;
  @Prop() result!: AlgorithmResult;
  @Prop() isLoading!: boolean;
  algorithmCodes = AlgorithmCodeEnum;

  @Emit("settings:updated")
  onSettingsUpdated() {
    return;
  }

  @Emit("reload")
  reload() {
    return;
  }
}
</script>

<style lang="scss" scoped>
:deep(.v-card-subtitle) {
  margin-bottom: 12px;
}
</style>
