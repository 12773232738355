import { API_ENDPOINTS } from "/src/endpoints";
import { DetailData, TableData } from "/src/entitites/Table.ts";
import { detailDecoder, tableDecoder } from "/src/services/Decoders/TableDecoders.ts";
import { createRequestOptions } from "/src/services/RequestSubmitter/RequestOptions.ts";
import { UploadedFile } from "/src/entitites/UploadedFile";
import { BaseResponse } from "/src/entitites/ResponseEntity";
import { Service } from "typedi";
import { baseResponse } from "./Decoders/CommonDecoders";
import { fileDecoder } from "./Decoders/FilesDecoders";
import { METHODS } from "./RequestSubmitter/Methods";
import { RequestSubmitter } from "./RequestSubmitter/RequestSubmitter";

@Service({ global: true })
export class FilesProvider extends RequestSubmitter {
  private detailRequest = this.createRequest(API_ENDPOINTS.FILES_DETAIL, METHODS.GET, detailDecoder(fileDecoder));
  private listRequest = this.createRequest(API_ENDPOINTS.FILES_LIST, METHODS.GET, tableDecoder(fileDecoder));
  private uploadRequest = this.createRequest(API_ENDPOINTS.FILES_UPLOAD, METHODS.POST, baseResponse());
  private processItemRequest = this.createRequest(API_ENDPOINTS.FILES_PROCESS, METHODS.POST, baseResponse());

  public async getItems(params: any): Promise<TableData<UploadedFile>> {
    return await this.listRequest(createRequestOptions().setBody(params));
  }

  public async getItem(id: number): Promise<DetailData<UploadedFile, null>> {
    return await this.detailRequest(createRequestOptions().addUrlParam("id", id));
  }

  public async processItem(id: number): Promise<BaseResponse> {
    return await this.processItemRequest(createRequestOptions().addUrlParam("id", id));
  }

  public async upload(files: File[], progressReceiver?: (progress: number) => void): Promise<BaseResponse> {
    const body = new FormData();
    files.forEach(file => body.append("files[]", file));
    const options = createRequestOptions().setBody(body).setProgressReceiver(progressReceiver);
    return await this.uploadRequest(options);
  }
}
