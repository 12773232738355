<template>
  <v-card>
    <v-progress-linear :active="store.loader.isLoading" :indeterminate="true" :absolute="true" bottom />
    <v-card-title>Пользователи</v-card-title>
    <v-card-text>
      <v-btn class="mb-4" color="primary" @click="openForm">Добавить</v-btn>
      <v-data-table-server
        :items-per-page="webQueryStore.perPage"
        :items-per-page-options="webQueryStore.itemsPerPageOptions"
        :headers="headers"
        :items-length="store.totalItems"
        :items="items"
        :loading="store.loader.isLoading"
        :sort-by="webQueryStore.sortBy"
        v-bind:page="webQueryStore.currentPage"
        class="elevation-1"
        items-per-page-text="Элементов на странице"
        no-data-text="Пользователи отсутствуют"
        @update:options="updateOptions"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <DropdownMenu :actions="actions" @action="(action: any) => handleAction(action, item.raw.item)" />
        </template>
        <template v-slot:[`item.roles`]="{ item }">
          <p v-for="(role, key) in item.columns.roles" :key="key">{{ role }}</p>
        </template>
      </v-data-table-server>
      <UserFormModal ref="formModal" @change="reload" />
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import { DropdownAction } from "/src/entitites/Dropdown.ts";
import { User } from "/src/entitites/User.ts";
import UserFormModal from "/src/modules/Settings/Users/UserFormModal.vue";
import DropdownMenu from "/src/primitives/DropdownMenu.vue";
import { UrlsProvider } from "/src/services/UrlsProvider.ts";
import { LayoutStore } from "/src/store/LayoutStore.ts";
import { UserRolesStore } from "/src/store/UserRolesStore.ts";
import { UsersStore } from "/src/store/UsersStore.ts";
import { WebQueryStore } from "/src/store/WebQueryStore.ts";
import { clone } from "ramda";
import { Container } from "typedi";
import { Component, Ref, Vue } from "vue-facing-decorator";

@Component({
  components: { UserFormModal, DropdownMenu },
})
export default class UsersList extends Vue {
  @Ref() readonly formModal!: UserFormModal;
  private layoutStore: LayoutStore = Container.get(LayoutStore);
  store = Container.get(UsersStore);
  rolesStore = Container.get(UserRolesStore);
  webQueryStore = Container.get(WebQueryStore);
  headers: any[] = [
    { title: "id", key: "id", sortable: true },
    { title: "Логин", key: "name", sortable: true },
    { title: "Email", key: "email", sortable: true },
    { title: "Роли", key: "roles", sortable: false },
    { title: "", key: "actions", sortable: false },
  ];
  urlsProvider = Container.get(UrlsProvider);
  actions: DropdownAction[] = [
    { title: "Изменить", code: "edit", icon: "mdi-pencil" },
    {
      title: "Удалить",
      code: "delete",
      icon: "mdi-trash-can-outline",
      confirm: {
        title: "Подтверждение удаления",
        message: "Вы действительно хотите удалить запись?",
        btnApprove: "Удалить",
      },
    },
  ];
  private tableKey = "UsersList";
  items: any[] = [];

  protected async created() {
    await this.webQueryStore.load(this.tableKey);
    await this.reload();
  }

  async reload() {
    await this.store.loadItems({
      tableKey: this.tableKey,
    });
    if (!this.rolesStore.items.length) await this.rolesStore.loadItems({});
    this.items = await Promise.all(
      this.store.items.map(async item => {
        return {
          id: item.id,
          name: item.name,
          email: item.email,
          item: item,
          roles: await this.formatRoles(item),
        };
      }),
    );
  }

  private async formatRoles(user: User) {
    const getTitle = async (id: number) => (await this.rolesStore.getRole(id))?.title;
    return Promise.all(user.rolesIds.map(getTitle).filter(role => !!role));
  }

  async updateOptions(options: any) {
    if (await this.webQueryStore.updateOptions(options)) {
      await this.reload();
    }
  }

  handleAction(action: DropdownAction, user: User) {
    switch (action.code) {
      case "edit":
        return this.openForm(user.id);
      case "delete":
        return this.delete(user.id);
    }
  }

  openForm(id: number | null) {
    let user;
    if (id) {
      user = this.store.items.filter(type => type.id === id).pop();
      user && (user = Object.assign({}, user));
    }
    !user && (user = new User());
    this.formModal.open(clone(user));
  }

  async delete(id: number) {
    if (!(await this.store.delete(id))) {
      this.store.errors.forEach(error => this.layoutStore.error(error));
    } else {
      this.layoutStore.success("Пользователь удален");
    }
    await this.reload();
  }
}
</script>

<style lang="scss" scoped></style>
