import { Loader } from "/src/entitites/Loader";
import { ManualAlgorithmResult } from "/src/entitites/ManualAlgorithmResult.ts";
import { ManualAlgorithmResultsProvider } from "/src/services/ManualAlgorithmResultsProvider.ts";
import { Container, Service } from "typedi";
import { action, observable } from "mobx";

@Service({ global: true })
export class ManualAlgorithmResultsStore {
  @observable public loader = new Loader();
  @observable public actionsLoader = new Loader();
  @observable public errors: string[] = [];
  private provider = Container.get(ManualAlgorithmResultsProvider);

  @action.bound
  public async loadItem(id: number): Promise<ManualAlgorithmResult | null> {
    this.loader.start();
    const result = await this.provider.getItem(id);
    this.loader.stop();
    return result.data;
  }

  public async save(data: ManualAlgorithmResult) {
    this.loader.start();
    const result = await this.provider.save(data);
    this.loader.stop();
    return result;
  }

  @action.bound
  public async loadItems(params: any) {
    this.loader.start();
    const result = await this.provider.getItems(params);
    this.loader.stop();
    return result;
  }

  public async setInWork(id: number) {
    this.actionsLoader.start();
    const result = await this.provider.setInWork(id);
    this.actionsLoader.stop();
    return result;
  }

  @action.bound
  public clear() {
    this.errors = [];
  }
}
