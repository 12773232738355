import { AlgorithmSettings } from "/src/entitites/AlgorithmSettings.ts";
import { Point } from "/src/entitites/PointsContour.ts";
import { UploadedFile } from "/src/entitites/UploadedFile.ts";

export class AlgorithmResult {
  public id!: number;
  public code!: string;
  public settingsId!: number;
  public settings!: AlgorithmSettings | null;
  public data!: any;
  public individualSettingsId!: number | null;
  public individualSettings!: AlgorithmSettings | null;
  public file!: UploadedFile;
  public statusTitle!: string;
  public statusCode!: number;
}

export class ContoursAlgorithmData {
  public contoursCount!: number;
  public contoursPoints: Point[][] = [];
  public success!: boolean;
  public errors!: string[];
}

export class TypedContour {
  public points: Point[] = [];
  public typeId!: number;
}

export class TypedContoursAlgorithmData extends ContoursAlgorithmData {
  public typedContours: TypedContour[] = [];
}

export class CumulativeResultsData {
  public siblingResults!: ContoursAlgorithmResult[];
}

export class ContoursAlgorithmResult extends AlgorithmResult {
  public data: ContoursAlgorithmData = new ContoursAlgorithmData();
}

export class TypedContoursAlgorithmResult extends AlgorithmResult {
  public data: TypedContoursAlgorithmData = new TypedContoursAlgorithmData();
}

export class CumulativeResultsAlgorithmResult extends AlgorithmResult {
  public declare data: CumulativeResultsData;
}
