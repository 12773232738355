import { ContoursAlgorithmResult, TypedContoursAlgorithmData } from "/src/entitites/AlgorithmResult.ts";
import { CanvasData } from "/src/entitites/CanvasData.ts";
import { PointsContourProvider } from "/src/services/PointsContourProvider.ts";
import { CanvasStore, SetupOptions } from "/src/store/CanvasStore.ts";
import { Service, Container } from "typedi";

@Service({ transient: true })
export class ContoursCanvasStore {
  private canvasStore = Container.get(CanvasStore);
  private pointsContourProvider = Container.get(PointsContourProvider);
  private results: ContoursAlgorithmResult[] = [];

  public async setup(options: SetupOptions, results: ContoursAlgorithmResult[]) {
    this.results = results;
    await this.canvasStore.setup(options);
  }

  public async updateCanvas(canvasData: CanvasData) {
    this.canvasStore.clearContours();
    (await this.collectContours()).forEach(list => this.canvasStore.addContours(list));
    await this.canvasStore.updateCanvas(canvasData);
  }

  private async collectContours() {
    const list = this.results.map(async result => {
      const settings = result.individualSettings || result.settings;
      const contours = [...this.pointsContourProvider.processSimpleContours(result.data.contoursPoints, settings)];
      const typedContours = (result.data as TypedContoursAlgorithmData).typedContours;
      if (typedContours) {
        contours.push(...(await this.pointsContourProvider.processTypedContours(typedContours)));
      }
      return contours;
    });
    return await Promise.all(list);
  }
}
