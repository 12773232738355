<template>
  <v-card>
    <v-progress-linear :active="packagesStore.loader.isLoading" :indeterminate="true" :absolute="true" bottom />
    <v-card-title>Пакет {{ package ? package.file.name : "" }}</v-card-title>
    <v-card-text v-if="package">
      <v-btn prepend-icon="mdi-arrow-left" color="primary" :to="urlsProvider.packages.list()">К списку</v-btn>
      <v-table class="table-rounded">
        <tbody>
          <tr>
            <td class="text-left">id</td>
            <td class="text-left">{{ package.id }}</td>
          </tr>
          <tr>
            <td class="text-left">Загружен</td>
            <td class="text-left">{{ package.createdAt.toLocaleString() }}</td>
          </tr>
          <tr>
            <td class="text-left">Действия</td>
            <td class="text-left">
              <TooltipBtn
                btn-variant="text"
                btn-class="text-blue"
                icon="mdi-download"
                :href="urlsProvider.files.download(package.file.hash)"
                tooltip="Скачать пакет"
              />
            </td>
          </tr>
        </tbody>
      </v-table>
      <v-container>
        <v-row no-gutters class="d-flex justify-start">
          <v-col class="ma-2" v-for="file in package.nestedFiles" :key="file.id">
            <v-sheet height="100%">
              <v-card>
                <v-card-text>
                  <v-img
                    v-if="isImage(file.name)"
                    :src="urlsProvider.files.download(file.hash)"
                    :alt="file.name"
                    :class="{ loading: !imageLoaded[file.id] }"
                    @load="imageLoaded[file.id] = true"
                  />
                  <v-overlay
                    v-if="!imageLoaded[file.id]"
                    :model-value="true"
                    contained
                    persistent
                    class="align-center justify-center"
                  >
                    <v-progress-circular indeterminate :size="70" :width="7" color="primary" />
                  </v-overlay>
                </v-card-text>
                <v-card-title>
                  <Typography size="18">
                    <TooltipBadge
                      v-if="file.algorithmResultsCount"
                      :badge-content="file.algorithmResultsCount"
                      :tooltip="`Есть результаты выполнения алгоритмов`"
                    >
                      <template v-slot:content>{{ file.name }}</template>
                    </TooltipBadge>
                    <span v-else> {{ file.name }} </span>
                  </Typography>
                </v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <TooltipBtn
                    btn-variant="text"
                    btn-class="text-blue"
                    icon="mdi-download"
                    :href="urlsProvider.files.download(file.hash)"
                    tooltip="Скачать файл"
                  />
                  <TooltipBtn
                    btn-variant="text"
                    btn-class="text-blue"
                    icon="mdi-magnify"
                    :to="urlsProvider.files.detail(file.id)"
                    tooltip="К деталям файла"
                  />
                </v-card-actions>
              </v-card>
            </v-sheet>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import { FilePackage } from "/src/entitites/FilePackage.ts";
import TooltipBadge from "/src/primitives/TooltipBadge.vue";
import TooltipBtn from "/src/primitives/TooltipBtn.vue";
import Typography from "/src/primitives/Typography.vue";
import { UrlsProvider } from "/src/services/UrlsProvider.ts";
import { PackagesStore } from "/src/store/PackagesStore.ts";
import { Container } from "typedi";
import { Component, Prop, Vue } from "vue-facing-decorator";

@Component({
  components: { Typography, TooltipBadge, TooltipBtn },
})
export default class PackageDetail extends Vue {
  @Prop() private id!: number;
  urlsProvider = Container.get(UrlsProvider);
  packagesStore = Container.get(PackagesStore);
  imageExtensions = ["jpeg", "jpg", "bmp"];
  package: FilePackage | null = null;
  imageLoaded: { [propertyName: number]: boolean } = {};

  protected async created() {
    const packageItem = await this.packagesStore.loadItem(this.id);
    packageItem && (this.package = packageItem);
  }

  isImage(fileName: string) {
    const extension = fileName.split(".").pop();
    return extension && this.imageExtensions.includes(extension);
  }
}
</script>

<style lang="scss" scoped>
.v-img {
  box-shadow: 0 0 0 1px lightgray;
  &.loading {
    min-height: 350px;
  }
}
.v-col {
  max-width: 370px;
}
</style>
