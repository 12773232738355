<template>
  <v-card class="root">
    <v-layout class="root">
      <Navigation />
      <v-main>
        <UserToolbar />
        <div class="pa-6">
          <slot />
        </div>
      </v-main>
    </v-layout>
  </v-card>
</template>

<script lang="ts">
import UserToolbar from "/src/modules/Layout/UserToolbar.vue";
import Notifier from "./Notifier.vue";
import Navigation from "./Navigation/Navigation.vue";
import { Component, Vue } from "vue-facing-decorator";

@Component({
  components: { UserToolbar, Navigation, Notifier },
})
export default class Layout extends Vue {}
</script>

<style lang="scss" scoped>
.v-card.root {
  margin: 5px;

  .v-layout.root {
    min-height: calc(100vh - 10px);
  }
}
</style>
