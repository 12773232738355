export function required(v: string): boolean | string {
  return String(v) !== "" || "Поле обязательно для заполнения";
}

export function decimalNumber(v: string): boolean | string {
  return !v || /^\d+[,.]\d+|\d+$/.test(v) || "Некорректное число";
}

export const RULES = {
  required,
  decimalNumber,
};
