import { Point, Style } from "/src/entitites/PointsContour.ts";
import { Service } from "typedi";

@Service({ global: true })
export class CanvasDrawer {
  public drawLine(ctx: CanvasRenderingContext2D, point1: Point, point2: Point, style: Style, ratio = 1) {
    ctx.beginPath();
    ctx.strokeStyle = style.lineColor;
    ctx.lineWidth = Number(style.lineSize);
    ctx.moveTo(point1.x * ratio, point1.y * ratio);
    ctx.lineTo(point2.x * ratio, point2.y * ratio);
    ctx.stroke();
    ctx.closePath();
  }

  public drawContour(ctx: CanvasRenderingContext2D, ratio: number, points: Point[], style: Style) {
    const first = points.shift();
    if (!first) {
      return;
    }
    first && points.push(first);
    let current = first;
    points.forEach(point => {
      this.drawLine(ctx, current, point, style, ratio);
      current = point;
    });
  }
}
