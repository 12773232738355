<template>
  <v-card>
    <v-card-text>
      <v-select
        v-model="settings.data.sourceSettingsIds"
        label="Источники контуров"
        item-title="title"
        item-value="id"
        multiple
        chips
        closableChips
        clearable
        :items="algorithmSettingsList"
        :rules="[rules.required]"
      />
      <v-chip v-if="!settings.data.typeConfigurations.length" class="mb-2 mt-2" color="red" label>
        <v-icon start icon="mdi-alert-outline"></v-icon>
        Конфигурации типов дефектов не добавлены
      </v-chip>
      <v-card
        v-for="(configuration, configurationKey) in settings.data.typeConfigurations"
        :key="configurationKey"
        class="mb-2 border-s-xl"
      >
        <v-card-text>
          <v-row>
            <v-col cols="11">
              <v-row>
                <v-col>
                  <v-select
                    v-model="configuration.defectTypeId"
                    :label="`Тип дефекта №${configurationKey + 1}`"
                    item-title="title"
                    item-value="id"
                    :items="defectTypesStore.types"
                    :rules="[rules.required]"
                  >
                    <template v-slot:append-inner v-if="getDefectType(configuration.defectTypeId)">
                      <div class="mr-2">Иллюстрация:</div>
                      <DefectTypeView :settings="getDefectType(configuration.defectTypeId)?.params as any" />
                    </template>
                  </v-select>
                  <v-chip v-if="!configuration.defectTypeId" class="mb-2 mt-2" color="red" label>
                    <v-icon start icon="mdi-alert-outline"></v-icon>
                    Тип дефекта не выбран
                  </v-chip>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <Typography :size="16">Условия</Typography>
                  <v-chip v-if="!configuration.conditions?.length" class="ml-2" color="red" label>
                    <v-icon start icon="mdi-alert-outline"></v-icon>
                    Условия не добавлены
                  </v-chip>
                  <v-card
                    v-for="(condition, conditionKey) in configuration.conditions"
                    :key="conditionKey"
                    class="mb-2 border-s-xl"
                  >
                    <v-card-title>
                      <v-row>
                        <v-col cols="10">
                          <v-select
                            v-model="condition.conditionId"
                            :label="`Условие №${conditionKey + 1}`"
                            item-title="title"
                            item-value="id"
                            :items="store.conditionTypes"
                            :rules="[rules.required]"
                          />
                          <v-chip v-if="!condition.conditionId" class="mb-2 mt-2" color="red" label>
                            <v-icon start icon="mdi-alert-outline"></v-icon>
                            Условие не выбрано
                          </v-chip>
                        </v-col>
                        <v-col cols="2" class="text-right">
                          <v-btn
                            density="comfortable"
                            icon="mdi-close"
                            @click="deleteCondition(configuration, conditionKey)"
                          />
                        </v-col>
                      </v-row>
                    </v-card-title>
                    <v-card-text>
                      <SquareParamsFields
                        v-if="condition.conditionId === conditionTypes.squareCondition"
                        :data="condition.params as any"
                      />
                      <EllipseAreaRatioFields
                        v-if="condition.conditionId === conditionTypes.ellipseAreaRatioCondition"
                        :data="condition.params as any"
                      />
                      <WidthHeightRatioFields
                        v-if="condition.conditionId === conditionTypes.widthHeightRatioCondition"
                        :data="condition.params as any"
                      />
                      <IncludesContourFields
                        v-if="condition.conditionId === conditionTypes.includesContourCondition"
                        :data="condition.params as any"
                      />
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-btn
                    variant="elevated"
                    prepend-icon="mdi-plus"
                    color="primary"
                    @click="addCondition(configuration)"
                  >
                    Добавить условие
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="1" class="text-right">
              <v-row>
                <v-col>
                  <v-btn density="comfortable" icon="mdi-close" @click="deleteConfiguration(configurationKey)" />
                </v-col>
              </v-row>
              <v-row v-if="configurationKey">
                <v-col>
                  <v-btn
                    density="comfortable"
                    icon="mdi-arrow-up-bold-outline"
                    @click="upConfigurationSort(configurationKey)"
                  />
                </v-col>
              </v-row>
              <v-row v-if="configurationKey < settings.data.typeConfigurations.length - 1">
                <v-col>
                  <v-btn
                    density="comfortable"
                    icon="mdi-arrow-down-bold-outline"
                    @click="downConfigurationSort(configurationKey)"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-row class="mt-4">
        <v-col>
          <v-btn variant="elevated" prepend-icon="mdi-plus" color="primary" @click="addConfiguration">
            Добавить настройку типа дефекта
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import {
  DefineDefectTypeCondition,
  DefineDefectTypesConfiguration,
  DefineDefectTypesSettings,
} from "/src/entitites/AlgorithmSettings.ts";
import { CommonSettingsCodes } from "/src/enums/AlgorithmCodeEnum.ts";
import { AlgorithmConditionTypeEnum } from "/src/enums/AlgorithmConditionTypeEnum.ts";
import EllipseAreaRatioFields from "/src/modules/Settings/Algorithms/Fields/EllipseAreaRatioFields.vue";
import IncludesContourFields from "/src/modules/Settings/Algorithms/Fields/IncludesContourFields.vue";
import SquareParamsFields from "/src/modules/Settings/Algorithms/Fields/SquareParamsFields.vue";
import WidthHeightRatioFields from "/src/modules/Settings/Algorithms/Fields/WidthHeightRatioFields.vue";
import DefectTypeView from "/src/modules/Settings/DefectTypes/DefectTypeView.vue";
import Typography from "/src/primitives/Typography.vue";
import { AlgorithmSettingsStore } from "/src/store/AlgorithmSettingsStore.ts";
import { DefectTypesStore } from "/src/store/DefectTypesStore.ts";
import { RULES } from "/src/utils/ValidationRules.ts";
import { Container } from "typedi";
import { Component, Prop, Vue } from "vue-facing-decorator";

@Component({
  components: {
    IncludesContourFields,
    DefectTypeView,
    WidthHeightRatioFields,
    EllipseAreaRatioFields,
    SquareParamsFields,
    Typography,
  },
})
export default class DefineDefectTypesFields extends Vue {
  @Prop() readonly settings!: DefineDefectTypesSettings;
  rules = RULES;
  store = Container.get(AlgorithmSettingsStore);
  defectTypesStore = Container.get(DefectTypesStore);
  conditionTypes = AlgorithmConditionTypeEnum;

  async created() {
    await this.store.loadItems();
    if (!this.defectTypesStore.types.length && !this.defectTypesStore.loader.isLoading) {
      await this.defectTypesStore.loadItems();
    }
    await this.store.loadConditionTypes();
  }

  get algorithmSettingsList() {
    return this.store.items
      .filter(item => CommonSettingsCodes.includes(item.code))
      .map(item => ({
        title: `#${item.id} ${item.title} (${!item.fileId ? "общий" : "для отдельного файла"})`,
        id: item.id,
      }));
  }

  getDefectType(typeId: any) {
    return typeId ? this.defectTypesStore.types.filter(item => item.id === typeId).pop() : null;
  }

  addConfiguration() {
    this.settings.data.typeConfigurations.push(new DefineDefectTypesConfiguration());
    this.onChangeTypeConfigurations();
  }

  deleteConfiguration(key: number) {
    this.settings.data.typeConfigurations.splice(key, 1);
    this.onChangeTypeConfigurations();
  }

  upConfigurationSort(key: number) {
    const items = this.settings.data.typeConfigurations.splice(key, 1);
    this.settings.data.typeConfigurations.splice(key - 1, 0, ...items);
    this.onChangeTypeConfigurations();
  }

  downConfigurationSort(key: number) {
    const items = this.settings.data.typeConfigurations.splice(key, 1);
    this.settings.data.typeConfigurations.splice(key + 1, 0, ...items);
    this.onChangeTypeConfigurations();
  }

  onChangeTypeConfigurations() {
    this.settings.data.typeConfigurations.forEach((item, index) => (item.orderId = index));
  }

  addCondition(configuration: DefineDefectTypesConfiguration) {
    configuration.conditions.push(new DefineDefectTypeCondition());
  }

  deleteCondition(configuration: DefineDefectTypesConfiguration, key: number) {
    configuration.conditions.splice(key, 1);
  }
}
</script>

<style lang="scss" scoped>
.two-cols-card {
  .v-input.v-text-field:not(.v-select) {
    max-width: 200px;
  }
}
:deep(.v-field__input) {
  word-break: break-word;
}
</style>
