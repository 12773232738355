<template>
  <div>
    <CommonFields v-if="showCommonFields" :settings="settings" />
    <DetectDefectsByBrightnessFields v-if="code === algorithmCodes.detectDefectsByBrightness" :settings="settings" />
    <DetectDefectsByThroughHolesFields
      v-if="code === algorithmCodes.detectDefectsByThroughHoles"
      :settings="settings"
    />
    <CumulativeResultsFields v-if="code === algorithmCodes.cumulativeResults" :settings="settings" />
    <ManualResultsFields v-if="code === algorithmCodes.manualResults" :settings="settings" />
    <DetectWorkingAreaFields v-if="code === algorithmCodes.detectWorkingArea" :settings="settings" />
    <DetectDefectsByKnotsFields v-if="code === algorithmCodes.detectDefectsByKnots" :settings="settings" />
    <DefineDefectTypesFields v-if="code === algorithmCodes.defineDefectTypes" :settings="settings" />
  </div>
</template>

<script lang="ts">
import { AlgorithmSettings } from "/src/entitites/AlgorithmSettings.ts";
import { AlgorithmCodeEnum, CommonSettingsCodes } from "/src/enums/AlgorithmCodeEnum.ts";
import DefineDefectTypesFields from "/src/modules/Settings/Algorithms/Fields/DefineDefectTypesFields.vue";
import { Component, Prop, Vue } from "vue-facing-decorator";
import CommonFields from "./CommonFields.vue";
import CumulativeResultsFields from "./CumulativeResultsFields.vue";
import DetectDefectsByBrightnessFields from "./DetectDefectsByBrightnessFields.vue";
import DetectDefectsByKnotsFields from "./DetectDefectsByKnotsFields.vue";
import DetectDefectsByThroughHolesFields from "./DetectDefectsByThroughHolesFields.vue";
import DetectWorkingAreaFields from "./DetectWorkingAreaFields.vue";
import ManualResultsFields from "./ManualResultsFields.vue";

@Component({
  components: {
    CumulativeResultsFields,
    DetectDefectsByThroughHolesFields,
    DetectDefectsByBrightnessFields,
    DetectDefectsByKnotsFields,
    CommonFields,
    ManualResultsFields,
    DetectWorkingAreaFields,
    DefineDefectTypesFields,
  },
})
export default class AlgorithmSettingsFields extends Vue {
  @Prop() readonly settings!: AlgorithmSettings | any;
  @Prop() readonly code!: string;
  algorithmCodes = AlgorithmCodeEnum;

  get showCommonFields() {
    return this.code && CommonSettingsCodes.includes(this.code);
  }
}
</script>

<style lang="scss" scoped></style>
