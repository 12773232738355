<template>
  <v-card>
    <v-progress-linear :active="store.loader.isLoading" :indeterminate="true" :absolute="true" bottom />
    <v-card-title>Настройка типов дефектов</v-card-title>
    <v-card-text>
      <v-btn class="mb-4" color="primary" @click="openForm">Добавить</v-btn>
      <v-table class="table-rounded" item-key="id" v-if="store.types.length">
        <thead>
          <tr>
            <th class="text-left">id</th>
            <th class="text-left">Название</th>
            <th class="text-left">Код</th>
            <th class="text-left">Параметры</th>
            <th class="text-left">Действия</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(type, key) in store.types" :key="key">
            <td>{{ type.id }}</td>
            <td>{{ type.title }}</td>
            <td>{{ type.code }}</td>
            <td>
              <div v-if="type.params.lineColor && type.params.lineSize">
                <p><strong>Цвет: </strong>{{ type.params.lineColor }}</p>
                <p><strong>Толщина: </strong>{{ type.params.lineSize }}</p>
                <p>
                  <strong>Иллюстрация: </strong>
                  <DefectTypeView :settings="type.params" />
                </p>
              </div>
            </td>
            <td>
              <DropdownMenu :actions="actions" @action="(action: any) => handleAction(action, type)" />
            </td>
          </tr>
        </tbody>
      </v-table>
      <div v-else>
        <br />
        <Typography :size="16"> Типы отсутствуют </Typography>
      </div>
      <DefectTypeFormModal ref="formModal" @change="reload" />
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import { DefectType } from "/src/entitites/DefectType.ts";
import { DropdownAction } from "/src/entitites/Dropdown.ts";
import DefectTypeView from "/src/modules/Settings/DefectTypes/DefectTypeView.vue";
import DefectTypeFormModal from "./DefectTypeFormModal.vue";
import DropdownMenu from "/src/primitives/DropdownMenu.vue";
import Typography from "/src/primitives/Typography.vue";
import { DefectTypesStore } from "/src/store/DefectTypesStore.ts";
import { LayoutStore } from "/src/store/LayoutStore.ts";
import { clone } from "ramda";
import { Container } from "typedi";
import { Component, Ref, Vue } from "vue-facing-decorator";

@Component({
  components: { DefectTypeView, Typography, DropdownMenu, DefectTypeFormModal },
})
export default class DefectTypesList extends Vue {
  @Ref() readonly formModal!: DefectTypeFormModal;
  private layoutStore: LayoutStore = Container.get(LayoutStore);
  store = Container.get(DefectTypesStore);
  actions: DropdownAction[] = [
    { title: "Изменить", code: "edit", icon: "mdi-pencil" },
    {
      title: "Удалить",
      code: "delete",
      icon: "mdi-trash-can-outline",
      confirm: {
        title: "Подтверждение удаления",
        message: "Вы действительно хотите удалить запись?",
        btnApprove: "Удалить",
      },
    },
  ];

  protected async created() {
    await this.reload();
  }

  async reload() {
    await this.store.loadItems();
  }

  openForm(id: number | null) {
    let item;
    if (id) {
      item = this.store.types.filter(type => type.id === id).pop();
      item && (item = clone(item));
    }
    !item && (item = new DefectType());
    this.formModal.open(item);
  }

  async delete(id: number) {
    await this.store.delete(id);
    this.layoutStore.success("Запись удалена");
    await this.reload();
  }

  handleAction(action: DropdownAction, type: DefectType) {
    switch (action.code) {
      case "edit":
        return this.openForm(type.id);
      case "delete":
        return this.delete(type.id);
    }
  }
}
</script>

<style lang="scss" scoped></style>
