<template>
  <div>
    <div class="canvas-container main">
      <canvas :id="canvasIds.main" @click="openModal()" />
    </div>
    <Modal ref="modal" label="Детали" width="auto" :persistent="true" @toggle="toggleModal" card-body-class="d-flex">
      <template v-slot:body>
        <div class="canvas-modal w-100">
          <v-row class="flex-nowrap w-100 h-100 ma-0">
            <v-col cols="auto canvas-outer-container pa-0">
              <div class="canvas-container" :class="{ 'two-cols': !!$slots['after-result'] }">
                <CanvasActions :zoomStore="zoomStore"></CanvasActions>
                <canvas :id="canvasIds.modal"></canvas>
              </div>
            </v-col>
            <v-col class="second-column pa-0 pl-6" v-if="hasResultErrors || !!$slots['after-result']">
              <div v-if="hasResultErrors" class="mb-3">
                <v-alert
                  variant="outlined"
                  type="error"
                  title="Ошибки при выполнении алгоритма:"
                  :prominent="true"
                  border="top"
                >
                  <div v-for="(errorResult, key) in errorResults" :key="key">
                    <div class="text-caption">
                      {{ (errorResult.result.individualSettings || errorResult.result.settings)?.title }}
                    </div>
                    <ul>
                      <li v-for="(error, key) in errorResult.errors" :key="key">{{ error }}</li>
                    </ul>
                  </div>
                </v-alert>
              </div>
              <div class="w-100 pb-3" v-if="!!$slots['after-result']">
                <slot name="after-result" />
              </div>
            </v-col>
          </v-row>
        </div>
      </template>
      <template v-slot:footer>
        <v-btn variant="tonal" @click="modal.close()">Закрыть</v-btn>
      </template>
    </Modal>
  </div>
</template>

<script lang="ts">
import { ContoursAlgorithmResult } from "/src/entitites/AlgorithmResult.ts";
import { CanvasData } from "/src/entitites/CanvasData.ts";
import { UploadedFile } from "/src/entitites/UploadedFile.ts";
import Modal from "/src/primitives/Modal.vue";
import TooltipBtn from "/src/primitives/TooltipBtn.vue";
import { UrlsProvider } from "/src/services/UrlsProvider.ts";
import { CanvasZoomStore } from "/src/store/CanvasZoomStore.ts";
import { ContoursCanvasStore } from "/src/store/ContoursCanvasStore.ts";
import { DefectTypesStore } from "/src/store/DefectTypesStore.ts";
import { Container } from "typedi";
import { Component, Prop, Ref, Vue, Watch } from "vue-facing-decorator";
import CanvasActions from "./actions/CanvasActions.vue";

@Component({
  components: { CanvasActions, TooltipBtn, Modal },
})
export default class ContoursCanvas extends Vue {
  @Ref() readonly modal!: Modal;
  @Prop() file!: UploadedFile;
  @Prop({ default: () => [] }) results!: ContoursAlgorithmResult[];
  public defectTypesStore = Container.get(DefectTypesStore);
  public zoomStore = Container.get(CanvasZoomStore);
  private canvasStore = Container.get(ContoursCanvasStore);
  private urlsProvider = Container.get(UrlsProvider);
  private uniqId: string = Math.random().toString(36).substring(7);

  async mounted() {
    await this.defectTypesStore.loadItems();
    await this.setupStore();
    window.addEventListener("resize", this.onUpdateData);
  }

  beforeDestroy() {
    window.removeEventListener("resize", this.onUpdateData);
  }

  @Watch("results")
  private async setupStore() {
    await this.canvasStore.setup({ zoomStore: this.zoomStore, imageUrl: this.imageUrl }, this.results);
    this.zoomStore.setCallbackOnChange(() => this.onUpdateData());
    this.onUpdateData();
  }

  onUpdateData() {
    this.$nextTick(() => {
      this.canvasStore && this.canvasStore.updateCanvas(this.getCanvasData(!!this.modal?.visible));
    });
  }

  get imageUrl() {
    return this.urlsProvider.files.download(this.file?.hash || "");
  }

  get hasResultErrors() {
    return !!this.errorResults.length;
  }

  get errorResults(): { result: ContoursAlgorithmResult; errors: string[] }[] {
    return this.results
      .filter(result => !!result.data?.errors?.length)
      .map(result => ({
        result,
        errors: result.data.errors,
      }));
  }

  @Watch("file")
  get canvasIds() {
    return {
      main: `main_canvas_${this.file?.hash}_${this.uniqId}`,
      modal: `modal_canvas_${this.file?.hash}_${this.uniqId}`,
    };
  }

  getCanvasData(isModal = false): CanvasData {
    const canvas = document.getElementById(isModal ? this.canvasIds.modal : this.canvasIds.main);
    const container = canvas?.closest(".canvas-container") as Element;
    return {
      canvas: canvas as HTMLCanvasElement,
      containerWidth: container?.clientWidth || 350,
      containerHeight: container?.clientHeight || 350,
      isModal,
      container,
    };
  }

  openModal() {
    this.modal.open();
    this.onUpdateData();
  }

  toggleModal() {
    this.onUpdateData();
  }
}
</script>

<style lang="scss" scoped>
.canvas-container {
  font-size: 0;
  line-height: 0;
  &.main {
    cursor: pointer;
    outline: 1px #d3d3d3 solid;
  }
}
.canvas-modal {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-height: 100%;
  .canvas-outer-container {
    position: relative;
    overflow: hidden;
    outline: 1px #d3d3d3 solid;
    border-radius: 4px;
    .canvas-container {
      overflow: auto;
      min-height: 80vh;
      max-height: 100%;
      max-width: 100%;
      &.two-cols {
        max-width: calc(100vw - 800px);
      }
    }
  }
  .second-column {
    max-width: 800px;
  }
}
</style>
