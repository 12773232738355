export class Sort {
  public sort!: string;
  public order!: boolean | "asc" | "desc";
}

export class ItemListRequest {
  public page!: number;
  public perPage!: number;
  public sort!: string;
  public order!: string;
  public search!: string;
  public additionalParams!: object;
}

export class FilterFieldItem {
  public code!: string;
  public label!: string;
  public type!: string;
  public value!: any;
  public options!: object;
}

export class Pagination {
  public currentPage!: number;
  public perPage!: number;
}

export class WebQuery {
  public pagination!: Pagination;
  public sort!: Sort;
}
