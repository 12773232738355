import { orNull, toObject } from "/src/services/Decoders/CommonDecoders.ts";
import { field, number, string, succeed } from "jsonous";
import { Pagination, Sort, WebQuery } from "/src/entitites/ItemList";

const pagination = () =>
  field(
    "pagination",
    succeed(Pagination)
      .assign("currentPage", orNull(field("currentPage", number), 1))
      .assign("perPage", orNull(field("perPage", number), 10))
      .andThen(toObject(Pagination)),
  );

const sort = () =>
  field(
    "sort",
    succeed(Sort)
      .assign("sort", orNull(field("sort", string)))
      .assign("order", orNull(field("order", string)))
      .andThen(toObject(Sort)),
  );

export const decodeWebQuery = () =>
  succeed(WebQuery).assign("pagination", pagination()).assign("sort", sort()).andThen(toObject(WebQuery));
