import { Loader } from "/src/entitites/Loader";
import { User } from "/src/entitites/User.ts";
import { OwnUserProvider } from "/src/services/OwnUserProvider.ts";
import { Container, Service } from "typedi";
import { observable } from "mobx";

@Service({ global: true })
export class OwnUserStore {
  @observable public loader = new Loader();
  @observable public errors: string[] = [];
  private provider = Container.get(OwnUserProvider);

  public async load(): Promise<User | null> {
    this.errors = [];
    this.loader.start();
    const result = await this.provider.load();
    this.loader.stop();
    result.hasErrors && (this.errors = result.errorsAsArray);
    return result.data;
  }

  public async save(model: User) {
    this.loader.start();
    const result = await this.provider.save(model);
    this.loader.stop();
    return result;
  }
}
