import Decoder, { boolean, field, number, succeed } from "jsonous";
import { ok } from "resulty";
import { BaseResponse, CreateEntityResponse, UpdateEntityResponse } from "/src/entitites/ResponseEntity";

export function orNull(decoder: Decoder<any>, defaultValue: any = null) {
  return new Decoder((value: any) => {
    return decoder.decodeAny(value).cata({
      Ok: value => ok<string, any>(value),
      Err: () => ok<string, any>(defaultValue),
    });
  });
}

export function toObject<T extends object>(name: { new (): T }): (obj: any) => Decoder<T> {
  return obj => succeed(Object.assign(new name(), obj));
}

export const any: Decoder<any> = new Decoder<any>(value => {
  return ok(value);
});

export function modifyEntity<T extends object>(itemClass: { new (): T }): Decoder<T> {
  return succeed(itemClass)
    .assign("success", field("success", boolean))
    .assign("id", orNull(field("id", number)))
    .assign("errors", orNull(field("errors", any)))
    .andThen(toObject(itemClass));
}

export type DecoderFunc = <A>(f: Decoder<A>) => Decoder<A & any>;

export function baseResponse<T = null>(decoderFunc: DecoderFunc | null = null): Decoder<BaseResponse<T>> {
  const decoder = decoderFunc ? decoderFunc(succeed(BaseResponse)) : succeed(BaseResponse);
  return decoder
    .assign("success", field("success", boolean))
    .assign("errors", orNull(field("errors", any)))
    .andThen(toObject(BaseResponse<T>));
}

export function createEntity(): Decoder<CreateEntityResponse> {
  return modifyEntity(CreateEntityResponse);
}

export function updateEntity(): Decoder<UpdateEntityResponse> {
  return modifyEntity(UpdateEntityResponse);
}

export function deleteEntity(): Decoder<BaseResponse> {
  return modifyEntity(BaseResponse);
}
